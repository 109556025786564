
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ExternalAuthParams} from './ExternalAuthParams';
import {FieldModelExternalAuthParams} from './ExternalAuthParams';
import {NewsServicesSettings} from './NewsServicesSettings';
import {FieldModelNewsServicesSettings} from './NewsServicesSettings';

/**
 * AccessServerSettings struct
 */
class AccessServerSettings {
  /**
   * Initialize struct
   * @param {!number=} sslClientPort
   * @param {!number=} sslManagerPort
   * @param {!number=} wssClientPort
   * @param {!number=} wssManagerPort
   * @param {!number=} httpsClientPort
   * @param {!number=} httpsManagerPort
   * @param {!number=} httpsPublicPort
   * @param {!string=} deprecated1
   * @param {!string=} deprecated2
   * @param {!string=} wwwPath
   * @param {ExternalAuthParams=} externalAuthParams
   * @param {!number=} clientNotifySendingInterval
   * @param {number=} sslClientOutgoingBufferSizeLimit
   * @param {number=} sslManagerOutgoingBufferSizeLimit
   * @param {number=} wssClientOutgoingBufferSizeLimit
   * @param {number=} wssManagerOutgoingBufferSizeLimit
   * @param {number=} httpsClientOutgoingBufferSizeLimit
   * @param {number=} httpsManagerOutgoingBufferSizeLimit
   * @param {number=} httpsPublicOutgoingBufferSizeLimit
   * @param {!boolean=} sslClientEndpointEnabled
   * @param {!boolean=} sslManagerEndpointEnabled
   * @param {!boolean=} wssClientEndpointEnabled
   * @param {!boolean=} wssManagerEndpointEnabled
   * @param {!boolean=} httpsClientEndpointEnabled
   * @param {!boolean=} httpsManagerEndpointEnabled
   * @param {!boolean=} httpsPublicEndpointEnabled
   * @param {!NewsServicesSettings=} newsServicesSettings
   * @param {!UUID=} securitySettingsId
   * @param {string=} minRequiredClientAppVersion
   * @constructor
   */
  constructor (sslClientPort = 6300, sslManagerPort = 6310, wssClientPort = 6400, wssManagerPort = 6410, httpsClientPort = 6500, httpsManagerPort = 6510, httpsPublicPort = 6520, deprecated1 = '', deprecated2 = '', wwwPath = '../www', externalAuthParams = null, clientNotifySendingInterval = 500, sslClientOutgoingBufferSizeLimit = new UInt64(100000, 0), sslManagerOutgoingBufferSizeLimit = null, wssClientOutgoingBufferSizeLimit = new UInt64(100000, 0), wssManagerOutgoingBufferSizeLimit = null, httpsClientOutgoingBufferSizeLimit = null, httpsManagerOutgoingBufferSizeLimit = null, httpsPublicOutgoingBufferSizeLimit = null, sslClientEndpointEnabled = true, sslManagerEndpointEnabled = true, wssClientEndpointEnabled = true, wssManagerEndpointEnabled = true, httpsClientEndpointEnabled = true, httpsManagerEndpointEnabled = true, httpsPublicEndpointEnabled = true, newsServicesSettings = new NewsServicesSettings(), securitySettingsId = new UUID(), minRequiredClientAppVersion = null) {
    this.SslClientPort = sslClientPort
    this.SslManagerPort = sslManagerPort
    this.WssClientPort = wssClientPort
    this.WssManagerPort = wssManagerPort
    this.HttpsClientPort = httpsClientPort
    this.HttpsManagerPort = httpsManagerPort
    this.HttpsPublicPort = httpsPublicPort
    this.Deprecated1 = deprecated1
    this.Deprecated2 = deprecated2
    this.WwwPath = wwwPath
    this.ExternalAuthParams = externalAuthParams
    this.ClientNotifySendingInterval = clientNotifySendingInterval
    this.SslClientOutgoingBufferSizeLimit = sslClientOutgoingBufferSizeLimit
    this.SslManagerOutgoingBufferSizeLimit = sslManagerOutgoingBufferSizeLimit
    this.WssClientOutgoingBufferSizeLimit = wssClientOutgoingBufferSizeLimit
    this.WssManagerOutgoingBufferSizeLimit = wssManagerOutgoingBufferSizeLimit
    this.HttpsClientOutgoingBufferSizeLimit = httpsClientOutgoingBufferSizeLimit
    this.HttpsManagerOutgoingBufferSizeLimit = httpsManagerOutgoingBufferSizeLimit
    this.HttpsPublicOutgoingBufferSizeLimit = httpsPublicOutgoingBufferSizeLimit
    this.SslClientEndpointEnabled = sslClientEndpointEnabled
    this.SslManagerEndpointEnabled = sslManagerEndpointEnabled
    this.WssClientEndpointEnabled = wssClientEndpointEnabled
    this.WssManagerEndpointEnabled = wssManagerEndpointEnabled
    this.HttpsClientEndpointEnabled = httpsClientEndpointEnabled
    this.HttpsManagerEndpointEnabled = httpsManagerEndpointEnabled
    this.HttpsPublicEndpointEnabled = httpsPublicEndpointEnabled
    this.NewsServicesSettings = newsServicesSettings
    this.SecuritySettingsId = securitySettingsId
    this.MinRequiredClientAppVersion = minRequiredClientAppVersion
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AccessServerSettings}
   * @param {!AccessServerSettings} other Other struct
   * @returns {!AccessServerSettings} This struct
   */
  copy (other) {
    if (other.SslClientPort != null) {
      this.SslClientPort = other.SslClientPort
    } else {
      this.SslClientPort = null
    }
    if (other.SslManagerPort != null) {
      this.SslManagerPort = other.SslManagerPort
    } else {
      this.SslManagerPort = null
    }
    if (other.WssClientPort != null) {
      this.WssClientPort = other.WssClientPort
    } else {
      this.WssClientPort = null
    }
    if (other.WssManagerPort != null) {
      this.WssManagerPort = other.WssManagerPort
    } else {
      this.WssManagerPort = null
    }
    if (other.HttpsClientPort != null) {
      this.HttpsClientPort = other.HttpsClientPort
    } else {
      this.HttpsClientPort = null
    }
    if (other.HttpsManagerPort != null) {
      this.HttpsManagerPort = other.HttpsManagerPort
    } else {
      this.HttpsManagerPort = null
    }
    if (other.HttpsPublicPort != null) {
      this.HttpsPublicPort = other.HttpsPublicPort
    } else {
      this.HttpsPublicPort = null
    }
    if (other.Deprecated1 != null) {
      this.Deprecated1 = other.Deprecated1
    } else {
      this.Deprecated1 = null
    }
    if (other.Deprecated2 != null) {
      this.Deprecated2 = other.Deprecated2
    } else {
      this.Deprecated2 = null
    }
    if (other.WwwPath != null) {
      this.WwwPath = other.WwwPath
    } else {
      this.WwwPath = null
    }
    if (other.ExternalAuthParams != null) {
      this.ExternalAuthParams = ExternalAuthParams.fromObject(other.ExternalAuthParams)
    } else {
      this.ExternalAuthParams = null
    }
    if (other.ClientNotifySendingInterval != null) {
      this.ClientNotifySendingInterval = other.ClientNotifySendingInterval
    } else {
      this.ClientNotifySendingInterval = null
    }
    if (other.SslClientOutgoingBufferSizeLimit != null) {
      this.SslClientOutgoingBufferSizeLimit = UInt64.fromNumber(other.SslClientOutgoingBufferSizeLimit)
    } else {
      this.SslClientOutgoingBufferSizeLimit = null
    }
    if (other.SslManagerOutgoingBufferSizeLimit != null) {
      this.SslManagerOutgoingBufferSizeLimit = UInt64.fromNumber(other.SslManagerOutgoingBufferSizeLimit)
    } else {
      this.SslManagerOutgoingBufferSizeLimit = null
    }
    if (other.WssClientOutgoingBufferSizeLimit != null) {
      this.WssClientOutgoingBufferSizeLimit = UInt64.fromNumber(other.WssClientOutgoingBufferSizeLimit)
    } else {
      this.WssClientOutgoingBufferSizeLimit = null
    }
    if (other.WssManagerOutgoingBufferSizeLimit != null) {
      this.WssManagerOutgoingBufferSizeLimit = UInt64.fromNumber(other.WssManagerOutgoingBufferSizeLimit)
    } else {
      this.WssManagerOutgoingBufferSizeLimit = null
    }
    if (other.HttpsClientOutgoingBufferSizeLimit != null) {
      this.HttpsClientOutgoingBufferSizeLimit = UInt64.fromNumber(other.HttpsClientOutgoingBufferSizeLimit)
    } else {
      this.HttpsClientOutgoingBufferSizeLimit = null
    }
    if (other.HttpsManagerOutgoingBufferSizeLimit != null) {
      this.HttpsManagerOutgoingBufferSizeLimit = UInt64.fromNumber(other.HttpsManagerOutgoingBufferSizeLimit)
    } else {
      this.HttpsManagerOutgoingBufferSizeLimit = null
    }
    if (other.HttpsPublicOutgoingBufferSizeLimit != null) {
      this.HttpsPublicOutgoingBufferSizeLimit = UInt64.fromNumber(other.HttpsPublicOutgoingBufferSizeLimit)
    } else {
      this.HttpsPublicOutgoingBufferSizeLimit = null
    }
    if (other.SslClientEndpointEnabled != null) {
      this.SslClientEndpointEnabled = other.SslClientEndpointEnabled
    } else {
      this.SslClientEndpointEnabled = null
    }
    if (other.SslManagerEndpointEnabled != null) {
      this.SslManagerEndpointEnabled = other.SslManagerEndpointEnabled
    } else {
      this.SslManagerEndpointEnabled = null
    }
    if (other.WssClientEndpointEnabled != null) {
      this.WssClientEndpointEnabled = other.WssClientEndpointEnabled
    } else {
      this.WssClientEndpointEnabled = null
    }
    if (other.WssManagerEndpointEnabled != null) {
      this.WssManagerEndpointEnabled = other.WssManagerEndpointEnabled
    } else {
      this.WssManagerEndpointEnabled = null
    }
    if (other.HttpsClientEndpointEnabled != null) {
      this.HttpsClientEndpointEnabled = other.HttpsClientEndpointEnabled
    } else {
      this.HttpsClientEndpointEnabled = null
    }
    if (other.HttpsManagerEndpointEnabled != null) {
      this.HttpsManagerEndpointEnabled = other.HttpsManagerEndpointEnabled
    } else {
      this.HttpsManagerEndpointEnabled = null
    }
    if (other.HttpsPublicEndpointEnabled != null) {
      this.HttpsPublicEndpointEnabled = other.HttpsPublicEndpointEnabled
    } else {
      this.HttpsPublicEndpointEnabled = null
    }
    if (other.NewsServicesSettings != null) {
      this.NewsServicesSettings = NewsServicesSettings.fromObject(other.NewsServicesSettings)
    } else {
      this.NewsServicesSettings = null
    }
    if (other.SecuritySettingsId != null) {
      this.SecuritySettingsId = new UUID(other.SecuritySettingsId)
    } else {
      this.SecuritySettingsId = null
    }
    if (other.MinRequiredClientAppVersion != null) {
      this.MinRequiredClientAppVersion = other.MinRequiredClientAppVersion
    } else {
      this.MinRequiredClientAppVersion = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AccessServerSettings}
   * @returns {!AccessServerSettings} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AccessServerSettingsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AccessServerSettingsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AccessServerSettings}
   * @param {!AccessServerSettings} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AccessServerSettings)) {
      throw new TypeError('Instance of AccessServerSettings is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AccessServerSettings}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      SslClientPort: ((this.SslClientPort != null) ? this.SslClientPort : null),
      SslManagerPort: ((this.SslManagerPort != null) ? this.SslManagerPort : null),
      WssClientPort: ((this.WssClientPort != null) ? this.WssClientPort : null),
      WssManagerPort: ((this.WssManagerPort != null) ? this.WssManagerPort : null),
      HttpsClientPort: ((this.HttpsClientPort != null) ? this.HttpsClientPort : null),
      HttpsManagerPort: ((this.HttpsManagerPort != null) ? this.HttpsManagerPort : null),
      HttpsPublicPort: ((this.HttpsPublicPort != null) ? this.HttpsPublicPort : null),
      Deprecated1: ((this.Deprecated1 != null) ? this.Deprecated1 : null),
      Deprecated2: ((this.Deprecated2 != null) ? this.Deprecated2 : null),
      WwwPath: ((this.WwwPath != null) ? this.WwwPath : null),
      ExternalAuthParams: ((this.ExternalAuthParams != null) ? this.ExternalAuthParams : null),
      ClientNotifySendingInterval: ((this.ClientNotifySendingInterval != null) ? this.ClientNotifySendingInterval : null),
      SslClientOutgoingBufferSizeLimit: ((this.SslClientOutgoingBufferSizeLimit != null) ? this.SslClientOutgoingBufferSizeLimit.toNumber() : null),
      SslManagerOutgoingBufferSizeLimit: ((this.SslManagerOutgoingBufferSizeLimit != null) ? this.SslManagerOutgoingBufferSizeLimit.toNumber() : null),
      WssClientOutgoingBufferSizeLimit: ((this.WssClientOutgoingBufferSizeLimit != null) ? this.WssClientOutgoingBufferSizeLimit.toNumber() : null),
      WssManagerOutgoingBufferSizeLimit: ((this.WssManagerOutgoingBufferSizeLimit != null) ? this.WssManagerOutgoingBufferSizeLimit.toNumber() : null),
      HttpsClientOutgoingBufferSizeLimit: ((this.HttpsClientOutgoingBufferSizeLimit != null) ? this.HttpsClientOutgoingBufferSizeLimit.toNumber() : null),
      HttpsManagerOutgoingBufferSizeLimit: ((this.HttpsManagerOutgoingBufferSizeLimit != null) ? this.HttpsManagerOutgoingBufferSizeLimit.toNumber() : null),
      HttpsPublicOutgoingBufferSizeLimit: ((this.HttpsPublicOutgoingBufferSizeLimit != null) ? this.HttpsPublicOutgoingBufferSizeLimit.toNumber() : null),
      SslClientEndpointEnabled: ((this.SslClientEndpointEnabled != null) ? this.SslClientEndpointEnabled : null),
      SslManagerEndpointEnabled: ((this.SslManagerEndpointEnabled != null) ? this.SslManagerEndpointEnabled : null),
      WssClientEndpointEnabled: ((this.WssClientEndpointEnabled != null) ? this.WssClientEndpointEnabled : null),
      WssManagerEndpointEnabled: ((this.WssManagerEndpointEnabled != null) ? this.WssManagerEndpointEnabled : null),
      HttpsClientEndpointEnabled: ((this.HttpsClientEndpointEnabled != null) ? this.HttpsClientEndpointEnabled : null),
      HttpsManagerEndpointEnabled: ((this.HttpsManagerEndpointEnabled != null) ? this.HttpsManagerEndpointEnabled : null),
      HttpsPublicEndpointEnabled: ((this.HttpsPublicEndpointEnabled != null) ? this.HttpsPublicEndpointEnabled : null),
      NewsServicesSettings: ((this.NewsServicesSettings != null) ? this.NewsServicesSettings : null),
      SecuritySettingsId: ((this.SecuritySettingsId != null) ? this.SecuritySettingsId.toString() : null),
      MinRequiredClientAppVersion: ((this.MinRequiredClientAppVersion != null) ? this.MinRequiredClientAppVersion : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AccessServerSettings.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AccessServerSettings} other Object value
   * @returns {!AccessServerSettings} Created struct
   */
  static fromObject (other) {
    return new AccessServerSettings().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AccessServerSettings}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AccessServerSettings.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AccessServerSettings}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 7
  }
}

export { AccessServerSettings };

/**
 * Fast Binary Encoding AccessServerSettings field model
 */
class FieldModelAccessServerSettings extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.SslClientPort = new fbe.FieldModelUInt16(buffer, 4 + 4)
    this.SslManagerPort = new fbe.FieldModelUInt16(buffer, this.SslClientPort.fbeOffset + this.SslClientPort.fbeSize)
    this.WssClientPort = new fbe.FieldModelUInt16(buffer, this.SslManagerPort.fbeOffset + this.SslManagerPort.fbeSize)
    this.WssManagerPort = new fbe.FieldModelUInt16(buffer, this.WssClientPort.fbeOffset + this.WssClientPort.fbeSize)
    this.HttpsClientPort = new fbe.FieldModelUInt16(buffer, this.WssManagerPort.fbeOffset + this.WssManagerPort.fbeSize)
    this.HttpsManagerPort = new fbe.FieldModelUInt16(buffer, this.HttpsClientPort.fbeOffset + this.HttpsClientPort.fbeSize)
    this.HttpsPublicPort = new fbe.FieldModelUInt16(buffer, this.HttpsManagerPort.fbeOffset + this.HttpsManagerPort.fbeSize)
    this.Deprecated1 = new fbe.FieldModelString(buffer, this.HttpsPublicPort.fbeOffset + this.HttpsPublicPort.fbeSize)
    this.Deprecated2 = new fbe.FieldModelString(buffer, this.Deprecated1.fbeOffset + this.Deprecated1.fbeSize)
    this.WwwPath = new fbe.FieldModelString(buffer, this.Deprecated2.fbeOffset + this.Deprecated2.fbeSize)
    this.ExternalAuthParams = new fbe.FieldModelOptional(new FieldModelExternalAuthParams(buffer, this.WwwPath.fbeOffset + this.WwwPath.fbeSize), buffer, this.WwwPath.fbeOffset + this.WwwPath.fbeSize)
    this.ClientNotifySendingInterval = new fbe.FieldModelUInt32(buffer, this.ExternalAuthParams.fbeOffset + this.ExternalAuthParams.fbeSize)
    this.SslClientOutgoingBufferSizeLimit = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.ClientNotifySendingInterval.fbeOffset + this.ClientNotifySendingInterval.fbeSize), buffer, this.ClientNotifySendingInterval.fbeOffset + this.ClientNotifySendingInterval.fbeSize)
    this.SslManagerOutgoingBufferSizeLimit = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.SslClientOutgoingBufferSizeLimit.fbeOffset + this.SslClientOutgoingBufferSizeLimit.fbeSize), buffer, this.SslClientOutgoingBufferSizeLimit.fbeOffset + this.SslClientOutgoingBufferSizeLimit.fbeSize)
    this.WssClientOutgoingBufferSizeLimit = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.SslManagerOutgoingBufferSizeLimit.fbeOffset + this.SslManagerOutgoingBufferSizeLimit.fbeSize), buffer, this.SslManagerOutgoingBufferSizeLimit.fbeOffset + this.SslManagerOutgoingBufferSizeLimit.fbeSize)
    this.WssManagerOutgoingBufferSizeLimit = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.WssClientOutgoingBufferSizeLimit.fbeOffset + this.WssClientOutgoingBufferSizeLimit.fbeSize), buffer, this.WssClientOutgoingBufferSizeLimit.fbeOffset + this.WssClientOutgoingBufferSizeLimit.fbeSize)
    this.HttpsClientOutgoingBufferSizeLimit = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.WssManagerOutgoingBufferSizeLimit.fbeOffset + this.WssManagerOutgoingBufferSizeLimit.fbeSize), buffer, this.WssManagerOutgoingBufferSizeLimit.fbeOffset + this.WssManagerOutgoingBufferSizeLimit.fbeSize)
    this.HttpsManagerOutgoingBufferSizeLimit = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.HttpsClientOutgoingBufferSizeLimit.fbeOffset + this.HttpsClientOutgoingBufferSizeLimit.fbeSize), buffer, this.HttpsClientOutgoingBufferSizeLimit.fbeOffset + this.HttpsClientOutgoingBufferSizeLimit.fbeSize)
    this.HttpsPublicOutgoingBufferSizeLimit = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.HttpsManagerOutgoingBufferSizeLimit.fbeOffset + this.HttpsManagerOutgoingBufferSizeLimit.fbeSize), buffer, this.HttpsManagerOutgoingBufferSizeLimit.fbeOffset + this.HttpsManagerOutgoingBufferSizeLimit.fbeSize)
    this.SslClientEndpointEnabled = new fbe.FieldModelBool(buffer, this.HttpsPublicOutgoingBufferSizeLimit.fbeOffset + this.HttpsPublicOutgoingBufferSizeLimit.fbeSize)
    this.SslManagerEndpointEnabled = new fbe.FieldModelBool(buffer, this.SslClientEndpointEnabled.fbeOffset + this.SslClientEndpointEnabled.fbeSize)
    this.WssClientEndpointEnabled = new fbe.FieldModelBool(buffer, this.SslManagerEndpointEnabled.fbeOffset + this.SslManagerEndpointEnabled.fbeSize)
    this.WssManagerEndpointEnabled = new fbe.FieldModelBool(buffer, this.WssClientEndpointEnabled.fbeOffset + this.WssClientEndpointEnabled.fbeSize)
    this.HttpsClientEndpointEnabled = new fbe.FieldModelBool(buffer, this.WssManagerEndpointEnabled.fbeOffset + this.WssManagerEndpointEnabled.fbeSize)
    this.HttpsManagerEndpointEnabled = new fbe.FieldModelBool(buffer, this.HttpsClientEndpointEnabled.fbeOffset + this.HttpsClientEndpointEnabled.fbeSize)
    this.HttpsPublicEndpointEnabled = new fbe.FieldModelBool(buffer, this.HttpsManagerEndpointEnabled.fbeOffset + this.HttpsManagerEndpointEnabled.fbeSize)
    this.NewsServicesSettings = new FieldModelNewsServicesSettings(buffer, this.HttpsPublicEndpointEnabled.fbeOffset + this.HttpsPublicEndpointEnabled.fbeSize)
    this.SecuritySettingsId = new fbe.FieldModelUUID(buffer, this.NewsServicesSettings.fbeOffset + this.NewsServicesSettings.fbeSize)
    this.MinRequiredClientAppVersion = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.SecuritySettingsId.fbeOffset + this.SecuritySettingsId.fbeSize), buffer, this.SecuritySettingsId.fbeOffset + this.SecuritySettingsId.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelAccessServerSettings}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAccessServerSettings}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.SslClientPort.fbeSize + this.SslManagerPort.fbeSize + this.WssClientPort.fbeSize + this.WssManagerPort.fbeSize + this.HttpsClientPort.fbeSize + this.HttpsManagerPort.fbeSize + this.HttpsPublicPort.fbeSize + this.Deprecated1.fbeSize + this.Deprecated2.fbeSize + this.WwwPath.fbeSize + this.ExternalAuthParams.fbeSize + this.ClientNotifySendingInterval.fbeSize + this.SslClientOutgoingBufferSizeLimit.fbeSize + this.SslManagerOutgoingBufferSizeLimit.fbeSize + this.WssClientOutgoingBufferSizeLimit.fbeSize + this.WssManagerOutgoingBufferSizeLimit.fbeSize + this.HttpsClientOutgoingBufferSizeLimit.fbeSize + this.HttpsManagerOutgoingBufferSizeLimit.fbeSize + this.HttpsPublicOutgoingBufferSizeLimit.fbeSize + this.SslClientEndpointEnabled.fbeSize + this.SslManagerEndpointEnabled.fbeSize + this.WssClientEndpointEnabled.fbeSize + this.WssManagerEndpointEnabled.fbeSize + this.HttpsClientEndpointEnabled.fbeSize + this.HttpsManagerEndpointEnabled.fbeSize + this.HttpsPublicEndpointEnabled.fbeSize + this.NewsServicesSettings.fbeSize + this.SecuritySettingsId.fbeSize + this.MinRequiredClientAppVersion.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAccessServerSettings}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.SslClientPort.fbeExtra + this.SslManagerPort.fbeExtra + this.WssClientPort.fbeExtra + this.WssManagerPort.fbeExtra + this.HttpsClientPort.fbeExtra + this.HttpsManagerPort.fbeExtra + this.HttpsPublicPort.fbeExtra + this.Deprecated1.fbeExtra + this.Deprecated2.fbeExtra + this.WwwPath.fbeExtra + this.ExternalAuthParams.fbeExtra + this.ClientNotifySendingInterval.fbeExtra + this.SslClientOutgoingBufferSizeLimit.fbeExtra + this.SslManagerOutgoingBufferSizeLimit.fbeExtra + this.WssClientOutgoingBufferSizeLimit.fbeExtra + this.WssManagerOutgoingBufferSizeLimit.fbeExtra + this.HttpsClientOutgoingBufferSizeLimit.fbeExtra + this.HttpsManagerOutgoingBufferSizeLimit.fbeExtra + this.HttpsPublicOutgoingBufferSizeLimit.fbeExtra + this.SslClientEndpointEnabled.fbeExtra + this.SslManagerEndpointEnabled.fbeExtra + this.WssClientEndpointEnabled.fbeExtra + this.WssManagerEndpointEnabled.fbeExtra + this.HttpsClientEndpointEnabled.fbeExtra + this.HttpsManagerEndpointEnabled.fbeExtra + this.HttpsPublicEndpointEnabled.fbeExtra + this.NewsServicesSettings.fbeExtra + this.SecuritySettingsId.fbeExtra + this.MinRequiredClientAppVersion.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAccessServerSettings}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAccessServerSettings.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAccessServerSettings}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 7
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAccessServerSettings}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAccessServerSettings}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.SslClientPort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SslClientPort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SslClientPort.fbeSize

    if ((fbeCurrentSize + this.SslManagerPort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SslManagerPort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SslManagerPort.fbeSize

    if ((fbeCurrentSize + this.WssClientPort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.WssClientPort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.WssClientPort.fbeSize

    if ((fbeCurrentSize + this.WssManagerPort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.WssManagerPort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.WssManagerPort.fbeSize

    if ((fbeCurrentSize + this.HttpsClientPort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HttpsClientPort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HttpsClientPort.fbeSize

    if ((fbeCurrentSize + this.HttpsManagerPort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HttpsManagerPort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HttpsManagerPort.fbeSize

    if ((fbeCurrentSize + this.HttpsPublicPort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HttpsPublicPort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HttpsPublicPort.fbeSize

    if ((fbeCurrentSize + this.Deprecated1.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated1.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated1.fbeSize

    if ((fbeCurrentSize + this.Deprecated2.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated2.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated2.fbeSize

    if ((fbeCurrentSize + this.WwwPath.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.WwwPath.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.WwwPath.fbeSize

    if ((fbeCurrentSize + this.ExternalAuthParams.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExternalAuthParams.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalAuthParams.fbeSize

    if ((fbeCurrentSize + this.ClientNotifySendingInterval.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientNotifySendingInterval.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientNotifySendingInterval.fbeSize

    if ((fbeCurrentSize + this.SslClientOutgoingBufferSizeLimit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SslClientOutgoingBufferSizeLimit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SslClientOutgoingBufferSizeLimit.fbeSize

    if ((fbeCurrentSize + this.SslManagerOutgoingBufferSizeLimit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SslManagerOutgoingBufferSizeLimit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SslManagerOutgoingBufferSizeLimit.fbeSize

    if ((fbeCurrentSize + this.WssClientOutgoingBufferSizeLimit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.WssClientOutgoingBufferSizeLimit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.WssClientOutgoingBufferSizeLimit.fbeSize

    if ((fbeCurrentSize + this.WssManagerOutgoingBufferSizeLimit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.WssManagerOutgoingBufferSizeLimit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.WssManagerOutgoingBufferSizeLimit.fbeSize

    if ((fbeCurrentSize + this.HttpsClientOutgoingBufferSizeLimit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HttpsClientOutgoingBufferSizeLimit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HttpsClientOutgoingBufferSizeLimit.fbeSize

    if ((fbeCurrentSize + this.HttpsManagerOutgoingBufferSizeLimit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HttpsManagerOutgoingBufferSizeLimit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HttpsManagerOutgoingBufferSizeLimit.fbeSize

    if ((fbeCurrentSize + this.HttpsPublicOutgoingBufferSizeLimit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HttpsPublicOutgoingBufferSizeLimit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HttpsPublicOutgoingBufferSizeLimit.fbeSize

    if ((fbeCurrentSize + this.SslClientEndpointEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SslClientEndpointEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SslClientEndpointEnabled.fbeSize

    if ((fbeCurrentSize + this.SslManagerEndpointEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SslManagerEndpointEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SslManagerEndpointEnabled.fbeSize

    if ((fbeCurrentSize + this.WssClientEndpointEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.WssClientEndpointEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.WssClientEndpointEnabled.fbeSize

    if ((fbeCurrentSize + this.WssManagerEndpointEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.WssManagerEndpointEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.WssManagerEndpointEnabled.fbeSize

    if ((fbeCurrentSize + this.HttpsClientEndpointEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HttpsClientEndpointEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HttpsClientEndpointEnabled.fbeSize

    if ((fbeCurrentSize + this.HttpsManagerEndpointEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HttpsManagerEndpointEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HttpsManagerEndpointEnabled.fbeSize

    if ((fbeCurrentSize + this.HttpsPublicEndpointEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HttpsPublicEndpointEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HttpsPublicEndpointEnabled.fbeSize

    if ((fbeCurrentSize + this.NewsServicesSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewsServicesSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewsServicesSettings.fbeSize

    if ((fbeCurrentSize + this.SecuritySettingsId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SecuritySettingsId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SecuritySettingsId.fbeSize

    if ((fbeCurrentSize + this.MinRequiredClientAppVersion.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MinRequiredClientAppVersion.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MinRequiredClientAppVersion.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAccessServerSettings}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAccessServerSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAccessServerSettings}
   * @param {!AccessServerSettings} fbeValue Default value, defaults is new AccessServerSettings()
   * @returns {!AccessServerSettings} AccessServerSettings value
   */
  get (fbeValue = new AccessServerSettings()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAccessServerSettings}
   * @param {!AccessServerSettings} fbeValue AccessServerSettings value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.SslClientPort.fbeSize) <= fbeStructSize) {
      fbeValue.SslClientPort = this.SslClientPort.get(6300)
    } else {
      fbeValue.SslClientPort = 6300
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SslClientPort.fbeSize

    if ((fbeCurrentSize + this.SslManagerPort.fbeSize) <= fbeStructSize) {
      fbeValue.SslManagerPort = this.SslManagerPort.get(6310)
    } else {
      fbeValue.SslManagerPort = 6310
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SslManagerPort.fbeSize

    if ((fbeCurrentSize + this.WssClientPort.fbeSize) <= fbeStructSize) {
      fbeValue.WssClientPort = this.WssClientPort.get(6400)
    } else {
      fbeValue.WssClientPort = 6400
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.WssClientPort.fbeSize

    if ((fbeCurrentSize + this.WssManagerPort.fbeSize) <= fbeStructSize) {
      fbeValue.WssManagerPort = this.WssManagerPort.get(6410)
    } else {
      fbeValue.WssManagerPort = 6410
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.WssManagerPort.fbeSize

    if ((fbeCurrentSize + this.HttpsClientPort.fbeSize) <= fbeStructSize) {
      fbeValue.HttpsClientPort = this.HttpsClientPort.get(6500)
    } else {
      fbeValue.HttpsClientPort = 6500
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HttpsClientPort.fbeSize

    if ((fbeCurrentSize + this.HttpsManagerPort.fbeSize) <= fbeStructSize) {
      fbeValue.HttpsManagerPort = this.HttpsManagerPort.get(6510)
    } else {
      fbeValue.HttpsManagerPort = 6510
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HttpsManagerPort.fbeSize

    if ((fbeCurrentSize + this.HttpsPublicPort.fbeSize) <= fbeStructSize) {
      fbeValue.HttpsPublicPort = this.HttpsPublicPort.get(6520)
    } else {
      fbeValue.HttpsPublicPort = 6520
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HttpsPublicPort.fbeSize

    if ((fbeCurrentSize + this.Deprecated1.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated1 = this.Deprecated1.get()
    } else {
      fbeValue.Deprecated1 = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated1.fbeSize

    if ((fbeCurrentSize + this.Deprecated2.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated2 = this.Deprecated2.get()
    } else {
      fbeValue.Deprecated2 = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated2.fbeSize

    if ((fbeCurrentSize + this.WwwPath.fbeSize) <= fbeStructSize) {
      fbeValue.WwwPath = this.WwwPath.get('../www')
    } else {
      fbeValue.WwwPath = '../www'
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.WwwPath.fbeSize

    if ((fbeCurrentSize + this.ExternalAuthParams.fbeSize) <= fbeStructSize) {
      fbeValue.ExternalAuthParams = this.ExternalAuthParams.get()
    } else {
      fbeValue.ExternalAuthParams = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalAuthParams.fbeSize

    if ((fbeCurrentSize + this.ClientNotifySendingInterval.fbeSize) <= fbeStructSize) {
      fbeValue.ClientNotifySendingInterval = this.ClientNotifySendingInterval.get(500)
    } else {
      fbeValue.ClientNotifySendingInterval = 500
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientNotifySendingInterval.fbeSize

    if ((fbeCurrentSize + this.SslClientOutgoingBufferSizeLimit.fbeSize) <= fbeStructSize) {
      fbeValue.SslClientOutgoingBufferSizeLimit = this.SslClientOutgoingBufferSizeLimit.get(new UInt64(100000, 0))
    } else {
      fbeValue.SslClientOutgoingBufferSizeLimit = new UInt64(100000, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SslClientOutgoingBufferSizeLimit.fbeSize

    if ((fbeCurrentSize + this.SslManagerOutgoingBufferSizeLimit.fbeSize) <= fbeStructSize) {
      fbeValue.SslManagerOutgoingBufferSizeLimit = this.SslManagerOutgoingBufferSizeLimit.get()
    } else {
      fbeValue.SslManagerOutgoingBufferSizeLimit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SslManagerOutgoingBufferSizeLimit.fbeSize

    if ((fbeCurrentSize + this.WssClientOutgoingBufferSizeLimit.fbeSize) <= fbeStructSize) {
      fbeValue.WssClientOutgoingBufferSizeLimit = this.WssClientOutgoingBufferSizeLimit.get(new UInt64(100000, 0))
    } else {
      fbeValue.WssClientOutgoingBufferSizeLimit = new UInt64(100000, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.WssClientOutgoingBufferSizeLimit.fbeSize

    if ((fbeCurrentSize + this.WssManagerOutgoingBufferSizeLimit.fbeSize) <= fbeStructSize) {
      fbeValue.WssManagerOutgoingBufferSizeLimit = this.WssManagerOutgoingBufferSizeLimit.get()
    } else {
      fbeValue.WssManagerOutgoingBufferSizeLimit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.WssManagerOutgoingBufferSizeLimit.fbeSize

    if ((fbeCurrentSize + this.HttpsClientOutgoingBufferSizeLimit.fbeSize) <= fbeStructSize) {
      fbeValue.HttpsClientOutgoingBufferSizeLimit = this.HttpsClientOutgoingBufferSizeLimit.get()
    } else {
      fbeValue.HttpsClientOutgoingBufferSizeLimit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HttpsClientOutgoingBufferSizeLimit.fbeSize

    if ((fbeCurrentSize + this.HttpsManagerOutgoingBufferSizeLimit.fbeSize) <= fbeStructSize) {
      fbeValue.HttpsManagerOutgoingBufferSizeLimit = this.HttpsManagerOutgoingBufferSizeLimit.get()
    } else {
      fbeValue.HttpsManagerOutgoingBufferSizeLimit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HttpsManagerOutgoingBufferSizeLimit.fbeSize

    if ((fbeCurrentSize + this.HttpsPublicOutgoingBufferSizeLimit.fbeSize) <= fbeStructSize) {
      fbeValue.HttpsPublicOutgoingBufferSizeLimit = this.HttpsPublicOutgoingBufferSizeLimit.get()
    } else {
      fbeValue.HttpsPublicOutgoingBufferSizeLimit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HttpsPublicOutgoingBufferSizeLimit.fbeSize

    if ((fbeCurrentSize + this.SslClientEndpointEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.SslClientEndpointEnabled = this.SslClientEndpointEnabled.get(true)
    } else {
      fbeValue.SslClientEndpointEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SslClientEndpointEnabled.fbeSize

    if ((fbeCurrentSize + this.SslManagerEndpointEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.SslManagerEndpointEnabled = this.SslManagerEndpointEnabled.get(true)
    } else {
      fbeValue.SslManagerEndpointEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SslManagerEndpointEnabled.fbeSize

    if ((fbeCurrentSize + this.WssClientEndpointEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.WssClientEndpointEnabled = this.WssClientEndpointEnabled.get(true)
    } else {
      fbeValue.WssClientEndpointEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.WssClientEndpointEnabled.fbeSize

    if ((fbeCurrentSize + this.WssManagerEndpointEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.WssManagerEndpointEnabled = this.WssManagerEndpointEnabled.get(true)
    } else {
      fbeValue.WssManagerEndpointEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.WssManagerEndpointEnabled.fbeSize

    if ((fbeCurrentSize + this.HttpsClientEndpointEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.HttpsClientEndpointEnabled = this.HttpsClientEndpointEnabled.get(true)
    } else {
      fbeValue.HttpsClientEndpointEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HttpsClientEndpointEnabled.fbeSize

    if ((fbeCurrentSize + this.HttpsManagerEndpointEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.HttpsManagerEndpointEnabled = this.HttpsManagerEndpointEnabled.get(true)
    } else {
      fbeValue.HttpsManagerEndpointEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HttpsManagerEndpointEnabled.fbeSize

    if ((fbeCurrentSize + this.HttpsPublicEndpointEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.HttpsPublicEndpointEnabled = this.HttpsPublicEndpointEnabled.get(true)
    } else {
      fbeValue.HttpsPublicEndpointEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HttpsPublicEndpointEnabled.fbeSize

    if ((fbeCurrentSize + this.NewsServicesSettings.fbeSize) <= fbeStructSize) {
      fbeValue.NewsServicesSettings = this.NewsServicesSettings.get()
    } else {
      fbeValue.NewsServicesSettings = new NewsServicesSettings()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewsServicesSettings.fbeSize

    if ((fbeCurrentSize + this.SecuritySettingsId.fbeSize) <= fbeStructSize) {
      fbeValue.SecuritySettingsId = this.SecuritySettingsId.get()
    } else {
      fbeValue.SecuritySettingsId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SecuritySettingsId.fbeSize

    if ((fbeCurrentSize + this.MinRequiredClientAppVersion.fbeSize) <= fbeStructSize) {
      fbeValue.MinRequiredClientAppVersion = this.MinRequiredClientAppVersion.get()
    } else {
      fbeValue.MinRequiredClientAppVersion = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MinRequiredClientAppVersion.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAccessServerSettings}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAccessServerSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAccessServerSettings}
   * @param {!AccessServerSettings} fbeValue AccessServerSettings value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAccessServerSettings}
   * @param {AccessServerSettings} fbeValue AccessServerSettings value
   */
  setFields (fbeValue) {
    this.SslClientPort.set(fbeValue.SslClientPort)
    this.SslManagerPort.set(fbeValue.SslManagerPort)
    this.WssClientPort.set(fbeValue.WssClientPort)
    this.WssManagerPort.set(fbeValue.WssManagerPort)
    this.HttpsClientPort.set(fbeValue.HttpsClientPort)
    this.HttpsManagerPort.set(fbeValue.HttpsManagerPort)
    this.HttpsPublicPort.set(fbeValue.HttpsPublicPort)
    this.Deprecated1.set(fbeValue.Deprecated1)
    this.Deprecated2.set(fbeValue.Deprecated2)
    this.WwwPath.set(fbeValue.WwwPath)
    this.ExternalAuthParams.set(fbeValue.ExternalAuthParams)
    this.ClientNotifySendingInterval.set(fbeValue.ClientNotifySendingInterval)
    this.SslClientOutgoingBufferSizeLimit.set(fbeValue.SslClientOutgoingBufferSizeLimit)
    this.SslManagerOutgoingBufferSizeLimit.set(fbeValue.SslManagerOutgoingBufferSizeLimit)
    this.WssClientOutgoingBufferSizeLimit.set(fbeValue.WssClientOutgoingBufferSizeLimit)
    this.WssManagerOutgoingBufferSizeLimit.set(fbeValue.WssManagerOutgoingBufferSizeLimit)
    this.HttpsClientOutgoingBufferSizeLimit.set(fbeValue.HttpsClientOutgoingBufferSizeLimit)
    this.HttpsManagerOutgoingBufferSizeLimit.set(fbeValue.HttpsManagerOutgoingBufferSizeLimit)
    this.HttpsPublicOutgoingBufferSizeLimit.set(fbeValue.HttpsPublicOutgoingBufferSizeLimit)
    this.SslClientEndpointEnabled.set(fbeValue.SslClientEndpointEnabled)
    this.SslManagerEndpointEnabled.set(fbeValue.SslManagerEndpointEnabled)
    this.WssClientEndpointEnabled.set(fbeValue.WssClientEndpointEnabled)
    this.WssManagerEndpointEnabled.set(fbeValue.WssManagerEndpointEnabled)
    this.HttpsClientEndpointEnabled.set(fbeValue.HttpsClientEndpointEnabled)
    this.HttpsManagerEndpointEnabled.set(fbeValue.HttpsManagerEndpointEnabled)
    this.HttpsPublicEndpointEnabled.set(fbeValue.HttpsPublicEndpointEnabled)
    this.NewsServicesSettings.set(fbeValue.NewsServicesSettings)
    this.SecuritySettingsId.set(fbeValue.SecuritySettingsId)
    this.MinRequiredClientAppVersion.set(fbeValue.MinRequiredClientAppVersion)
  }
}

export { FieldModelAccessServerSettings };

/**
 * Fast Binary Encoding AccessServerSettings model
 */
class AccessServerSettingsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAccessServerSettings(this.buffer, 4)
  }

  /**
   * Get the AccessServerSettings model
   * @this {!AccessServerSettingsModel}
   * @returns {!FieldModelAccessServerSettings} model AccessServerSettings model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AccessServerSettingsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AccessServerSettingsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AccessServerSettingsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AccessServerSettingsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAccessServerSettings.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AccessServerSettingsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AccessServerSettingsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AccessServerSettingsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AccessServerSettingsModel}
   * @param {!AccessServerSettings} value AccessServerSettings value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AccessServerSettingsModel}
   * @param {!AccessServerSettings} value AccessServerSettings value, defaults is new AccessServerSettings()
   * @return {!object} Deserialized AccessServerSettings value and its size
   */
  deserialize (value = new AccessServerSettings()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AccessServerSettings(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AccessServerSettings(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AccessServerSettingsModel}
   * @param {!number} prev Previous AccessServerSettings model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AccessServerSettingsModel };
