
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * ServiceStatusDetail enum
 */
class ServiceStatusDetail {
  /**
   * Initialize enum with a given value
   * @param {ServiceStatusDetail|number|Int64|UInt64=} value Enum value, defaults is 0
   * @constructor
   */
  constructor (value = 0) {
    if (value instanceof ServiceStatusDetail) {
      this.value = value.value
    } else {
      this.value = value
    }
  }

  /**
   * Is this enum equal to other one?
   * @this {!ServiceStatusDetail}
   * @param {!ServiceStatusDetail} other Other enum
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ServiceStatusDetail)) {
      throw new TypeError('Instance of ServiceStatusDetail is required!')
    }
    return this.value === other.value
  }

  /**
   * Get enum value
   * @this {!ServiceStatusDetail}
   * @returns {!number|!Int64|!UInt64} Enum value
   */
  valueOf () {
    return this.value
  }

  /**
   * Convert enum to string
   * @this {!ServiceStatusDetail}
   * @returns {!string} Enum value string
   */
  toString () {
    if (this.value === ServiceStatusDetail.NO_CONNECTION.value) {
      return 'NO_CONNECTION'
    }
    if (this.value === ServiceStatusDetail.DEPENDENCIES_UNINITIALIZED.value) {
      return 'DEPENDENCIES_UNINITIALIZED'
    }
    return '<unknown>'
  }

  /**
   * Inspect enum
   * @this {!ServiceStatusDetail}
   * @returns {!string} Enum value string
   */
  [util.inspect.custom] () {
    return this.toString()
  }

  /**
   * Convert enum to JSON
   * @this {!ServiceStatusDetail}
   * @returns {!number} Enum value for JSON
   */
  toJSON () {
    return this.value
  }

  /**
   * Create enum from object value
   * @param {!number} other Object value
   * @returns {!ServiceStatusDetail} Created enum
   */
  static fromObject (other) {
    return new ServiceStatusDetail(other)
  }
}

// noinspection PointlessArithmeticExpressionJS
ServiceStatusDetail.NO_CONNECTION = new ServiceStatusDetail(0 + 0)
// noinspection PointlessArithmeticExpressionJS
ServiceStatusDetail.DEPENDENCIES_UNINITIALIZED = new ServiceStatusDetail(0 + 1)

export { ServiceStatusDetail };

/**
 * Fast Binary Encoding ServiceStatusDetail field model
 */
class FieldModelServiceStatusDetail extends fbe.FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelServiceStatusDetail}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the value
   * @this {!FieldModelServiceStatusDetail}
   * @param {ServiceStatusDetail=} defaults Default value, defaults is new ServiceStatusDetail()
   * @returns {!ServiceStatusDetail} Result value
   */
  get (defaults = new ServiceStatusDetail()) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return new ServiceStatusDetail(this.readInt32(this.fbeOffset))
  }

  /**
   * Set the value
   * @this {!FieldModelServiceStatusDetail}
   * @param {!ServiceStatusDetail} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeInt32(this.fbeOffset, value.value)
  }
}

export { FieldModelServiceStatusDetail };
