// Automatically generated by the Fast Binary Encoding compiler, do not modify!
// https://github.com/chronoxor/FastBinaryEncoding
// Source: trade.fbe
// Version: 1.4.0.0

/* eslint-disable prefer-const */
'use strict'

import * as util from 'util'

import * as big from './big'
import * as int64 from './int64'
import * as uuid from './uuid'

import * as fbe from './fbe'
import * as core from './core'
import * as inner from './inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ServiceStatusDetail} from './trade/ServiceStatusDetail'
import {AccountsGetAllRequest} from './trade/AccountsGetAllRequest'
import {AccountsGetAllRequestModel} from './trade/AccountsGetAllRequest'
import {AccountsGetAllResponse} from './trade/AccountsGetAllResponse'
import {AccountsGetAllResponseModel} from './trade/AccountsGetAllResponse'
import {TradeEventsGetRequest} from './trade/TradeEventsGetRequest'
import {TradeEventsGetRequestModel} from './trade/TradeEventsGetRequest'
import {TradeEventsGetResponse} from './trade/TradeEventsGetResponse'
import {TradeEventsGetResponseModel} from './trade/TradeEventsGetResponse'
import {ServiceStatusUpdateNotify} from './trade/ServiceStatusUpdateNotify'
import {ServiceStatusUpdateNotifyModel} from './trade/ServiceStatusUpdateNotify'
import {ServiceStatusGetRequest} from './trade/ServiceStatusGetRequest'
import {ServiceStatusGetRequestModel} from './trade/ServiceStatusGetRequest'
import {ServiceStatusGetResponse} from './trade/ServiceStatusGetResponse'
import {ServiceStatusGetResponseModel} from './trade/ServiceStatusGetResponse'
import {TradeHistoryRequest} from './trade/TradeHistoryRequest'
import {TradeHistoryRequestModel} from './trade/TradeHistoryRequest'
import {TradeHistoryResponse} from './trade/TradeHistoryResponse'
import {TradeHistoryResponseModel} from './trade/TradeHistoryResponse'
import {PersonInsertParams} from './trade/PersonInsertParams'
import {PersonInsertParamsModel} from './trade/PersonInsertParams'
import {PersonsInsertParams} from './trade/PersonsInsertParams'
import {PersonsInsertParamsModel} from './trade/PersonsInsertParams'
import {PersonsInsertRequest} from './trade/PersonsInsertRequest'
import {PersonsInsertRequestModel} from './trade/PersonsInsertRequest'
import {PersonsInsertResponse} from './trade/PersonsInsertResponse'
import {PersonsInsertResponseModel} from './trade/PersonsInsertResponse'
import {PersonUpdateRequest} from './trade/PersonUpdateRequest'
import {PersonUpdateRequestModel} from './trade/PersonUpdateRequest'
import {PersonUpdateResponse} from './trade/PersonUpdateResponse'
import {PersonUpdateResponseModel} from './trade/PersonUpdateResponse'
import {TradeHistoryByIdRequest} from './trade/TradeHistoryByIdRequest'
import {TradeHistoryByIdRequestModel} from './trade/TradeHistoryByIdRequest'
import {TradeHistoryByIdResponse} from './trade/TradeHistoryByIdResponse'
import {TradeHistoryByIdResponseModel} from './trade/TradeHistoryByIdResponse'
import {ProfitClosedGetRequest} from './trade/ProfitClosedGetRequest'
import {ProfitClosedGetRequestModel} from './trade/ProfitClosedGetRequest'
import {ProfitClosedGetResponse} from './trade/ProfitClosedGetResponse'
import {ProfitClosedGetResponseModel} from './trade/ProfitClosedGetResponse'
import {MarginsGetAllRequest} from './trade/MarginsGetAllRequest'
import {MarginsGetAllRequestModel} from './trade/MarginsGetAllRequest'
import {MarginsGetAllResponse} from './trade/MarginsGetAllResponse'
import {MarginsGetAllResponseModel} from './trade/MarginsGetAllResponse'
import {OrdersGetAllRequest} from './trade/OrdersGetAllRequest'
import {OrdersGetAllRequestModel} from './trade/OrdersGetAllRequest'
import {OrdersGetAllResponse} from './trade/OrdersGetAllResponse'
import {OrdersGetAllResponseModel} from './trade/OrdersGetAllResponse'
import {AssetsGetAllRequest} from './trade/AssetsGetAllRequest'
import {AssetsGetAllRequestModel} from './trade/AssetsGetAllRequest'
import {AssetsGetAllResponse} from './trade/AssetsGetAllResponse'
import {AssetsGetAllResponseModel} from './trade/AssetsGetAllResponse'
import {PositionsGetAllRequest} from './trade/PositionsGetAllRequest'
import {PositionsGetAllRequestModel} from './trade/PositionsGetAllRequest'
import {PositionsGetAllResponse} from './trade/PositionsGetAllResponse'
import {PositionsGetAllResponseModel} from './trade/PositionsGetAllResponse'
import {PersonsGetAllRequest} from './trade/PersonsGetAllRequest'
import {PersonsGetAllRequestModel} from './trade/PersonsGetAllRequest'
import {PersonsGetAllResponse} from './trade/PersonsGetAllResponse'
import {PersonsGetAllResponseModel} from './trade/PersonsGetAllResponse'
import {DailySnapshotRequest} from './trade/DailySnapshotRequest'
import {DailySnapshotRequestModel} from './trade/DailySnapshotRequest'
import {DailySnapshotResponse} from './trade/DailySnapshotResponse'
import {DailySnapshotResponseModel} from './trade/DailySnapshotResponse'

// Protocol major version
const ProtocolVersionMajor = 0
// Protocol minor version
const ProtocolVersionMinor = 6

export { ProtocolVersionMajor };
export { ProtocolVersionMinor };

/**
 * Fast Binary Encoding trade sender
 */
class Sender extends fbe.Sender {
  /**
   * Initialize trade sender with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreSender = new core.Sender(this.buffer)
    this._innerSender = new inner.Sender(this.buffer)
    this._AccountsGetAllRequestModel = new AccountsGetAllRequestModel(this.buffer)
    this._AccountsGetAllResponseModel = new AccountsGetAllResponseModel(this.buffer)
    this._TradeEventsGetRequestModel = new TradeEventsGetRequestModel(this.buffer)
    this._TradeEventsGetResponseModel = new TradeEventsGetResponseModel(this.buffer)
    this._ServiceStatusUpdateNotifyModel = new ServiceStatusUpdateNotifyModel(this.buffer)
    this._ServiceStatusGetRequestModel = new ServiceStatusGetRequestModel(this.buffer)
    this._ServiceStatusGetResponseModel = new ServiceStatusGetResponseModel(this.buffer)
    this._TradeHistoryRequestModel = new TradeHistoryRequestModel(this.buffer)
    this._TradeHistoryResponseModel = new TradeHistoryResponseModel(this.buffer)
    this._PersonsInsertRequestModel = new PersonsInsertRequestModel(this.buffer)
    this._PersonsInsertResponseModel = new PersonsInsertResponseModel(this.buffer)
    this._PersonUpdateRequestModel = new PersonUpdateRequestModel(this.buffer)
    this._PersonUpdateResponseModel = new PersonUpdateResponseModel(this.buffer)
    this._TradeHistoryByIdRequestModel = new TradeHistoryByIdRequestModel(this.buffer)
    this._TradeHistoryByIdResponseModel = new TradeHistoryByIdResponseModel(this.buffer)
    this._ProfitClosedGetRequestModel = new ProfitClosedGetRequestModel(this.buffer)
    this._ProfitClosedGetResponseModel = new ProfitClosedGetResponseModel(this.buffer)
    this._MarginsGetAllRequestModel = new MarginsGetAllRequestModel(this.buffer)
    this._MarginsGetAllResponseModel = new MarginsGetAllResponseModel(this.buffer)
    this._OrdersGetAllRequestModel = new OrdersGetAllRequestModel(this.buffer)
    this._OrdersGetAllResponseModel = new OrdersGetAllResponseModel(this.buffer)
    this._AssetsGetAllRequestModel = new AssetsGetAllRequestModel(this.buffer)
    this._AssetsGetAllResponseModel = new AssetsGetAllResponseModel(this.buffer)
    this._PositionsGetAllRequestModel = new PositionsGetAllRequestModel(this.buffer)
    this._PositionsGetAllResponseModel = new PositionsGetAllResponseModel(this.buffer)
    this._PersonsGetAllRequestModel = new PersonsGetAllRequestModel(this.buffer)
    this._PersonsGetAllResponseModel = new PersonsGetAllResponseModel(this.buffer)
    this._DailySnapshotRequestModel = new DailySnapshotRequestModel(this.buffer)
    this._DailySnapshotResponseModel = new DailySnapshotResponseModel(this.buffer)
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
  }

  // Imported senders

  /**
   * Get imported core sender
   * @this {!Sender}
   * @returns {!core.Sender} core sender
   */
  get coreSender () {
    return this._coreSender
  }

  /**
   * Get imported inner sender
   * @this {!Sender}
   * @returns {!inner.Sender} inner sender
   */
  get innerSender () {
    return this._innerSender
  }

  // Sender models accessors

  /**
   * Get AccountsGetAllRequest model
   * @this {!Sender}
   * @returns {!AccountsGetAllRequestModel} AccountsGetAllRequest model
   */
  get AccountsGetAllRequestModel () {
    return this._AccountsGetAllRequestModel
  }

  /**
   * Get AccountsGetAllResponse model
   * @this {!Sender}
   * @returns {!AccountsGetAllResponseModel} AccountsGetAllResponse model
   */
  get AccountsGetAllResponseModel () {
    return this._AccountsGetAllResponseModel
  }

  /**
   * Get TradeEventsGetRequest model
   * @this {!Sender}
   * @returns {!TradeEventsGetRequestModel} TradeEventsGetRequest model
   */
  get TradeEventsGetRequestModel () {
    return this._TradeEventsGetRequestModel
  }

  /**
   * Get TradeEventsGetResponse model
   * @this {!Sender}
   * @returns {!TradeEventsGetResponseModel} TradeEventsGetResponse model
   */
  get TradeEventsGetResponseModel () {
    return this._TradeEventsGetResponseModel
  }

  /**
   * Get ServiceStatusUpdateNotify model
   * @this {!Sender}
   * @returns {!ServiceStatusUpdateNotifyModel} ServiceStatusUpdateNotify model
   */
  get ServiceStatusUpdateNotifyModel () {
    return this._ServiceStatusUpdateNotifyModel
  }

  /**
   * Get ServiceStatusGetRequest model
   * @this {!Sender}
   * @returns {!ServiceStatusGetRequestModel} ServiceStatusGetRequest model
   */
  get ServiceStatusGetRequestModel () {
    return this._ServiceStatusGetRequestModel
  }

  /**
   * Get ServiceStatusGetResponse model
   * @this {!Sender}
   * @returns {!ServiceStatusGetResponseModel} ServiceStatusGetResponse model
   */
  get ServiceStatusGetResponseModel () {
    return this._ServiceStatusGetResponseModel
  }

  /**
   * Get TradeHistoryRequest model
   * @this {!Sender}
   * @returns {!TradeHistoryRequestModel} TradeHistoryRequest model
   */
  get TradeHistoryRequestModel () {
    return this._TradeHistoryRequestModel
  }

  /**
   * Get TradeHistoryResponse model
   * @this {!Sender}
   * @returns {!TradeHistoryResponseModel} TradeHistoryResponse model
   */
  get TradeHistoryResponseModel () {
    return this._TradeHistoryResponseModel
  }

  /**
   * Get PersonsInsertRequest model
   * @this {!Sender}
   * @returns {!PersonsInsertRequestModel} PersonsInsertRequest model
   */
  get PersonsInsertRequestModel () {
    return this._PersonsInsertRequestModel
  }

  /**
   * Get PersonsInsertResponse model
   * @this {!Sender}
   * @returns {!PersonsInsertResponseModel} PersonsInsertResponse model
   */
  get PersonsInsertResponseModel () {
    return this._PersonsInsertResponseModel
  }

  /**
   * Get PersonUpdateRequest model
   * @this {!Sender}
   * @returns {!PersonUpdateRequestModel} PersonUpdateRequest model
   */
  get PersonUpdateRequestModel () {
    return this._PersonUpdateRequestModel
  }

  /**
   * Get PersonUpdateResponse model
   * @this {!Sender}
   * @returns {!PersonUpdateResponseModel} PersonUpdateResponse model
   */
  get PersonUpdateResponseModel () {
    return this._PersonUpdateResponseModel
  }

  /**
   * Get TradeHistoryByIdRequest model
   * @this {!Sender}
   * @returns {!TradeHistoryByIdRequestModel} TradeHistoryByIdRequest model
   */
  get TradeHistoryByIdRequestModel () {
    return this._TradeHistoryByIdRequestModel
  }

  /**
   * Get TradeHistoryByIdResponse model
   * @this {!Sender}
   * @returns {!TradeHistoryByIdResponseModel} TradeHistoryByIdResponse model
   */
  get TradeHistoryByIdResponseModel () {
    return this._TradeHistoryByIdResponseModel
  }

  /**
   * Get ProfitClosedGetRequest model
   * @this {!Sender}
   * @returns {!ProfitClosedGetRequestModel} ProfitClosedGetRequest model
   */
  get ProfitClosedGetRequestModel () {
    return this._ProfitClosedGetRequestModel
  }

  /**
   * Get ProfitClosedGetResponse model
   * @this {!Sender}
   * @returns {!ProfitClosedGetResponseModel} ProfitClosedGetResponse model
   */
  get ProfitClosedGetResponseModel () {
    return this._ProfitClosedGetResponseModel
  }

  /**
   * Get MarginsGetAllRequest model
   * @this {!Sender}
   * @returns {!MarginsGetAllRequestModel} MarginsGetAllRequest model
   */
  get MarginsGetAllRequestModel () {
    return this._MarginsGetAllRequestModel
  }

  /**
   * Get MarginsGetAllResponse model
   * @this {!Sender}
   * @returns {!MarginsGetAllResponseModel} MarginsGetAllResponse model
   */
  get MarginsGetAllResponseModel () {
    return this._MarginsGetAllResponseModel
  }

  /**
   * Get OrdersGetAllRequest model
   * @this {!Sender}
   * @returns {!OrdersGetAllRequestModel} OrdersGetAllRequest model
   */
  get OrdersGetAllRequestModel () {
    return this._OrdersGetAllRequestModel
  }

  /**
   * Get OrdersGetAllResponse model
   * @this {!Sender}
   * @returns {!OrdersGetAllResponseModel} OrdersGetAllResponse model
   */
  get OrdersGetAllResponseModel () {
    return this._OrdersGetAllResponseModel
  }

  /**
   * Get AssetsGetAllRequest model
   * @this {!Sender}
   * @returns {!AssetsGetAllRequestModel} AssetsGetAllRequest model
   */
  get AssetsGetAllRequestModel () {
    return this._AssetsGetAllRequestModel
  }

  /**
   * Get AssetsGetAllResponse model
   * @this {!Sender}
   * @returns {!AssetsGetAllResponseModel} AssetsGetAllResponse model
   */
  get AssetsGetAllResponseModel () {
    return this._AssetsGetAllResponseModel
  }

  /**
   * Get PositionsGetAllRequest model
   * @this {!Sender}
   * @returns {!PositionsGetAllRequestModel} PositionsGetAllRequest model
   */
  get PositionsGetAllRequestModel () {
    return this._PositionsGetAllRequestModel
  }

  /**
   * Get PositionsGetAllResponse model
   * @this {!Sender}
   * @returns {!PositionsGetAllResponseModel} PositionsGetAllResponse model
   */
  get PositionsGetAllResponseModel () {
    return this._PositionsGetAllResponseModel
  }

  /**
   * Get PersonsGetAllRequest model
   * @this {!Sender}
   * @returns {!PersonsGetAllRequestModel} PersonsGetAllRequest model
   */
  get PersonsGetAllRequestModel () {
    return this._PersonsGetAllRequestModel
  }

  /**
   * Get PersonsGetAllResponse model
   * @this {!Sender}
   * @returns {!PersonsGetAllResponseModel} PersonsGetAllResponse model
   */
  get PersonsGetAllResponseModel () {
    return this._PersonsGetAllResponseModel
  }

  /**
   * Get DailySnapshotRequest model
   * @this {!Sender}
   * @returns {!DailySnapshotRequestModel} DailySnapshotRequest model
   */
  get DailySnapshotRequestModel () {
    return this._DailySnapshotRequestModel
  }

  /**
   * Get DailySnapshotResponse model
   * @this {!Sender}
   * @returns {!DailySnapshotResponseModel} DailySnapshotResponse model
   */
  get DailySnapshotResponseModel () {
    return this._DailySnapshotResponseModel
  }

  // Send methods

  /**
   * Send value
   * @this {!Sender}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof AccountsGetAllRequest) && (value.fbeType === this.AccountsGetAllRequestModel.fbeType)) {
      return this.send_AccountsGetAllRequest(value)
    }
    if ((value instanceof AccountsGetAllResponse) && (value.fbeType === this.AccountsGetAllResponseModel.fbeType)) {
      return this.send_AccountsGetAllResponse(value)
    }
    if ((value instanceof TradeEventsGetRequest) && (value.fbeType === this.TradeEventsGetRequestModel.fbeType)) {
      return this.send_TradeEventsGetRequest(value)
    }
    if ((value instanceof TradeEventsGetResponse) && (value.fbeType === this.TradeEventsGetResponseModel.fbeType)) {
      return this.send_TradeEventsGetResponse(value)
    }
    if ((value instanceof ServiceStatusUpdateNotify) && (value.fbeType === this.ServiceStatusUpdateNotifyModel.fbeType)) {
      return this.send_ServiceStatusUpdateNotify(value)
    }
    if ((value instanceof ServiceStatusGetRequest) && (value.fbeType === this.ServiceStatusGetRequestModel.fbeType)) {
      return this.send_ServiceStatusGetRequest(value)
    }
    if ((value instanceof ServiceStatusGetResponse) && (value.fbeType === this.ServiceStatusGetResponseModel.fbeType)) {
      return this.send_ServiceStatusGetResponse(value)
    }
    if ((value instanceof TradeHistoryRequest) && (value.fbeType === this.TradeHistoryRequestModel.fbeType)) {
      return this.send_TradeHistoryRequest(value)
    }
    if ((value instanceof TradeHistoryResponse) && (value.fbeType === this.TradeHistoryResponseModel.fbeType)) {
      return this.send_TradeHistoryResponse(value)
    }
    if ((value instanceof PersonsInsertRequest) && (value.fbeType === this.PersonsInsertRequestModel.fbeType)) {
      return this.send_PersonsInsertRequest(value)
    }
    if ((value instanceof PersonsInsertResponse) && (value.fbeType === this.PersonsInsertResponseModel.fbeType)) {
      return this.send_PersonsInsertResponse(value)
    }
    if ((value instanceof PersonUpdateRequest) && (value.fbeType === this.PersonUpdateRequestModel.fbeType)) {
      return this.send_PersonUpdateRequest(value)
    }
    if ((value instanceof PersonUpdateResponse) && (value.fbeType === this.PersonUpdateResponseModel.fbeType)) {
      return this.send_PersonUpdateResponse(value)
    }
    if ((value instanceof TradeHistoryByIdRequest) && (value.fbeType === this.TradeHistoryByIdRequestModel.fbeType)) {
      return this.send_TradeHistoryByIdRequest(value)
    }
    if ((value instanceof TradeHistoryByIdResponse) && (value.fbeType === this.TradeHistoryByIdResponseModel.fbeType)) {
      return this.send_TradeHistoryByIdResponse(value)
    }
    if ((value instanceof ProfitClosedGetRequest) && (value.fbeType === this.ProfitClosedGetRequestModel.fbeType)) {
      return this.send_ProfitClosedGetRequest(value)
    }
    if ((value instanceof ProfitClosedGetResponse) && (value.fbeType === this.ProfitClosedGetResponseModel.fbeType)) {
      return this.send_ProfitClosedGetResponse(value)
    }
    if ((value instanceof MarginsGetAllRequest) && (value.fbeType === this.MarginsGetAllRequestModel.fbeType)) {
      return this.send_MarginsGetAllRequest(value)
    }
    if ((value instanceof MarginsGetAllResponse) && (value.fbeType === this.MarginsGetAllResponseModel.fbeType)) {
      return this.send_MarginsGetAllResponse(value)
    }
    if ((value instanceof OrdersGetAllRequest) && (value.fbeType === this.OrdersGetAllRequestModel.fbeType)) {
      return this.send_OrdersGetAllRequest(value)
    }
    if ((value instanceof OrdersGetAllResponse) && (value.fbeType === this.OrdersGetAllResponseModel.fbeType)) {
      return this.send_OrdersGetAllResponse(value)
    }
    if ((value instanceof AssetsGetAllRequest) && (value.fbeType === this.AssetsGetAllRequestModel.fbeType)) {
      return this.send_AssetsGetAllRequest(value)
    }
    if ((value instanceof AssetsGetAllResponse) && (value.fbeType === this.AssetsGetAllResponseModel.fbeType)) {
      return this.send_AssetsGetAllResponse(value)
    }
    if ((value instanceof PositionsGetAllRequest) && (value.fbeType === this.PositionsGetAllRequestModel.fbeType)) {
      return this.send_PositionsGetAllRequest(value)
    }
    if ((value instanceof PositionsGetAllResponse) && (value.fbeType === this.PositionsGetAllResponseModel.fbeType)) {
      return this.send_PositionsGetAllResponse(value)
    }
    if ((value instanceof PersonsGetAllRequest) && (value.fbeType === this.PersonsGetAllRequestModel.fbeType)) {
      return this.send_PersonsGetAllRequest(value)
    }
    if ((value instanceof PersonsGetAllResponse) && (value.fbeType === this.PersonsGetAllResponseModel.fbeType)) {
      return this.send_PersonsGetAllResponse(value)
    }
    if ((value instanceof DailySnapshotRequest) && (value.fbeType === this.DailySnapshotRequestModel.fbeType)) {
      return this.send_DailySnapshotRequest(value)
    }
    if ((value instanceof DailySnapshotResponse) && (value.fbeType === this.DailySnapshotResponseModel.fbeType)) {
      return this.send_DailySnapshotResponse(value)
    }
    let result = 0
    result = this._coreSender.send(value)
    if (result > 0) {
      return result
    }
    result = this._innerSender.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send AccountsGetAllRequest value
   * @this {!Sender}
   * @param {!AccountsGetAllRequest} value AccountsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'trade.AccountsGetAllRequest serialization failed!')
    console.assert(this.AccountsGetAllRequestModel.verify(), 'trade.AccountsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountsGetAllResponse value
   * @this {!Sender}
   * @param {!AccountsGetAllResponse} value AccountsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'trade.AccountsGetAllResponse serialization failed!')
    console.assert(this.AccountsGetAllResponseModel.verify(), 'trade.AccountsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsGetRequest value
   * @this {!Sender}
   * @param {!TradeEventsGetRequest} value TradeEventsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'trade.TradeEventsGetRequest serialization failed!')
    console.assert(this.TradeEventsGetRequestModel.verify(), 'trade.TradeEventsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsGetResponse value
   * @this {!Sender}
   * @param {!TradeEventsGetResponse} value TradeEventsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'trade.TradeEventsGetResponse serialization failed!')
    console.assert(this.TradeEventsGetResponseModel.verify(), 'trade.TradeEventsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ServiceStatusUpdateNotify value
   * @this {!Sender}
   * @param {!ServiceStatusUpdateNotify} value ServiceStatusUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ServiceStatusUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ServiceStatusUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'trade.ServiceStatusUpdateNotify serialization failed!')
    console.assert(this.ServiceStatusUpdateNotifyModel.verify(), 'trade.ServiceStatusUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ServiceStatusGetRequest value
   * @this {!Sender}
   * @param {!ServiceStatusGetRequest} value ServiceStatusGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ServiceStatusGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ServiceStatusGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'trade.ServiceStatusGetRequest serialization failed!')
    console.assert(this.ServiceStatusGetRequestModel.verify(), 'trade.ServiceStatusGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ServiceStatusGetResponse value
   * @this {!Sender}
   * @param {!ServiceStatusGetResponse} value ServiceStatusGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ServiceStatusGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ServiceStatusGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'trade.ServiceStatusGetResponse serialization failed!')
    console.assert(this.ServiceStatusGetResponseModel.verify(), 'trade.ServiceStatusGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryRequest value
   * @this {!Sender}
   * @param {!TradeHistoryRequest} value TradeHistoryRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryRequestModel.serialize(value)
    console.assert((serialized > 0), 'trade.TradeHistoryRequest serialization failed!')
    console.assert(this.TradeHistoryRequestModel.verify(), 'trade.TradeHistoryRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryResponse value
   * @this {!Sender}
   * @param {!TradeHistoryResponse} value TradeHistoryResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryResponseModel.serialize(value)
    console.assert((serialized > 0), 'trade.TradeHistoryResponse serialization failed!')
    console.assert(this.TradeHistoryResponseModel.verify(), 'trade.TradeHistoryResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsInsertRequest value
   * @this {!Sender}
   * @param {!PersonsInsertRequest} value PersonsInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsInsertRequestModel.serialize(value)
    console.assert((serialized > 0), 'trade.PersonsInsertRequest serialization failed!')
    console.assert(this.PersonsInsertRequestModel.verify(), 'trade.PersonsInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsInsertResponse value
   * @this {!Sender}
   * @param {!PersonsInsertResponse} value PersonsInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsInsertResponseModel.serialize(value)
    console.assert((serialized > 0), 'trade.PersonsInsertResponse serialization failed!')
    console.assert(this.PersonsInsertResponseModel.verify(), 'trade.PersonsInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonUpdateRequest value
   * @this {!Sender}
   * @param {!PersonUpdateRequest} value PersonUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonUpdateRequestModel.serialize(value)
    console.assert((serialized > 0), 'trade.PersonUpdateRequest serialization failed!')
    console.assert(this.PersonUpdateRequestModel.verify(), 'trade.PersonUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonUpdateResponse value
   * @this {!Sender}
   * @param {!PersonUpdateResponse} value PersonUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonUpdateResponseModel.serialize(value)
    console.assert((serialized > 0), 'trade.PersonUpdateResponse serialization failed!')
    console.assert(this.PersonUpdateResponseModel.verify(), 'trade.PersonUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryByIdRequest value
   * @this {!Sender}
   * @param {!TradeHistoryByIdRequest} value TradeHistoryByIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryByIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryByIdRequestModel.serialize(value)
    console.assert((serialized > 0), 'trade.TradeHistoryByIdRequest serialization failed!')
    console.assert(this.TradeHistoryByIdRequestModel.verify(), 'trade.TradeHistoryByIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryByIdResponse value
   * @this {!Sender}
   * @param {!TradeHistoryByIdResponse} value TradeHistoryByIdResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryByIdResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryByIdResponseModel.serialize(value)
    console.assert((serialized > 0), 'trade.TradeHistoryByIdResponse serialization failed!')
    console.assert(this.TradeHistoryByIdResponseModel.verify(), 'trade.TradeHistoryByIdResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ProfitClosedGetRequest value
   * @this {!Sender}
   * @param {!ProfitClosedGetRequest} value ProfitClosedGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ProfitClosedGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ProfitClosedGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'trade.ProfitClosedGetRequest serialization failed!')
    console.assert(this.ProfitClosedGetRequestModel.verify(), 'trade.ProfitClosedGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ProfitClosedGetResponse value
   * @this {!Sender}
   * @param {!ProfitClosedGetResponse} value ProfitClosedGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ProfitClosedGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ProfitClosedGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'trade.ProfitClosedGetResponse serialization failed!')
    console.assert(this.ProfitClosedGetResponseModel.verify(), 'trade.ProfitClosedGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginsGetAllRequest value
   * @this {!Sender}
   * @param {!MarginsGetAllRequest} value MarginsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'trade.MarginsGetAllRequest serialization failed!')
    console.assert(this.MarginsGetAllRequestModel.verify(), 'trade.MarginsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginsGetAllResponse value
   * @this {!Sender}
   * @param {!MarginsGetAllResponse} value MarginsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'trade.MarginsGetAllResponse serialization failed!')
    console.assert(this.MarginsGetAllResponseModel.verify(), 'trade.MarginsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetAllRequest value
   * @this {!Sender}
   * @param {!OrdersGetAllRequest} value OrdersGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'trade.OrdersGetAllRequest serialization failed!')
    console.assert(this.OrdersGetAllRequestModel.verify(), 'trade.OrdersGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetAllResponse value
   * @this {!Sender}
   * @param {!OrdersGetAllResponse} value OrdersGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'trade.OrdersGetAllResponse serialization failed!')
    console.assert(this.OrdersGetAllResponseModel.verify(), 'trade.OrdersGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetsGetAllRequest value
   * @this {!Sender}
   * @param {!AssetsGetAllRequest} value AssetsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'trade.AssetsGetAllRequest serialization failed!')
    console.assert(this.AssetsGetAllRequestModel.verify(), 'trade.AssetsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetsGetAllResponse value
   * @this {!Sender}
   * @param {!AssetsGetAllResponse} value AssetsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'trade.AssetsGetAllResponse serialization failed!')
    console.assert(this.AssetsGetAllResponseModel.verify(), 'trade.AssetsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionsGetAllRequest value
   * @this {!Sender}
   * @param {!PositionsGetAllRequest} value PositionsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'trade.PositionsGetAllRequest serialization failed!')
    console.assert(this.PositionsGetAllRequestModel.verify(), 'trade.PositionsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionsGetAllResponse value
   * @this {!Sender}
   * @param {!PositionsGetAllResponse} value PositionsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'trade.PositionsGetAllResponse serialization failed!')
    console.assert(this.PositionsGetAllResponseModel.verify(), 'trade.PositionsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsGetAllRequest value
   * @this {!Sender}
   * @param {!PersonsGetAllRequest} value PersonsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'trade.PersonsGetAllRequest serialization failed!')
    console.assert(this.PersonsGetAllRequestModel.verify(), 'trade.PersonsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsGetAllResponse value
   * @this {!Sender}
   * @param {!PersonsGetAllResponse} value PersonsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'trade.PersonsGetAllResponse serialization failed!')
    console.assert(this.PersonsGetAllResponseModel.verify(), 'trade.PersonsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DailySnapshotRequest value
   * @this {!Sender}
   * @param {!DailySnapshotRequest} value DailySnapshotRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DailySnapshotRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DailySnapshotRequestModel.serialize(value)
    console.assert((serialized > 0), 'trade.DailySnapshotRequest serialization failed!')
    console.assert(this.DailySnapshotRequestModel.verify(), 'trade.DailySnapshotRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DailySnapshotResponse value
   * @this {!Sender}
   * @param {!DailySnapshotResponse} value DailySnapshotResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DailySnapshotResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DailySnapshotResponseModel.serialize(value)
    console.assert((serialized > 0), 'trade.DailySnapshotResponse serialization failed!')
    console.assert(this.DailySnapshotResponseModel.verify(), 'trade.DailySnapshotResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Sender}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'trade.Sender.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Sender}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreSender.onSendHandler = handler
    this._innerSender.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Sender}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreSender.onSendLogHandler = handler
    this._innerSender.onSendLogHandler = handler
  }
}

export {Sender };

/**
 * Fast Binary Encoding trade receiver
 */
class Receiver extends fbe.Receiver {
  /**
   * Initialize trade receiver with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreReceiver = new core.Receiver(this.buffer)
    this._innerReceiver = new inner.Receiver(this.buffer)
    this._AccountsGetAllRequestValue = new AccountsGetAllRequest()
    this._AccountsGetAllRequestModel = new AccountsGetAllRequestModel()
    this._AccountsGetAllResponseValue = new AccountsGetAllResponse()
    this._AccountsGetAllResponseModel = new AccountsGetAllResponseModel()
    this._TradeEventsGetRequestValue = new TradeEventsGetRequest()
    this._TradeEventsGetRequestModel = new TradeEventsGetRequestModel()
    this._TradeEventsGetResponseValue = new TradeEventsGetResponse()
    this._TradeEventsGetResponseModel = new TradeEventsGetResponseModel()
    this._ServiceStatusUpdateNotifyValue = new ServiceStatusUpdateNotify()
    this._ServiceStatusUpdateNotifyModel = new ServiceStatusUpdateNotifyModel()
    this._ServiceStatusGetRequestValue = new ServiceStatusGetRequest()
    this._ServiceStatusGetRequestModel = new ServiceStatusGetRequestModel()
    this._ServiceStatusGetResponseValue = new ServiceStatusGetResponse()
    this._ServiceStatusGetResponseModel = new ServiceStatusGetResponseModel()
    this._TradeHistoryRequestValue = new TradeHistoryRequest()
    this._TradeHistoryRequestModel = new TradeHistoryRequestModel()
    this._TradeHistoryResponseValue = new TradeHistoryResponse()
    this._TradeHistoryResponseModel = new TradeHistoryResponseModel()
    this._PersonsInsertRequestValue = new PersonsInsertRequest()
    this._PersonsInsertRequestModel = new PersonsInsertRequestModel()
    this._PersonsInsertResponseValue = new PersonsInsertResponse()
    this._PersonsInsertResponseModel = new PersonsInsertResponseModel()
    this._PersonUpdateRequestValue = new PersonUpdateRequest()
    this._PersonUpdateRequestModel = new PersonUpdateRequestModel()
    this._PersonUpdateResponseValue = new PersonUpdateResponse()
    this._PersonUpdateResponseModel = new PersonUpdateResponseModel()
    this._TradeHistoryByIdRequestValue = new TradeHistoryByIdRequest()
    this._TradeHistoryByIdRequestModel = new TradeHistoryByIdRequestModel()
    this._TradeHistoryByIdResponseValue = new TradeHistoryByIdResponse()
    this._TradeHistoryByIdResponseModel = new TradeHistoryByIdResponseModel()
    this._ProfitClosedGetRequestValue = new ProfitClosedGetRequest()
    this._ProfitClosedGetRequestModel = new ProfitClosedGetRequestModel()
    this._ProfitClosedGetResponseValue = new ProfitClosedGetResponse()
    this._ProfitClosedGetResponseModel = new ProfitClosedGetResponseModel()
    this._MarginsGetAllRequestValue = new MarginsGetAllRequest()
    this._MarginsGetAllRequestModel = new MarginsGetAllRequestModel()
    this._MarginsGetAllResponseValue = new MarginsGetAllResponse()
    this._MarginsGetAllResponseModel = new MarginsGetAllResponseModel()
    this._OrdersGetAllRequestValue = new OrdersGetAllRequest()
    this._OrdersGetAllRequestModel = new OrdersGetAllRequestModel()
    this._OrdersGetAllResponseValue = new OrdersGetAllResponse()
    this._OrdersGetAllResponseModel = new OrdersGetAllResponseModel()
    this._AssetsGetAllRequestValue = new AssetsGetAllRequest()
    this._AssetsGetAllRequestModel = new AssetsGetAllRequestModel()
    this._AssetsGetAllResponseValue = new AssetsGetAllResponse()
    this._AssetsGetAllResponseModel = new AssetsGetAllResponseModel()
    this._PositionsGetAllRequestValue = new PositionsGetAllRequest()
    this._PositionsGetAllRequestModel = new PositionsGetAllRequestModel()
    this._PositionsGetAllResponseValue = new PositionsGetAllResponse()
    this._PositionsGetAllResponseModel = new PositionsGetAllResponseModel()
    this._PersonsGetAllRequestValue = new PersonsGetAllRequest()
    this._PersonsGetAllRequestModel = new PersonsGetAllRequestModel()
    this._PersonsGetAllResponseValue = new PersonsGetAllResponse()
    this._PersonsGetAllResponseModel = new PersonsGetAllResponseModel()
    this._DailySnapshotRequestValue = new DailySnapshotRequest()
    this._DailySnapshotRequestModel = new DailySnapshotRequestModel()
    this._DailySnapshotResponseValue = new DailySnapshotResponse()
    this._DailySnapshotResponseModel = new DailySnapshotResponseModel()
    this.onReceiveLogHandler = this.onReceiveLog
  }

  // Imported receivers

  /**
   * Get imported core receiver
   * @this {!Receiver}
   * @returns {Receiver} core receiver
   */
  get coreReceiver () {
    return this._coreReceiver
  }

  /**
   * Set imported core receiver
   * @this {!Receiver}
   * @param {Receiver} receiver core receiver
   */
  set coreReceiver (receiver) {
    this._coreReceiver = receiver
  }

  /**
   * Get imported inner receiver
   * @this {!Receiver}
   * @returns {Receiver} inner receiver
   */
  get innerReceiver () {
    return this._innerReceiver
  }

  /**
   * Set imported inner receiver
   * @this {!Receiver}
   * @param {Receiver} receiver inner receiver
   */
  set innerReceiver (receiver) {
    this._innerReceiver = receiver
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * AccountsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!AccountsGetAllRequest} value AccountsGetAllRequest received value
   */
  onReceive_AccountsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * AccountsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!AccountsGetAllResponse} value AccountsGetAllResponse received value
   */
  onReceive_AccountsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * TradeEventsGetRequest receive handler
   * @this {!Receiver}
   * @param {!TradeEventsGetRequest} value TradeEventsGetRequest received value
   */
  onReceive_TradeEventsGetRequest (value) {}  // eslint-disable-line

  /**
   * TradeEventsGetResponse receive handler
   * @this {!Receiver}
   * @param {!TradeEventsGetResponse} value TradeEventsGetResponse received value
   */
  onReceive_TradeEventsGetResponse (value) {}  // eslint-disable-line

  /**
   * ServiceStatusUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!ServiceStatusUpdateNotify} value ServiceStatusUpdateNotify received value
   */
  onReceive_ServiceStatusUpdateNotify (value) {}  // eslint-disable-line

  /**
   * ServiceStatusGetRequest receive handler
   * @this {!Receiver}
   * @param {!ServiceStatusGetRequest} value ServiceStatusGetRequest received value
   */
  onReceive_ServiceStatusGetRequest (value) {}  // eslint-disable-line

  /**
   * ServiceStatusGetResponse receive handler
   * @this {!Receiver}
   * @param {!ServiceStatusGetResponse} value ServiceStatusGetResponse received value
   */
  onReceive_ServiceStatusGetResponse (value) {}  // eslint-disable-line

  /**
   * TradeHistoryRequest receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryRequest} value TradeHistoryRequest received value
   */
  onReceive_TradeHistoryRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryResponse receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryResponse} value TradeHistoryResponse received value
   */
  onReceive_TradeHistoryResponse (value) {}  // eslint-disable-line

  /**
   * PersonsInsertRequest receive handler
   * @this {!Receiver}
   * @param {!PersonsInsertRequest} value PersonsInsertRequest received value
   */
  onReceive_PersonsInsertRequest (value) {}  // eslint-disable-line

  /**
   * PersonsInsertResponse receive handler
   * @this {!Receiver}
   * @param {!PersonsInsertResponse} value PersonsInsertResponse received value
   */
  onReceive_PersonsInsertResponse (value) {}  // eslint-disable-line

  /**
   * PersonUpdateRequest receive handler
   * @this {!Receiver}
   * @param {!PersonUpdateRequest} value PersonUpdateRequest received value
   */
  onReceive_PersonUpdateRequest (value) {}  // eslint-disable-line

  /**
   * PersonUpdateResponse receive handler
   * @this {!Receiver}
   * @param {!PersonUpdateResponse} value PersonUpdateResponse received value
   */
  onReceive_PersonUpdateResponse (value) {}  // eslint-disable-line

  /**
   * TradeHistoryByIdRequest receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryByIdRequest} value TradeHistoryByIdRequest received value
   */
  onReceive_TradeHistoryByIdRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryByIdResponse receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryByIdResponse} value TradeHistoryByIdResponse received value
   */
  onReceive_TradeHistoryByIdResponse (value) {}  // eslint-disable-line

  /**
   * ProfitClosedGetRequest receive handler
   * @this {!Receiver}
   * @param {!ProfitClosedGetRequest} value ProfitClosedGetRequest received value
   */
  onReceive_ProfitClosedGetRequest (value) {}  // eslint-disable-line

  /**
   * ProfitClosedGetResponse receive handler
   * @this {!Receiver}
   * @param {!ProfitClosedGetResponse} value ProfitClosedGetResponse received value
   */
  onReceive_ProfitClosedGetResponse (value) {}  // eslint-disable-line

  /**
   * MarginsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!MarginsGetAllRequest} value MarginsGetAllRequest received value
   */
  onReceive_MarginsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * MarginsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!MarginsGetAllResponse} value MarginsGetAllResponse received value
   */
  onReceive_MarginsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * OrdersGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!OrdersGetAllRequest} value OrdersGetAllRequest received value
   */
  onReceive_OrdersGetAllRequest (value) {}  // eslint-disable-line

  /**
   * OrdersGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!OrdersGetAllResponse} value OrdersGetAllResponse received value
   */
  onReceive_OrdersGetAllResponse (value) {}  // eslint-disable-line

  /**
   * AssetsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!AssetsGetAllRequest} value AssetsGetAllRequest received value
   */
  onReceive_AssetsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * AssetsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!AssetsGetAllResponse} value AssetsGetAllResponse received value
   */
  onReceive_AssetsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * PositionsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!PositionsGetAllRequest} value PositionsGetAllRequest received value
   */
  onReceive_PositionsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * PositionsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!PositionsGetAllResponse} value PositionsGetAllResponse received value
   */
  onReceive_PositionsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * PersonsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!PersonsGetAllRequest} value PersonsGetAllRequest received value
   */
  onReceive_PersonsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * PersonsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!PersonsGetAllResponse} value PersonsGetAllResponse received value
   */
  onReceive_PersonsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * DailySnapshotRequest receive handler
   * @this {!Receiver}
   * @param {!DailySnapshotRequest} value DailySnapshotRequest received value
   */
  onReceive_DailySnapshotRequest (value) {}  // eslint-disable-line

  /**
   * DailySnapshotResponse receive handler
   * @this {!Receiver}
   * @param {!DailySnapshotResponse} value DailySnapshotResponse received value
   */
  onReceive_DailySnapshotResponse (value) {}  // eslint-disable-line

  /**
   * trade receive message handler
   * @this {!Receiver}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case AccountsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetAllRequestModel.verify(), 'trade.AccountsGetAllRequest validation failed!')
        let deserialized = this._AccountsGetAllRequestModel.deserialize(this._AccountsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'trade.AccountsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountsGetAllRequestValue)
        this.onReceive_AccountsGetAllRequest(this._AccountsGetAllRequestValue)
        return true
      }
      case AccountsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetAllResponseModel.verify(), 'trade.AccountsGetAllResponse validation failed!')
        let deserialized = this._AccountsGetAllResponseModel.deserialize(this._AccountsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'trade.AccountsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountsGetAllResponseValue)
        this.onReceive_AccountsGetAllResponse(this._AccountsGetAllResponseValue)
        return true
      }
      case TradeEventsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsGetRequestModel.verify(), 'trade.TradeEventsGetRequest validation failed!')
        let deserialized = this._TradeEventsGetRequestModel.deserialize(this._TradeEventsGetRequestValue)
        console.assert((deserialized.size > 0), 'trade.TradeEventsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsGetRequestValue)
        this.onReceive_TradeEventsGetRequest(this._TradeEventsGetRequestValue)
        return true
      }
      case TradeEventsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsGetResponseModel.verify(), 'trade.TradeEventsGetResponse validation failed!')
        let deserialized = this._TradeEventsGetResponseModel.deserialize(this._TradeEventsGetResponseValue)
        console.assert((deserialized.size > 0), 'trade.TradeEventsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsGetResponseValue)
        this.onReceive_TradeEventsGetResponse(this._TradeEventsGetResponseValue)
        return true
      }
      case ServiceStatusUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ServiceStatusUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ServiceStatusUpdateNotifyModel.verify(), 'trade.ServiceStatusUpdateNotify validation failed!')
        let deserialized = this._ServiceStatusUpdateNotifyModel.deserialize(this._ServiceStatusUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'trade.ServiceStatusUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ServiceStatusUpdateNotifyValue)
        this.onReceive_ServiceStatusUpdateNotify(this._ServiceStatusUpdateNotifyValue)
        return true
      }
      case ServiceStatusGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ServiceStatusGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ServiceStatusGetRequestModel.verify(), 'trade.ServiceStatusGetRequest validation failed!')
        let deserialized = this._ServiceStatusGetRequestModel.deserialize(this._ServiceStatusGetRequestValue)
        console.assert((deserialized.size > 0), 'trade.ServiceStatusGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ServiceStatusGetRequestValue)
        this.onReceive_ServiceStatusGetRequest(this._ServiceStatusGetRequestValue)
        return true
      }
      case ServiceStatusGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ServiceStatusGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ServiceStatusGetResponseModel.verify(), 'trade.ServiceStatusGetResponse validation failed!')
        let deserialized = this._ServiceStatusGetResponseModel.deserialize(this._ServiceStatusGetResponseValue)
        console.assert((deserialized.size > 0), 'trade.ServiceStatusGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ServiceStatusGetResponseValue)
        this.onReceive_ServiceStatusGetResponse(this._ServiceStatusGetResponseValue)
        return true
      }
      case TradeHistoryRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryRequestModel.verify(), 'trade.TradeHistoryRequest validation failed!')
        let deserialized = this._TradeHistoryRequestModel.deserialize(this._TradeHistoryRequestValue)
        console.assert((deserialized.size > 0), 'trade.TradeHistoryRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryRequestValue)
        this.onReceive_TradeHistoryRequest(this._TradeHistoryRequestValue)
        return true
      }
      case TradeHistoryResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryResponseModel.verify(), 'trade.TradeHistoryResponse validation failed!')
        let deserialized = this._TradeHistoryResponseModel.deserialize(this._TradeHistoryResponseValue)
        console.assert((deserialized.size > 0), 'trade.TradeHistoryResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryResponseValue)
        this.onReceive_TradeHistoryResponse(this._TradeHistoryResponseValue)
        return true
      }
      case PersonsInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsInsertRequestModel.verify(), 'trade.PersonsInsertRequest validation failed!')
        let deserialized = this._PersonsInsertRequestModel.deserialize(this._PersonsInsertRequestValue)
        console.assert((deserialized.size > 0), 'trade.PersonsInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsInsertRequestValue)
        this.onReceive_PersonsInsertRequest(this._PersonsInsertRequestValue)
        return true
      }
      case PersonsInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsInsertResponseModel.verify(), 'trade.PersonsInsertResponse validation failed!')
        let deserialized = this._PersonsInsertResponseModel.deserialize(this._PersonsInsertResponseValue)
        console.assert((deserialized.size > 0), 'trade.PersonsInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsInsertResponseValue)
        this.onReceive_PersonsInsertResponse(this._PersonsInsertResponseValue)
        return true
      }
      case PersonUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdateRequestModel.verify(), 'trade.PersonUpdateRequest validation failed!')
        let deserialized = this._PersonUpdateRequestModel.deserialize(this._PersonUpdateRequestValue)
        console.assert((deserialized.size > 0), 'trade.PersonUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonUpdateRequestValue)
        this.onReceive_PersonUpdateRequest(this._PersonUpdateRequestValue)
        return true
      }
      case PersonUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdateResponseModel.verify(), 'trade.PersonUpdateResponse validation failed!')
        let deserialized = this._PersonUpdateResponseModel.deserialize(this._PersonUpdateResponseValue)
        console.assert((deserialized.size > 0), 'trade.PersonUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonUpdateResponseValue)
        this.onReceive_PersonUpdateResponse(this._PersonUpdateResponseValue)
        return true
      }
      case TradeHistoryByIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryByIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryByIdRequestModel.verify(), 'trade.TradeHistoryByIdRequest validation failed!')
        let deserialized = this._TradeHistoryByIdRequestModel.deserialize(this._TradeHistoryByIdRequestValue)
        console.assert((deserialized.size > 0), 'trade.TradeHistoryByIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryByIdRequestValue)
        this.onReceive_TradeHistoryByIdRequest(this._TradeHistoryByIdRequestValue)
        return true
      }
      case TradeHistoryByIdResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryByIdResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryByIdResponseModel.verify(), 'trade.TradeHistoryByIdResponse validation failed!')
        let deserialized = this._TradeHistoryByIdResponseModel.deserialize(this._TradeHistoryByIdResponseValue)
        console.assert((deserialized.size > 0), 'trade.TradeHistoryByIdResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryByIdResponseValue)
        this.onReceive_TradeHistoryByIdResponse(this._TradeHistoryByIdResponseValue)
        return true
      }
      case ProfitClosedGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ProfitClosedGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedGetRequestModel.verify(), 'trade.ProfitClosedGetRequest validation failed!')
        let deserialized = this._ProfitClosedGetRequestModel.deserialize(this._ProfitClosedGetRequestValue)
        console.assert((deserialized.size > 0), 'trade.ProfitClosedGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ProfitClosedGetRequestValue)
        this.onReceive_ProfitClosedGetRequest(this._ProfitClosedGetRequestValue)
        return true
      }
      case ProfitClosedGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ProfitClosedGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedGetResponseModel.verify(), 'trade.ProfitClosedGetResponse validation failed!')
        let deserialized = this._ProfitClosedGetResponseModel.deserialize(this._ProfitClosedGetResponseValue)
        console.assert((deserialized.size > 0), 'trade.ProfitClosedGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ProfitClosedGetResponseValue)
        this.onReceive_ProfitClosedGetResponse(this._ProfitClosedGetResponseValue)
        return true
      }
      case MarginsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginsGetAllRequestModel.verify(), 'trade.MarginsGetAllRequest validation failed!')
        let deserialized = this._MarginsGetAllRequestModel.deserialize(this._MarginsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'trade.MarginsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginsGetAllRequestValue)
        this.onReceive_MarginsGetAllRequest(this._MarginsGetAllRequestValue)
        return true
      }
      case MarginsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginsGetAllResponseModel.verify(), 'trade.MarginsGetAllResponse validation failed!')
        let deserialized = this._MarginsGetAllResponseModel.deserialize(this._MarginsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'trade.MarginsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginsGetAllResponseValue)
        this.onReceive_MarginsGetAllResponse(this._MarginsGetAllResponseValue)
        return true
      }
      case OrdersGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetAllRequestModel.verify(), 'trade.OrdersGetAllRequest validation failed!')
        let deserialized = this._OrdersGetAllRequestModel.deserialize(this._OrdersGetAllRequestValue)
        console.assert((deserialized.size > 0), 'trade.OrdersGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetAllRequestValue)
        this.onReceive_OrdersGetAllRequest(this._OrdersGetAllRequestValue)
        return true
      }
      case OrdersGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetAllResponseModel.verify(), 'trade.OrdersGetAllResponse validation failed!')
        let deserialized = this._OrdersGetAllResponseModel.deserialize(this._OrdersGetAllResponseValue)
        console.assert((deserialized.size > 0), 'trade.OrdersGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetAllResponseValue)
        this.onReceive_OrdersGetAllResponse(this._OrdersGetAllResponseValue)
        return true
      }
      case AssetsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetAllRequestModel.verify(), 'trade.AssetsGetAllRequest validation failed!')
        let deserialized = this._AssetsGetAllRequestModel.deserialize(this._AssetsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'trade.AssetsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetsGetAllRequestValue)
        this.onReceive_AssetsGetAllRequest(this._AssetsGetAllRequestValue)
        return true
      }
      case AssetsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetAllResponseModel.verify(), 'trade.AssetsGetAllResponse validation failed!')
        let deserialized = this._AssetsGetAllResponseModel.deserialize(this._AssetsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'trade.AssetsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetsGetAllResponseValue)
        this.onReceive_AssetsGetAllResponse(this._AssetsGetAllResponseValue)
        return true
      }
      case PositionsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetAllRequestModel.verify(), 'trade.PositionsGetAllRequest validation failed!')
        let deserialized = this._PositionsGetAllRequestModel.deserialize(this._PositionsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'trade.PositionsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionsGetAllRequestValue)
        this.onReceive_PositionsGetAllRequest(this._PositionsGetAllRequestValue)
        return true
      }
      case PositionsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetAllResponseModel.verify(), 'trade.PositionsGetAllResponse validation failed!')
        let deserialized = this._PositionsGetAllResponseModel.deserialize(this._PositionsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'trade.PositionsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionsGetAllResponseValue)
        this.onReceive_PositionsGetAllResponse(this._PositionsGetAllResponseValue)
        return true
      }
      case PersonsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsGetAllRequestModel.verify(), 'trade.PersonsGetAllRequest validation failed!')
        let deserialized = this._PersonsGetAllRequestModel.deserialize(this._PersonsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'trade.PersonsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsGetAllRequestValue)
        this.onReceive_PersonsGetAllRequest(this._PersonsGetAllRequestValue)
        return true
      }
      case PersonsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsGetAllResponseModel.verify(), 'trade.PersonsGetAllResponse validation failed!')
        let deserialized = this._PersonsGetAllResponseModel.deserialize(this._PersonsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'trade.PersonsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsGetAllResponseValue)
        this.onReceive_PersonsGetAllResponse(this._PersonsGetAllResponseValue)
        return true
      }
      case DailySnapshotRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DailySnapshotRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DailySnapshotRequestModel.verify(), 'trade.DailySnapshotRequest validation failed!')
        let deserialized = this._DailySnapshotRequestModel.deserialize(this._DailySnapshotRequestValue)
        console.assert((deserialized.size > 0), 'trade.DailySnapshotRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DailySnapshotRequestValue)
        this.onReceive_DailySnapshotRequest(this._DailySnapshotRequestValue)
        return true
      }
      case DailySnapshotResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DailySnapshotResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DailySnapshotResponseModel.verify(), 'trade.DailySnapshotResponse validation failed!')
        let deserialized = this._DailySnapshotResponseModel.deserialize(this._DailySnapshotResponseValue)
        console.assert((deserialized.size > 0), 'trade.DailySnapshotResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DailySnapshotResponseValue)
        this.onReceive_DailySnapshotResponse(this._DailySnapshotResponseValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreReceiver != null) && this.coreReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.innerReceiver != null) && this.innerReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Receiver}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreReceiver.onReceiveLogHandler = handler
    this._innerReceiver.onReceiveLogHandler = handler
  }
}

export { Receiver };

/**
 * Fast Binary Encoding trade proxy
 */
class Proxy extends fbe.Receiver {
  /**
   * Initialize trade proxy with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreProxy = new core.Proxy(this.buffer)
    this._innerProxy = new inner.Proxy(this.buffer)
    this._AccountsGetAllRequestModel = new AccountsGetAllRequestModel()
    this._AccountsGetAllResponseModel = new AccountsGetAllResponseModel()
    this._TradeEventsGetRequestModel = new TradeEventsGetRequestModel()
    this._TradeEventsGetResponseModel = new TradeEventsGetResponseModel()
    this._ServiceStatusUpdateNotifyModel = new ServiceStatusUpdateNotifyModel()
    this._ServiceStatusGetRequestModel = new ServiceStatusGetRequestModel()
    this._ServiceStatusGetResponseModel = new ServiceStatusGetResponseModel()
    this._TradeHistoryRequestModel = new TradeHistoryRequestModel()
    this._TradeHistoryResponseModel = new TradeHistoryResponseModel()
    this._PersonsInsertRequestModel = new PersonsInsertRequestModel()
    this._PersonsInsertResponseModel = new PersonsInsertResponseModel()
    this._PersonUpdateRequestModel = new PersonUpdateRequestModel()
    this._PersonUpdateResponseModel = new PersonUpdateResponseModel()
    this._TradeHistoryByIdRequestModel = new TradeHistoryByIdRequestModel()
    this._TradeHistoryByIdResponseModel = new TradeHistoryByIdResponseModel()
    this._ProfitClosedGetRequestModel = new ProfitClosedGetRequestModel()
    this._ProfitClosedGetResponseModel = new ProfitClosedGetResponseModel()
    this._MarginsGetAllRequestModel = new MarginsGetAllRequestModel()
    this._MarginsGetAllResponseModel = new MarginsGetAllResponseModel()
    this._OrdersGetAllRequestModel = new OrdersGetAllRequestModel()
    this._OrdersGetAllResponseModel = new OrdersGetAllResponseModel()
    this._AssetsGetAllRequestModel = new AssetsGetAllRequestModel()
    this._AssetsGetAllResponseModel = new AssetsGetAllResponseModel()
    this._PositionsGetAllRequestModel = new PositionsGetAllRequestModel()
    this._PositionsGetAllResponseModel = new PositionsGetAllResponseModel()
    this._PersonsGetAllRequestModel = new PersonsGetAllRequestModel()
    this._PersonsGetAllResponseModel = new PersonsGetAllResponseModel()
    this._DailySnapshotRequestModel = new DailySnapshotRequestModel()
    this._DailySnapshotResponseModel = new DailySnapshotResponseModel()
  }

  // Imported proxy

  /**
   * Get imported core proxy
   * @this {!Proxy}
   * @returns {Proxy} core proxy
   */
  get coreProxy () {
    return this._coreProxy
  }

  /**
   * Set imported core proxy
   * @this {!Proxy}
   * @param {Proxy} proxy core proxy
   */
  set coreProxy (proxy) {
    this._coreProxy = proxy
  }

  /**
   * Get imported inner proxy
   * @this {!Proxy}
   * @returns {Proxy} inner proxy
   */
  get innerProxy () {
    return this._innerProxy
  }

  /**
   * Set imported inner proxy
   * @this {!Proxy}
   * @param {Proxy} proxy inner proxy
   */
  set innerProxy (proxy) {
    this._innerProxy = proxy
  }

  // Proxy handlers

  /**
   * AccountsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountsGetAllRequest} model AccountsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountsGetAllResponse} model AccountsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeEventsGetRequest proxy handler
   * @this {!Proxy}
   * @param {!TradeEventsGetRequest} model TradeEventsGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeEventsGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeEventsGetResponse proxy handler
   * @this {!Proxy}
   * @param {!TradeEventsGetResponse} model TradeEventsGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeEventsGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ServiceStatusUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!ServiceStatusUpdateNotify} model ServiceStatusUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ServiceStatusUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ServiceStatusGetRequest proxy handler
   * @this {!Proxy}
   * @param {!ServiceStatusGetRequest} model ServiceStatusGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ServiceStatusGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ServiceStatusGetResponse proxy handler
   * @this {!Proxy}
   * @param {!ServiceStatusGetResponse} model ServiceStatusGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ServiceStatusGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryRequest proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryRequest} model TradeHistoryRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryResponse proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryResponse} model TradeHistoryResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonsInsertRequest proxy handler
   * @this {!Proxy}
   * @param {!PersonsInsertRequest} model PersonsInsertRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonsInsertRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonsInsertResponse proxy handler
   * @this {!Proxy}
   * @param {!PersonsInsertResponse} model PersonsInsertResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonsInsertResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonUpdateRequest proxy handler
   * @this {!Proxy}
   * @param {!PersonUpdateRequest} model PersonUpdateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonUpdateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonUpdateResponse proxy handler
   * @this {!Proxy}
   * @param {!PersonUpdateResponse} model PersonUpdateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonUpdateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryByIdRequest proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryByIdRequest} model TradeHistoryByIdRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryByIdRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryByIdResponse proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryByIdResponse} model TradeHistoryByIdResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryByIdResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ProfitClosedGetRequest proxy handler
   * @this {!Proxy}
   * @param {!ProfitClosedGetRequest} model ProfitClosedGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ProfitClosedGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ProfitClosedGetResponse proxy handler
   * @this {!Proxy}
   * @param {!ProfitClosedGetResponse} model ProfitClosedGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ProfitClosedGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!MarginsGetAllRequest} model MarginsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!MarginsGetAllResponse} model MarginsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrdersGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!OrdersGetAllRequest} model OrdersGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrdersGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrdersGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!OrdersGetAllResponse} model OrdersGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrdersGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!AssetsGetAllRequest} model AssetsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!AssetsGetAllResponse} model AssetsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!PositionsGetAllRequest} model PositionsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!PositionsGetAllResponse} model PositionsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!PersonsGetAllRequest} model PersonsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!PersonsGetAllResponse} model PersonsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DailySnapshotRequest proxy handler
   * @this {!Proxy}
   * @param {!DailySnapshotRequest} model DailySnapshotRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DailySnapshotRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DailySnapshotResponse proxy handler
   * @this {!Proxy}
   * @param {!DailySnapshotResponse} model DailySnapshotResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DailySnapshotResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * trade receive message handler
   * @this {!Proxy}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case AccountsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetAllRequestModel.verify(), 'trade.AccountsGetAllRequest validation failed!')

        let fbeBegin = this._AccountsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountsGetAllRequest(this._AccountsGetAllRequestModel, type, buffer, offset, size)
        this._AccountsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetAllResponseModel.verify(), 'trade.AccountsGetAllResponse validation failed!')

        let fbeBegin = this._AccountsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountsGetAllResponse(this._AccountsGetAllResponseModel, type, buffer, offset, size)
        this._AccountsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeEventsGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeEventsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsGetRequestModel.verify(), 'trade.TradeEventsGetRequest validation failed!')

        let fbeBegin = this._TradeEventsGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeEventsGetRequest(this._TradeEventsGetRequestModel, type, buffer, offset, size)
        this._TradeEventsGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeEventsGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeEventsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsGetResponseModel.verify(), 'trade.TradeEventsGetResponse validation failed!')

        let fbeBegin = this._TradeEventsGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeEventsGetResponse(this._TradeEventsGetResponseModel, type, buffer, offset, size)
        this._TradeEventsGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ServiceStatusUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ServiceStatusUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ServiceStatusUpdateNotifyModel.verify(), 'trade.ServiceStatusUpdateNotify validation failed!')

        let fbeBegin = this._ServiceStatusUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ServiceStatusUpdateNotify(this._ServiceStatusUpdateNotifyModel, type, buffer, offset, size)
        this._ServiceStatusUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case ServiceStatusGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ServiceStatusGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ServiceStatusGetRequestModel.verify(), 'trade.ServiceStatusGetRequest validation failed!')

        let fbeBegin = this._ServiceStatusGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ServiceStatusGetRequest(this._ServiceStatusGetRequestModel, type, buffer, offset, size)
        this._ServiceStatusGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ServiceStatusGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ServiceStatusGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ServiceStatusGetResponseModel.verify(), 'trade.ServiceStatusGetResponse validation failed!')

        let fbeBegin = this._ServiceStatusGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ServiceStatusGetResponse(this._ServiceStatusGetResponseModel, type, buffer, offset, size)
        this._ServiceStatusGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryRequestModel.verify(), 'trade.TradeHistoryRequest validation failed!')

        let fbeBegin = this._TradeHistoryRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryRequest(this._TradeHistoryRequestModel, type, buffer, offset, size)
        this._TradeHistoryRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryResponseModel.verify(), 'trade.TradeHistoryResponse validation failed!')

        let fbeBegin = this._TradeHistoryResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryResponse(this._TradeHistoryResponseModel, type, buffer, offset, size)
        this._TradeHistoryResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonsInsertRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonsInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsInsertRequestModel.verify(), 'trade.PersonsInsertRequest validation failed!')

        let fbeBegin = this._PersonsInsertRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonsInsertRequest(this._PersonsInsertRequestModel, type, buffer, offset, size)
        this._PersonsInsertRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonsInsertResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonsInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsInsertResponseModel.verify(), 'trade.PersonsInsertResponse validation failed!')

        let fbeBegin = this._PersonsInsertResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonsInsertResponse(this._PersonsInsertResponseModel, type, buffer, offset, size)
        this._PersonsInsertResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonUpdateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdateRequestModel.verify(), 'trade.PersonUpdateRequest validation failed!')

        let fbeBegin = this._PersonUpdateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonUpdateRequest(this._PersonUpdateRequestModel, type, buffer, offset, size)
        this._PersonUpdateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonUpdateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdateResponseModel.verify(), 'trade.PersonUpdateResponse validation failed!')

        let fbeBegin = this._PersonUpdateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonUpdateResponse(this._PersonUpdateResponseModel, type, buffer, offset, size)
        this._PersonUpdateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryByIdRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryByIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryByIdRequestModel.verify(), 'trade.TradeHistoryByIdRequest validation failed!')

        let fbeBegin = this._TradeHistoryByIdRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryByIdRequest(this._TradeHistoryByIdRequestModel, type, buffer, offset, size)
        this._TradeHistoryByIdRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryByIdResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryByIdResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryByIdResponseModel.verify(), 'trade.TradeHistoryByIdResponse validation failed!')

        let fbeBegin = this._TradeHistoryByIdResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryByIdResponse(this._TradeHistoryByIdResponseModel, type, buffer, offset, size)
        this._TradeHistoryByIdResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ProfitClosedGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ProfitClosedGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedGetRequestModel.verify(), 'trade.ProfitClosedGetRequest validation failed!')

        let fbeBegin = this._ProfitClosedGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ProfitClosedGetRequest(this._ProfitClosedGetRequestModel, type, buffer, offset, size)
        this._ProfitClosedGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ProfitClosedGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ProfitClosedGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedGetResponseModel.verify(), 'trade.ProfitClosedGetResponse validation failed!')

        let fbeBegin = this._ProfitClosedGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ProfitClosedGetResponse(this._ProfitClosedGetResponseModel, type, buffer, offset, size)
        this._ProfitClosedGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginsGetAllRequestModel.verify(), 'trade.MarginsGetAllRequest validation failed!')

        let fbeBegin = this._MarginsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginsGetAllRequest(this._MarginsGetAllRequestModel, type, buffer, offset, size)
        this._MarginsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginsGetAllResponseModel.verify(), 'trade.MarginsGetAllResponse validation failed!')

        let fbeBegin = this._MarginsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginsGetAllResponse(this._MarginsGetAllResponseModel, type, buffer, offset, size)
        this._MarginsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrdersGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrdersGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetAllRequestModel.verify(), 'trade.OrdersGetAllRequest validation failed!')

        let fbeBegin = this._OrdersGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrdersGetAllRequest(this._OrdersGetAllRequestModel, type, buffer, offset, size)
        this._OrdersGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrdersGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrdersGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetAllResponseModel.verify(), 'trade.OrdersGetAllResponse validation failed!')

        let fbeBegin = this._OrdersGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrdersGetAllResponse(this._OrdersGetAllResponseModel, type, buffer, offset, size)
        this._OrdersGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetAllRequestModel.verify(), 'trade.AssetsGetAllRequest validation failed!')

        let fbeBegin = this._AssetsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetsGetAllRequest(this._AssetsGetAllRequestModel, type, buffer, offset, size)
        this._AssetsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetAllResponseModel.verify(), 'trade.AssetsGetAllResponse validation failed!')

        let fbeBegin = this._AssetsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetsGetAllResponse(this._AssetsGetAllResponseModel, type, buffer, offset, size)
        this._AssetsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetAllRequestModel.verify(), 'trade.PositionsGetAllRequest validation failed!')

        let fbeBegin = this._PositionsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionsGetAllRequest(this._PositionsGetAllRequestModel, type, buffer, offset, size)
        this._PositionsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetAllResponseModel.verify(), 'trade.PositionsGetAllResponse validation failed!')

        let fbeBegin = this._PositionsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionsGetAllResponse(this._PositionsGetAllResponseModel, type, buffer, offset, size)
        this._PositionsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsGetAllRequestModel.verify(), 'trade.PersonsGetAllRequest validation failed!')

        let fbeBegin = this._PersonsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonsGetAllRequest(this._PersonsGetAllRequestModel, type, buffer, offset, size)
        this._PersonsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsGetAllResponseModel.verify(), 'trade.PersonsGetAllResponse validation failed!')

        let fbeBegin = this._PersonsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonsGetAllResponse(this._PersonsGetAllResponseModel, type, buffer, offset, size)
        this._PersonsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DailySnapshotRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DailySnapshotRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DailySnapshotRequestModel.verify(), 'trade.DailySnapshotRequest validation failed!')

        let fbeBegin = this._DailySnapshotRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DailySnapshotRequest(this._DailySnapshotRequestModel, type, buffer, offset, size)
        this._DailySnapshotRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DailySnapshotResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DailySnapshotResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DailySnapshotResponseModel.verify(), 'trade.DailySnapshotResponse validation failed!')

        let fbeBegin = this._DailySnapshotResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DailySnapshotResponse(this._DailySnapshotResponseModel, type, buffer, offset, size)
        this._DailySnapshotResponseModel.model.getEnd(fbeBegin)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreProxy != null) && this.coreProxy.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.innerProxy != null) && this.innerProxy.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }
}

export { Proxy };

/**
 * Fast Binary Encoding trade client
 */
class Client extends fbe.Client {
  /**
   * Initialize trade client with the given buffers
   * @param {!fbe.WriteBuffer} sendBuffer Send buffer, defaults is new fbe.WriteBuffer()
   * @param {!fbe.WriteBuffer} receiveBuffer Receive buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (sendBuffer = new fbe.WriteBuffer(), receiveBuffer = new fbe.WriteBuffer()) {
    super(sendBuffer, receiveBuffer, false)
    this._coreClient = new core.Client(this.sendBuffer, this.receiveBuffer)
    this._innerClient = new inner.Client(this.sendBuffer, this.receiveBuffer)
    this._AccountsGetAllRequestSenderModel = new AccountsGetAllRequestModel(this.sendBuffer)
    this._AccountsGetAllRequestReceiverValue = new AccountsGetAllRequest()
    this._AccountsGetAllRequestReceiverModel = new AccountsGetAllRequestModel()
    this._AccountsGetAllResponseSenderModel = new AccountsGetAllResponseModel(this.sendBuffer)
    this._AccountsGetAllResponseReceiverValue = new AccountsGetAllResponse()
    this._AccountsGetAllResponseReceiverModel = new AccountsGetAllResponseModel()
    this._TradeEventsGetRequestSenderModel = new TradeEventsGetRequestModel(this.sendBuffer)
    this._TradeEventsGetRequestReceiverValue = new TradeEventsGetRequest()
    this._TradeEventsGetRequestReceiverModel = new TradeEventsGetRequestModel()
    this._TradeEventsGetResponseSenderModel = new TradeEventsGetResponseModel(this.sendBuffer)
    this._TradeEventsGetResponseReceiverValue = new TradeEventsGetResponse()
    this._TradeEventsGetResponseReceiverModel = new TradeEventsGetResponseModel()
    this._ServiceStatusUpdateNotifySenderModel = new ServiceStatusUpdateNotifyModel(this.sendBuffer)
    this._ServiceStatusUpdateNotifyReceiverValue = new ServiceStatusUpdateNotify()
    this._ServiceStatusUpdateNotifyReceiverModel = new ServiceStatusUpdateNotifyModel()
    this._ServiceStatusGetRequestSenderModel = new ServiceStatusGetRequestModel(this.sendBuffer)
    this._ServiceStatusGetRequestReceiverValue = new ServiceStatusGetRequest()
    this._ServiceStatusGetRequestReceiverModel = new ServiceStatusGetRequestModel()
    this._ServiceStatusGetResponseSenderModel = new ServiceStatusGetResponseModel(this.sendBuffer)
    this._ServiceStatusGetResponseReceiverValue = new ServiceStatusGetResponse()
    this._ServiceStatusGetResponseReceiverModel = new ServiceStatusGetResponseModel()
    this._TradeHistoryRequestSenderModel = new TradeHistoryRequestModel(this.sendBuffer)
    this._TradeHistoryRequestReceiverValue = new TradeHistoryRequest()
    this._TradeHistoryRequestReceiverModel = new TradeHistoryRequestModel()
    this._TradeHistoryResponseSenderModel = new TradeHistoryResponseModel(this.sendBuffer)
    this._TradeHistoryResponseReceiverValue = new TradeHistoryResponse()
    this._TradeHistoryResponseReceiverModel = new TradeHistoryResponseModel()
    this._PersonsInsertRequestSenderModel = new PersonsInsertRequestModel(this.sendBuffer)
    this._PersonsInsertRequestReceiverValue = new PersonsInsertRequest()
    this._PersonsInsertRequestReceiverModel = new PersonsInsertRequestModel()
    this._PersonsInsertResponseSenderModel = new PersonsInsertResponseModel(this.sendBuffer)
    this._PersonsInsertResponseReceiverValue = new PersonsInsertResponse()
    this._PersonsInsertResponseReceiverModel = new PersonsInsertResponseModel()
    this._PersonUpdateRequestSenderModel = new PersonUpdateRequestModel(this.sendBuffer)
    this._PersonUpdateRequestReceiverValue = new PersonUpdateRequest()
    this._PersonUpdateRequestReceiverModel = new PersonUpdateRequestModel()
    this._PersonUpdateResponseSenderModel = new PersonUpdateResponseModel(this.sendBuffer)
    this._PersonUpdateResponseReceiverValue = new PersonUpdateResponse()
    this._PersonUpdateResponseReceiverModel = new PersonUpdateResponseModel()
    this._TradeHistoryByIdRequestSenderModel = new TradeHistoryByIdRequestModel(this.sendBuffer)
    this._TradeHistoryByIdRequestReceiverValue = new TradeHistoryByIdRequest()
    this._TradeHistoryByIdRequestReceiverModel = new TradeHistoryByIdRequestModel()
    this._TradeHistoryByIdResponseSenderModel = new TradeHistoryByIdResponseModel(this.sendBuffer)
    this._TradeHistoryByIdResponseReceiverValue = new TradeHistoryByIdResponse()
    this._TradeHistoryByIdResponseReceiverModel = new TradeHistoryByIdResponseModel()
    this._ProfitClosedGetRequestSenderModel = new ProfitClosedGetRequestModel(this.sendBuffer)
    this._ProfitClosedGetRequestReceiverValue = new ProfitClosedGetRequest()
    this._ProfitClosedGetRequestReceiverModel = new ProfitClosedGetRequestModel()
    this._ProfitClosedGetResponseSenderModel = new ProfitClosedGetResponseModel(this.sendBuffer)
    this._ProfitClosedGetResponseReceiverValue = new ProfitClosedGetResponse()
    this._ProfitClosedGetResponseReceiverModel = new ProfitClosedGetResponseModel()
    this._MarginsGetAllRequestSenderModel = new MarginsGetAllRequestModel(this.sendBuffer)
    this._MarginsGetAllRequestReceiverValue = new MarginsGetAllRequest()
    this._MarginsGetAllRequestReceiverModel = new MarginsGetAllRequestModel()
    this._MarginsGetAllResponseSenderModel = new MarginsGetAllResponseModel(this.sendBuffer)
    this._MarginsGetAllResponseReceiverValue = new MarginsGetAllResponse()
    this._MarginsGetAllResponseReceiverModel = new MarginsGetAllResponseModel()
    this._OrdersGetAllRequestSenderModel = new OrdersGetAllRequestModel(this.sendBuffer)
    this._OrdersGetAllRequestReceiverValue = new OrdersGetAllRequest()
    this._OrdersGetAllRequestReceiverModel = new OrdersGetAllRequestModel()
    this._OrdersGetAllResponseSenderModel = new OrdersGetAllResponseModel(this.sendBuffer)
    this._OrdersGetAllResponseReceiverValue = new OrdersGetAllResponse()
    this._OrdersGetAllResponseReceiverModel = new OrdersGetAllResponseModel()
    this._AssetsGetAllRequestSenderModel = new AssetsGetAllRequestModel(this.sendBuffer)
    this._AssetsGetAllRequestReceiverValue = new AssetsGetAllRequest()
    this._AssetsGetAllRequestReceiverModel = new AssetsGetAllRequestModel()
    this._AssetsGetAllResponseSenderModel = new AssetsGetAllResponseModel(this.sendBuffer)
    this._AssetsGetAllResponseReceiverValue = new AssetsGetAllResponse()
    this._AssetsGetAllResponseReceiverModel = new AssetsGetAllResponseModel()
    this._PositionsGetAllRequestSenderModel = new PositionsGetAllRequestModel(this.sendBuffer)
    this._PositionsGetAllRequestReceiverValue = new PositionsGetAllRequest()
    this._PositionsGetAllRequestReceiverModel = new PositionsGetAllRequestModel()
    this._PositionsGetAllResponseSenderModel = new PositionsGetAllResponseModel(this.sendBuffer)
    this._PositionsGetAllResponseReceiverValue = new PositionsGetAllResponse()
    this._PositionsGetAllResponseReceiverModel = new PositionsGetAllResponseModel()
    this._PersonsGetAllRequestSenderModel = new PersonsGetAllRequestModel(this.sendBuffer)
    this._PersonsGetAllRequestReceiverValue = new PersonsGetAllRequest()
    this._PersonsGetAllRequestReceiverModel = new PersonsGetAllRequestModel()
    this._PersonsGetAllResponseSenderModel = new PersonsGetAllResponseModel(this.sendBuffer)
    this._PersonsGetAllResponseReceiverValue = new PersonsGetAllResponse()
    this._PersonsGetAllResponseReceiverModel = new PersonsGetAllResponseModel()
    this._DailySnapshotRequestSenderModel = new DailySnapshotRequestModel(this.sendBuffer)
    this._DailySnapshotRequestReceiverValue = new DailySnapshotRequest()
    this._DailySnapshotRequestReceiverModel = new DailySnapshotRequestModel()
    this._DailySnapshotResponseSenderModel = new DailySnapshotResponseModel(this.sendBuffer)
    this._DailySnapshotResponseReceiverValue = new DailySnapshotResponse()
    this._DailySnapshotResponseReceiverModel = new DailySnapshotResponseModel()
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
    this.onReceiveLogHandler = this.onReceiveLog
    this._timestamp = 0
    this._requests_by_id = new Map()
    this._requests_by_timestamp = new Map()
  }

  // Imported clients

  /**
   * Get imported core client
   * @this {!Client}
   * @returns {!core.Client} core client
   */
  get coreClient () {
    return this._coreClient
  }

  /**
   * Get imported inner client
   * @this {!Client}
   * @returns {!inner.Client} inner client
   */
  get innerClient () {
    return this._innerClient
  }

  // Sender models accessors

  /**
   * Get AccountsGetAllRequest model
   * @this {!Client}
   * @returns {!AccountsGetAllRequestModel} AccountsGetAllRequest sender model
   */
  get AccountsGetAllRequestSenderModel () {
    return this._AccountsGetAllRequestSenderModel
  }

  /**
   * Get AccountsGetAllResponse model
   * @this {!Client}
   * @returns {!AccountsGetAllResponseModel} AccountsGetAllResponse sender model
   */
  get AccountsGetAllResponseSenderModel () {
    return this._AccountsGetAllResponseSenderModel
  }

  /**
   * Get TradeEventsGetRequest model
   * @this {!Client}
   * @returns {!TradeEventsGetRequestModel} TradeEventsGetRequest sender model
   */
  get TradeEventsGetRequestSenderModel () {
    return this._TradeEventsGetRequestSenderModel
  }

  /**
   * Get TradeEventsGetResponse model
   * @this {!Client}
   * @returns {!TradeEventsGetResponseModel} TradeEventsGetResponse sender model
   */
  get TradeEventsGetResponseSenderModel () {
    return this._TradeEventsGetResponseSenderModel
  }

  /**
   * Get ServiceStatusUpdateNotify model
   * @this {!Client}
   * @returns {!ServiceStatusUpdateNotifyModel} ServiceStatusUpdateNotify sender model
   */
  get ServiceStatusUpdateNotifySenderModel () {
    return this._ServiceStatusUpdateNotifySenderModel
  }

  /**
   * Get ServiceStatusGetRequest model
   * @this {!Client}
   * @returns {!ServiceStatusGetRequestModel} ServiceStatusGetRequest sender model
   */
  get ServiceStatusGetRequestSenderModel () {
    return this._ServiceStatusGetRequestSenderModel
  }

  /**
   * Get ServiceStatusGetResponse model
   * @this {!Client}
   * @returns {!ServiceStatusGetResponseModel} ServiceStatusGetResponse sender model
   */
  get ServiceStatusGetResponseSenderModel () {
    return this._ServiceStatusGetResponseSenderModel
  }

  /**
   * Get TradeHistoryRequest model
   * @this {!Client}
   * @returns {!TradeHistoryRequestModel} TradeHistoryRequest sender model
   */
  get TradeHistoryRequestSenderModel () {
    return this._TradeHistoryRequestSenderModel
  }

  /**
   * Get TradeHistoryResponse model
   * @this {!Client}
   * @returns {!TradeHistoryResponseModel} TradeHistoryResponse sender model
   */
  get TradeHistoryResponseSenderModel () {
    return this._TradeHistoryResponseSenderModel
  }

  /**
   * Get PersonsInsertRequest model
   * @this {!Client}
   * @returns {!PersonsInsertRequestModel} PersonsInsertRequest sender model
   */
  get PersonsInsertRequestSenderModel () {
    return this._PersonsInsertRequestSenderModel
  }

  /**
   * Get PersonsInsertResponse model
   * @this {!Client}
   * @returns {!PersonsInsertResponseModel} PersonsInsertResponse sender model
   */
  get PersonsInsertResponseSenderModel () {
    return this._PersonsInsertResponseSenderModel
  }

  /**
   * Get PersonUpdateRequest model
   * @this {!Client}
   * @returns {!PersonUpdateRequestModel} PersonUpdateRequest sender model
   */
  get PersonUpdateRequestSenderModel () {
    return this._PersonUpdateRequestSenderModel
  }

  /**
   * Get PersonUpdateResponse model
   * @this {!Client}
   * @returns {!PersonUpdateResponseModel} PersonUpdateResponse sender model
   */
  get PersonUpdateResponseSenderModel () {
    return this._PersonUpdateResponseSenderModel
  }

  /**
   * Get TradeHistoryByIdRequest model
   * @this {!Client}
   * @returns {!TradeHistoryByIdRequestModel} TradeHistoryByIdRequest sender model
   */
  get TradeHistoryByIdRequestSenderModel () {
    return this._TradeHistoryByIdRequestSenderModel
  }

  /**
   * Get TradeHistoryByIdResponse model
   * @this {!Client}
   * @returns {!TradeHistoryByIdResponseModel} TradeHistoryByIdResponse sender model
   */
  get TradeHistoryByIdResponseSenderModel () {
    return this._TradeHistoryByIdResponseSenderModel
  }

  /**
   * Get ProfitClosedGetRequest model
   * @this {!Client}
   * @returns {!ProfitClosedGetRequestModel} ProfitClosedGetRequest sender model
   */
  get ProfitClosedGetRequestSenderModel () {
    return this._ProfitClosedGetRequestSenderModel
  }

  /**
   * Get ProfitClosedGetResponse model
   * @this {!Client}
   * @returns {!ProfitClosedGetResponseModel} ProfitClosedGetResponse sender model
   */
  get ProfitClosedGetResponseSenderModel () {
    return this._ProfitClosedGetResponseSenderModel
  }

  /**
   * Get MarginsGetAllRequest model
   * @this {!Client}
   * @returns {!MarginsGetAllRequestModel} MarginsGetAllRequest sender model
   */
  get MarginsGetAllRequestSenderModel () {
    return this._MarginsGetAllRequestSenderModel
  }

  /**
   * Get MarginsGetAllResponse model
   * @this {!Client}
   * @returns {!MarginsGetAllResponseModel} MarginsGetAllResponse sender model
   */
  get MarginsGetAllResponseSenderModel () {
    return this._MarginsGetAllResponseSenderModel
  }

  /**
   * Get OrdersGetAllRequest model
   * @this {!Client}
   * @returns {!OrdersGetAllRequestModel} OrdersGetAllRequest sender model
   */
  get OrdersGetAllRequestSenderModel () {
    return this._OrdersGetAllRequestSenderModel
  }

  /**
   * Get OrdersGetAllResponse model
   * @this {!Client}
   * @returns {!OrdersGetAllResponseModel} OrdersGetAllResponse sender model
   */
  get OrdersGetAllResponseSenderModel () {
    return this._OrdersGetAllResponseSenderModel
  }

  /**
   * Get AssetsGetAllRequest model
   * @this {!Client}
   * @returns {!AssetsGetAllRequestModel} AssetsGetAllRequest sender model
   */
  get AssetsGetAllRequestSenderModel () {
    return this._AssetsGetAllRequestSenderModel
  }

  /**
   * Get AssetsGetAllResponse model
   * @this {!Client}
   * @returns {!AssetsGetAllResponseModel} AssetsGetAllResponse sender model
   */
  get AssetsGetAllResponseSenderModel () {
    return this._AssetsGetAllResponseSenderModel
  }

  /**
   * Get PositionsGetAllRequest model
   * @this {!Client}
   * @returns {!PositionsGetAllRequestModel} PositionsGetAllRequest sender model
   */
  get PositionsGetAllRequestSenderModel () {
    return this._PositionsGetAllRequestSenderModel
  }

  /**
   * Get PositionsGetAllResponse model
   * @this {!Client}
   * @returns {!PositionsGetAllResponseModel} PositionsGetAllResponse sender model
   */
  get PositionsGetAllResponseSenderModel () {
    return this._PositionsGetAllResponseSenderModel
  }

  /**
   * Get PersonsGetAllRequest model
   * @this {!Client}
   * @returns {!PersonsGetAllRequestModel} PersonsGetAllRequest sender model
   */
  get PersonsGetAllRequestSenderModel () {
    return this._PersonsGetAllRequestSenderModel
  }

  /**
   * Get PersonsGetAllResponse model
   * @this {!Client}
   * @returns {!PersonsGetAllResponseModel} PersonsGetAllResponse sender model
   */
  get PersonsGetAllResponseSenderModel () {
    return this._PersonsGetAllResponseSenderModel
  }

  /**
   * Get DailySnapshotRequest model
   * @this {!Client}
   * @returns {!DailySnapshotRequestModel} DailySnapshotRequest sender model
   */
  get DailySnapshotRequestSenderModel () {
    return this._DailySnapshotRequestSenderModel
  }

  /**
   * Get DailySnapshotResponse model
   * @this {!Client}
   * @returns {!DailySnapshotResponseModel} DailySnapshotResponse sender model
   */
  get DailySnapshotResponseSenderModel () {
    return this._DailySnapshotResponseSenderModel
  }

  // Reset and watchdog methods

  /**
   * Reset the client
   * @this {!Client}
   */
  reset () {
    super.reset()
    this.resetRequests()
  }

  /**
   * Watchdog for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp
   */
  watchdog (utc) {
    this.watchdogRequests(utc)
  }

  // Send methods

  /**
   * Send value
   * @this {!Client}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof AccountsGetAllRequest) && (value.fbeType === this.AccountsGetAllRequestSenderModel.fbeType)) {
      return this.send_AccountsGetAllRequest(value)
    }
    if ((value instanceof AccountsGetAllResponse) && (value.fbeType === this.AccountsGetAllResponseSenderModel.fbeType)) {
      return this.send_AccountsGetAllResponse(value)
    }
    if ((value instanceof TradeEventsGetRequest) && (value.fbeType === this.TradeEventsGetRequestSenderModel.fbeType)) {
      return this.send_TradeEventsGetRequest(value)
    }
    if ((value instanceof TradeEventsGetResponse) && (value.fbeType === this.TradeEventsGetResponseSenderModel.fbeType)) {
      return this.send_TradeEventsGetResponse(value)
    }
    if ((value instanceof ServiceStatusUpdateNotify) && (value.fbeType === this.ServiceStatusUpdateNotifySenderModel.fbeType)) {
      return this.send_ServiceStatusUpdateNotify(value)
    }
    if ((value instanceof ServiceStatusGetRequest) && (value.fbeType === this.ServiceStatusGetRequestSenderModel.fbeType)) {
      return this.send_ServiceStatusGetRequest(value)
    }
    if ((value instanceof ServiceStatusGetResponse) && (value.fbeType === this.ServiceStatusGetResponseSenderModel.fbeType)) {
      return this.send_ServiceStatusGetResponse(value)
    }
    if ((value instanceof TradeHistoryRequest) && (value.fbeType === this.TradeHistoryRequestSenderModel.fbeType)) {
      return this.send_TradeHistoryRequest(value)
    }
    if ((value instanceof TradeHistoryResponse) && (value.fbeType === this.TradeHistoryResponseSenderModel.fbeType)) {
      return this.send_TradeHistoryResponse(value)
    }
    if ((value instanceof PersonsInsertRequest) && (value.fbeType === this.PersonsInsertRequestSenderModel.fbeType)) {
      return this.send_PersonsInsertRequest(value)
    }
    if ((value instanceof PersonsInsertResponse) && (value.fbeType === this.PersonsInsertResponseSenderModel.fbeType)) {
      return this.send_PersonsInsertResponse(value)
    }
    if ((value instanceof PersonUpdateRequest) && (value.fbeType === this.PersonUpdateRequestSenderModel.fbeType)) {
      return this.send_PersonUpdateRequest(value)
    }
    if ((value instanceof PersonUpdateResponse) && (value.fbeType === this.PersonUpdateResponseSenderModel.fbeType)) {
      return this.send_PersonUpdateResponse(value)
    }
    if ((value instanceof TradeHistoryByIdRequest) && (value.fbeType === this.TradeHistoryByIdRequestSenderModel.fbeType)) {
      return this.send_TradeHistoryByIdRequest(value)
    }
    if ((value instanceof TradeHistoryByIdResponse) && (value.fbeType === this.TradeHistoryByIdResponseSenderModel.fbeType)) {
      return this.send_TradeHistoryByIdResponse(value)
    }
    if ((value instanceof ProfitClosedGetRequest) && (value.fbeType === this.ProfitClosedGetRequestSenderModel.fbeType)) {
      return this.send_ProfitClosedGetRequest(value)
    }
    if ((value instanceof ProfitClosedGetResponse) && (value.fbeType === this.ProfitClosedGetResponseSenderModel.fbeType)) {
      return this.send_ProfitClosedGetResponse(value)
    }
    if ((value instanceof MarginsGetAllRequest) && (value.fbeType === this.MarginsGetAllRequestSenderModel.fbeType)) {
      return this.send_MarginsGetAllRequest(value)
    }
    if ((value instanceof MarginsGetAllResponse) && (value.fbeType === this.MarginsGetAllResponseSenderModel.fbeType)) {
      return this.send_MarginsGetAllResponse(value)
    }
    if ((value instanceof OrdersGetAllRequest) && (value.fbeType === this.OrdersGetAllRequestSenderModel.fbeType)) {
      return this.send_OrdersGetAllRequest(value)
    }
    if ((value instanceof OrdersGetAllResponse) && (value.fbeType === this.OrdersGetAllResponseSenderModel.fbeType)) {
      return this.send_OrdersGetAllResponse(value)
    }
    if ((value instanceof AssetsGetAllRequest) && (value.fbeType === this.AssetsGetAllRequestSenderModel.fbeType)) {
      return this.send_AssetsGetAllRequest(value)
    }
    if ((value instanceof AssetsGetAllResponse) && (value.fbeType === this.AssetsGetAllResponseSenderModel.fbeType)) {
      return this.send_AssetsGetAllResponse(value)
    }
    if ((value instanceof PositionsGetAllRequest) && (value.fbeType === this.PositionsGetAllRequestSenderModel.fbeType)) {
      return this.send_PositionsGetAllRequest(value)
    }
    if ((value instanceof PositionsGetAllResponse) && (value.fbeType === this.PositionsGetAllResponseSenderModel.fbeType)) {
      return this.send_PositionsGetAllResponse(value)
    }
    if ((value instanceof PersonsGetAllRequest) && (value.fbeType === this.PersonsGetAllRequestSenderModel.fbeType)) {
      return this.send_PersonsGetAllRequest(value)
    }
    if ((value instanceof PersonsGetAllResponse) && (value.fbeType === this.PersonsGetAllResponseSenderModel.fbeType)) {
      return this.send_PersonsGetAllResponse(value)
    }
    if ((value instanceof DailySnapshotRequest) && (value.fbeType === this.DailySnapshotRequestSenderModel.fbeType)) {
      return this.send_DailySnapshotRequest(value)
    }
    if ((value instanceof DailySnapshotResponse) && (value.fbeType === this.DailySnapshotResponseSenderModel.fbeType)) {
      return this.send_DailySnapshotResponse(value)
    }
    let result = 0
    result = this._coreClient.send(value)
    if (result > 0) {
      return result
    }
    result = this._innerClient.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send AccountsGetAllRequest value
   * @this {!Client}
   * @param {!AccountsGetAllRequest} value AccountsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.AccountsGetAllRequest serialization failed!')
    console.assert(this.AccountsGetAllRequestSenderModel.verify(), 'trade.AccountsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountsGetAllResponse value
   * @this {!Client}
   * @param {!AccountsGetAllResponse} value AccountsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.AccountsGetAllResponse serialization failed!')
    console.assert(this.AccountsGetAllResponseSenderModel.verify(), 'trade.AccountsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsGetRequest value
   * @this {!Client}
   * @param {!TradeEventsGetRequest} value TradeEventsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.TradeEventsGetRequest serialization failed!')
    console.assert(this.TradeEventsGetRequestSenderModel.verify(), 'trade.TradeEventsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsGetResponse value
   * @this {!Client}
   * @param {!TradeEventsGetResponse} value TradeEventsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.TradeEventsGetResponse serialization failed!')
    console.assert(this.TradeEventsGetResponseSenderModel.verify(), 'trade.TradeEventsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ServiceStatusUpdateNotify value
   * @this {!Client}
   * @param {!ServiceStatusUpdateNotify} value ServiceStatusUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ServiceStatusUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ServiceStatusUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.ServiceStatusUpdateNotify serialization failed!')
    console.assert(this.ServiceStatusUpdateNotifySenderModel.verify(), 'trade.ServiceStatusUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ServiceStatusGetRequest value
   * @this {!Client}
   * @param {!ServiceStatusGetRequest} value ServiceStatusGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ServiceStatusGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ServiceStatusGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.ServiceStatusGetRequest serialization failed!')
    console.assert(this.ServiceStatusGetRequestSenderModel.verify(), 'trade.ServiceStatusGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ServiceStatusGetResponse value
   * @this {!Client}
   * @param {!ServiceStatusGetResponse} value ServiceStatusGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ServiceStatusGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ServiceStatusGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.ServiceStatusGetResponse serialization failed!')
    console.assert(this.ServiceStatusGetResponseSenderModel.verify(), 'trade.ServiceStatusGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryRequest value
   * @this {!Client}
   * @param {!TradeHistoryRequest} value TradeHistoryRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.TradeHistoryRequest serialization failed!')
    console.assert(this.TradeHistoryRequestSenderModel.verify(), 'trade.TradeHistoryRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryResponse value
   * @this {!Client}
   * @param {!TradeHistoryResponse} value TradeHistoryResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.TradeHistoryResponse serialization failed!')
    console.assert(this.TradeHistoryResponseSenderModel.verify(), 'trade.TradeHistoryResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsInsertRequest value
   * @this {!Client}
   * @param {!PersonsInsertRequest} value PersonsInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsInsertRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.PersonsInsertRequest serialization failed!')
    console.assert(this.PersonsInsertRequestSenderModel.verify(), 'trade.PersonsInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsInsertResponse value
   * @this {!Client}
   * @param {!PersonsInsertResponse} value PersonsInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsInsertResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.PersonsInsertResponse serialization failed!')
    console.assert(this.PersonsInsertResponseSenderModel.verify(), 'trade.PersonsInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonUpdateRequest value
   * @this {!Client}
   * @param {!PersonUpdateRequest} value PersonUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonUpdateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.PersonUpdateRequest serialization failed!')
    console.assert(this.PersonUpdateRequestSenderModel.verify(), 'trade.PersonUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonUpdateResponse value
   * @this {!Client}
   * @param {!PersonUpdateResponse} value PersonUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonUpdateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.PersonUpdateResponse serialization failed!')
    console.assert(this.PersonUpdateResponseSenderModel.verify(), 'trade.PersonUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryByIdRequest value
   * @this {!Client}
   * @param {!TradeHistoryByIdRequest} value TradeHistoryByIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryByIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryByIdRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.TradeHistoryByIdRequest serialization failed!')
    console.assert(this.TradeHistoryByIdRequestSenderModel.verify(), 'trade.TradeHistoryByIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryByIdResponse value
   * @this {!Client}
   * @param {!TradeHistoryByIdResponse} value TradeHistoryByIdResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryByIdResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryByIdResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.TradeHistoryByIdResponse serialization failed!')
    console.assert(this.TradeHistoryByIdResponseSenderModel.verify(), 'trade.TradeHistoryByIdResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ProfitClosedGetRequest value
   * @this {!Client}
   * @param {!ProfitClosedGetRequest} value ProfitClosedGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ProfitClosedGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ProfitClosedGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.ProfitClosedGetRequest serialization failed!')
    console.assert(this.ProfitClosedGetRequestSenderModel.verify(), 'trade.ProfitClosedGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ProfitClosedGetResponse value
   * @this {!Client}
   * @param {!ProfitClosedGetResponse} value ProfitClosedGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ProfitClosedGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ProfitClosedGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.ProfitClosedGetResponse serialization failed!')
    console.assert(this.ProfitClosedGetResponseSenderModel.verify(), 'trade.ProfitClosedGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginsGetAllRequest value
   * @this {!Client}
   * @param {!MarginsGetAllRequest} value MarginsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.MarginsGetAllRequest serialization failed!')
    console.assert(this.MarginsGetAllRequestSenderModel.verify(), 'trade.MarginsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginsGetAllResponse value
   * @this {!Client}
   * @param {!MarginsGetAllResponse} value MarginsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.MarginsGetAllResponse serialization failed!')
    console.assert(this.MarginsGetAllResponseSenderModel.verify(), 'trade.MarginsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetAllRequest value
   * @this {!Client}
   * @param {!OrdersGetAllRequest} value OrdersGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.OrdersGetAllRequest serialization failed!')
    console.assert(this.OrdersGetAllRequestSenderModel.verify(), 'trade.OrdersGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetAllResponse value
   * @this {!Client}
   * @param {!OrdersGetAllResponse} value OrdersGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.OrdersGetAllResponse serialization failed!')
    console.assert(this.OrdersGetAllResponseSenderModel.verify(), 'trade.OrdersGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetsGetAllRequest value
   * @this {!Client}
   * @param {!AssetsGetAllRequest} value AssetsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.AssetsGetAllRequest serialization failed!')
    console.assert(this.AssetsGetAllRequestSenderModel.verify(), 'trade.AssetsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetsGetAllResponse value
   * @this {!Client}
   * @param {!AssetsGetAllResponse} value AssetsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.AssetsGetAllResponse serialization failed!')
    console.assert(this.AssetsGetAllResponseSenderModel.verify(), 'trade.AssetsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionsGetAllRequest value
   * @this {!Client}
   * @param {!PositionsGetAllRequest} value PositionsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.PositionsGetAllRequest serialization failed!')
    console.assert(this.PositionsGetAllRequestSenderModel.verify(), 'trade.PositionsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionsGetAllResponse value
   * @this {!Client}
   * @param {!PositionsGetAllResponse} value PositionsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.PositionsGetAllResponse serialization failed!')
    console.assert(this.PositionsGetAllResponseSenderModel.verify(), 'trade.PositionsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsGetAllRequest value
   * @this {!Client}
   * @param {!PersonsGetAllRequest} value PersonsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.PersonsGetAllRequest serialization failed!')
    console.assert(this.PersonsGetAllRequestSenderModel.verify(), 'trade.PersonsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsGetAllResponse value
   * @this {!Client}
   * @param {!PersonsGetAllResponse} value PersonsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.PersonsGetAllResponse serialization failed!')
    console.assert(this.PersonsGetAllResponseSenderModel.verify(), 'trade.PersonsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DailySnapshotRequest value
   * @this {!Client}
   * @param {!DailySnapshotRequest} value DailySnapshotRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DailySnapshotRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DailySnapshotRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.DailySnapshotRequest serialization failed!')
    console.assert(this.DailySnapshotRequestSenderModel.verify(), 'trade.DailySnapshotRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DailySnapshotResponse value
   * @this {!Client}
   * @param {!DailySnapshotResponse} value DailySnapshotResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DailySnapshotResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DailySnapshotResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'trade.DailySnapshotResponse serialization failed!')
    console.assert(this.DailySnapshotResponseSenderModel.verify(), 'trade.DailySnapshotResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Client}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'trade.Client.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Client}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreClient.onSendHandler = handler
    this._innerClient.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Client}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreClient.onSendLogHandler = handler
    this._innerClient.onSendLogHandler = handler
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * AccountsGetAllRequest receive handler
   * @this {!Client}
   * @param {!AccountsGetAllRequest} value AccountsGetAllRequest received value
   */
  onReceive_AccountsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * AccountsGetAllResponse receive handler
   * @this {!Client}
   * @param {!AccountsGetAllResponse} value AccountsGetAllResponse received value
   */
  onReceive_AccountsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * TradeEventsGetRequest receive handler
   * @this {!Client}
   * @param {!TradeEventsGetRequest} value TradeEventsGetRequest received value
   */
  onReceive_TradeEventsGetRequest (value) {}  // eslint-disable-line

  /**
   * TradeEventsGetResponse receive handler
   * @this {!Client}
   * @param {!TradeEventsGetResponse} value TradeEventsGetResponse received value
   */
  onReceive_TradeEventsGetResponse (value) {}  // eslint-disable-line

  /**
   * ServiceStatusUpdateNotify receive handler
   * @this {!Client}
   * @param {!ServiceStatusUpdateNotify} value ServiceStatusUpdateNotify received value
   */
  onReceive_ServiceStatusUpdateNotify (value) {}  // eslint-disable-line

  /**
   * ServiceStatusGetRequest receive handler
   * @this {!Client}
   * @param {!ServiceStatusGetRequest} value ServiceStatusGetRequest received value
   */
  onReceive_ServiceStatusGetRequest (value) {}  // eslint-disable-line

  /**
   * ServiceStatusGetResponse receive handler
   * @this {!Client}
   * @param {!ServiceStatusGetResponse} value ServiceStatusGetResponse received value
   */
  onReceive_ServiceStatusGetResponse (value) {}  // eslint-disable-line

  /**
   * TradeHistoryRequest receive handler
   * @this {!Client}
   * @param {!TradeHistoryRequest} value TradeHistoryRequest received value
   */
  onReceive_TradeHistoryRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryResponse receive handler
   * @this {!Client}
   * @param {!TradeHistoryResponse} value TradeHistoryResponse received value
   */
  onReceive_TradeHistoryResponse (value) {}  // eslint-disable-line

  /**
   * PersonsInsertRequest receive handler
   * @this {!Client}
   * @param {!PersonsInsertRequest} value PersonsInsertRequest received value
   */
  onReceive_PersonsInsertRequest (value) {}  // eslint-disable-line

  /**
   * PersonsInsertResponse receive handler
   * @this {!Client}
   * @param {!PersonsInsertResponse} value PersonsInsertResponse received value
   */
  onReceive_PersonsInsertResponse (value) {}  // eslint-disable-line

  /**
   * PersonUpdateRequest receive handler
   * @this {!Client}
   * @param {!PersonUpdateRequest} value PersonUpdateRequest received value
   */
  onReceive_PersonUpdateRequest (value) {}  // eslint-disable-line

  /**
   * PersonUpdateResponse receive handler
   * @this {!Client}
   * @param {!PersonUpdateResponse} value PersonUpdateResponse received value
   */
  onReceive_PersonUpdateResponse (value) {}  // eslint-disable-line

  /**
   * TradeHistoryByIdRequest receive handler
   * @this {!Client}
   * @param {!TradeHistoryByIdRequest} value TradeHistoryByIdRequest received value
   */
  onReceive_TradeHistoryByIdRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryByIdResponse receive handler
   * @this {!Client}
   * @param {!TradeHistoryByIdResponse} value TradeHistoryByIdResponse received value
   */
  onReceive_TradeHistoryByIdResponse (value) {}  // eslint-disable-line

  /**
   * ProfitClosedGetRequest receive handler
   * @this {!Client}
   * @param {!ProfitClosedGetRequest} value ProfitClosedGetRequest received value
   */
  onReceive_ProfitClosedGetRequest (value) {}  // eslint-disable-line

  /**
   * ProfitClosedGetResponse receive handler
   * @this {!Client}
   * @param {!ProfitClosedGetResponse} value ProfitClosedGetResponse received value
   */
  onReceive_ProfitClosedGetResponse (value) {}  // eslint-disable-line

  /**
   * MarginsGetAllRequest receive handler
   * @this {!Client}
   * @param {!MarginsGetAllRequest} value MarginsGetAllRequest received value
   */
  onReceive_MarginsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * MarginsGetAllResponse receive handler
   * @this {!Client}
   * @param {!MarginsGetAllResponse} value MarginsGetAllResponse received value
   */
  onReceive_MarginsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * OrdersGetAllRequest receive handler
   * @this {!Client}
   * @param {!OrdersGetAllRequest} value OrdersGetAllRequest received value
   */
  onReceive_OrdersGetAllRequest (value) {}  // eslint-disable-line

  /**
   * OrdersGetAllResponse receive handler
   * @this {!Client}
   * @param {!OrdersGetAllResponse} value OrdersGetAllResponse received value
   */
  onReceive_OrdersGetAllResponse (value) {}  // eslint-disable-line

  /**
   * AssetsGetAllRequest receive handler
   * @this {!Client}
   * @param {!AssetsGetAllRequest} value AssetsGetAllRequest received value
   */
  onReceive_AssetsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * AssetsGetAllResponse receive handler
   * @this {!Client}
   * @param {!AssetsGetAllResponse} value AssetsGetAllResponse received value
   */
  onReceive_AssetsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * PositionsGetAllRequest receive handler
   * @this {!Client}
   * @param {!PositionsGetAllRequest} value PositionsGetAllRequest received value
   */
  onReceive_PositionsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * PositionsGetAllResponse receive handler
   * @this {!Client}
   * @param {!PositionsGetAllResponse} value PositionsGetAllResponse received value
   */
  onReceive_PositionsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * PersonsGetAllRequest receive handler
   * @this {!Client}
   * @param {!PersonsGetAllRequest} value PersonsGetAllRequest received value
   */
  onReceive_PersonsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * PersonsGetAllResponse receive handler
   * @this {!Client}
   * @param {!PersonsGetAllResponse} value PersonsGetAllResponse received value
   */
  onReceive_PersonsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * DailySnapshotRequest receive handler
   * @this {!Client}
   * @param {!DailySnapshotRequest} value DailySnapshotRequest received value
   */
  onReceive_DailySnapshotRequest (value) {}  // eslint-disable-line

  /**
   * DailySnapshotResponse receive handler
   * @this {!Client}
   * @param {!DailySnapshotResponse} value DailySnapshotResponse received value
   */
  onReceive_DailySnapshotResponse (value) {}  // eslint-disable-line

  /**
   * trade receive message handler
   * @this {!Client}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case AccountsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetAllRequestReceiverModel.verify(), 'trade.AccountsGetAllRequest validation failed!')
        let deserialized = this._AccountsGetAllRequestReceiverModel.deserialize(this._AccountsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'trade.AccountsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountsGetAllRequestReceiverValue)
        this.onReceive_AccountsGetAllRequest(this._AccountsGetAllRequestReceiverValue)
        return true
      }
      case AccountsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetAllResponseReceiverModel.verify(), 'trade.AccountsGetAllResponse validation failed!')
        let deserialized = this._AccountsGetAllResponseReceiverModel.deserialize(this._AccountsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'trade.AccountsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountsGetAllResponseReceiverValue)
        this.onReceive_AccountsGetAllResponse(this._AccountsGetAllResponseReceiverValue)
        return true
      }
      case TradeEventsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsGetRequestReceiverModel.verify(), 'trade.TradeEventsGetRequest validation failed!')
        let deserialized = this._TradeEventsGetRequestReceiverModel.deserialize(this._TradeEventsGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'trade.TradeEventsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsGetRequestReceiverValue)
        this.onReceive_TradeEventsGetRequest(this._TradeEventsGetRequestReceiverValue)
        return true
      }
      case TradeEventsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsGetResponseReceiverModel.verify(), 'trade.TradeEventsGetResponse validation failed!')
        let deserialized = this._TradeEventsGetResponseReceiverModel.deserialize(this._TradeEventsGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'trade.TradeEventsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsGetResponseReceiverValue)
        this.onReceive_TradeEventsGetResponse(this._TradeEventsGetResponseReceiverValue)
        return true
      }
      case ServiceStatusUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ServiceStatusUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ServiceStatusUpdateNotifyReceiverModel.verify(), 'trade.ServiceStatusUpdateNotify validation failed!')
        let deserialized = this._ServiceStatusUpdateNotifyReceiverModel.deserialize(this._ServiceStatusUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'trade.ServiceStatusUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ServiceStatusUpdateNotifyReceiverValue)
        this.onReceive_ServiceStatusUpdateNotify(this._ServiceStatusUpdateNotifyReceiverValue)
        return true
      }
      case ServiceStatusGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ServiceStatusGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ServiceStatusGetRequestReceiverModel.verify(), 'trade.ServiceStatusGetRequest validation failed!')
        let deserialized = this._ServiceStatusGetRequestReceiverModel.deserialize(this._ServiceStatusGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'trade.ServiceStatusGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ServiceStatusGetRequestReceiverValue)
        this.onReceive_ServiceStatusGetRequest(this._ServiceStatusGetRequestReceiverValue)
        return true
      }
      case ServiceStatusGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ServiceStatusGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ServiceStatusGetResponseReceiverModel.verify(), 'trade.ServiceStatusGetResponse validation failed!')
        let deserialized = this._ServiceStatusGetResponseReceiverModel.deserialize(this._ServiceStatusGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'trade.ServiceStatusGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ServiceStatusGetResponseReceiverValue)
        this.onReceive_ServiceStatusGetResponse(this._ServiceStatusGetResponseReceiverValue)
        return true
      }
      case TradeHistoryRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryRequestReceiverModel.verify(), 'trade.TradeHistoryRequest validation failed!')
        let deserialized = this._TradeHistoryRequestReceiverModel.deserialize(this._TradeHistoryRequestReceiverValue)
        console.assert((deserialized.size > 0), 'trade.TradeHistoryRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryRequestReceiverValue)
        this.onReceive_TradeHistoryRequest(this._TradeHistoryRequestReceiverValue)
        return true
      }
      case TradeHistoryResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryResponseReceiverModel.verify(), 'trade.TradeHistoryResponse validation failed!')
        let deserialized = this._TradeHistoryResponseReceiverModel.deserialize(this._TradeHistoryResponseReceiverValue)
        console.assert((deserialized.size > 0), 'trade.TradeHistoryResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryResponseReceiverValue)
        this.onReceive_TradeHistoryResponse(this._TradeHistoryResponseReceiverValue)
        return true
      }
      case PersonsInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsInsertRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsInsertRequestReceiverModel.verify(), 'trade.PersonsInsertRequest validation failed!')
        let deserialized = this._PersonsInsertRequestReceiverModel.deserialize(this._PersonsInsertRequestReceiverValue)
        console.assert((deserialized.size > 0), 'trade.PersonsInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsInsertRequestReceiverValue)
        this.onReceive_PersonsInsertRequest(this._PersonsInsertRequestReceiverValue)
        return true
      }
      case PersonsInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsInsertResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsInsertResponseReceiverModel.verify(), 'trade.PersonsInsertResponse validation failed!')
        let deserialized = this._PersonsInsertResponseReceiverModel.deserialize(this._PersonsInsertResponseReceiverValue)
        console.assert((deserialized.size > 0), 'trade.PersonsInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsInsertResponseReceiverValue)
        this.onReceive_PersonsInsertResponse(this._PersonsInsertResponseReceiverValue)
        return true
      }
      case PersonUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonUpdateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdateRequestReceiverModel.verify(), 'trade.PersonUpdateRequest validation failed!')
        let deserialized = this._PersonUpdateRequestReceiverModel.deserialize(this._PersonUpdateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'trade.PersonUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonUpdateRequestReceiverValue)
        this.onReceive_PersonUpdateRequest(this._PersonUpdateRequestReceiverValue)
        return true
      }
      case PersonUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonUpdateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdateResponseReceiverModel.verify(), 'trade.PersonUpdateResponse validation failed!')
        let deserialized = this._PersonUpdateResponseReceiverModel.deserialize(this._PersonUpdateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'trade.PersonUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonUpdateResponseReceiverValue)
        this.onReceive_PersonUpdateResponse(this._PersonUpdateResponseReceiverValue)
        return true
      }
      case TradeHistoryByIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryByIdRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryByIdRequestReceiverModel.verify(), 'trade.TradeHistoryByIdRequest validation failed!')
        let deserialized = this._TradeHistoryByIdRequestReceiverModel.deserialize(this._TradeHistoryByIdRequestReceiverValue)
        console.assert((deserialized.size > 0), 'trade.TradeHistoryByIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryByIdRequestReceiverValue)
        this.onReceive_TradeHistoryByIdRequest(this._TradeHistoryByIdRequestReceiverValue)
        return true
      }
      case TradeHistoryByIdResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryByIdResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryByIdResponseReceiverModel.verify(), 'trade.TradeHistoryByIdResponse validation failed!')
        let deserialized = this._TradeHistoryByIdResponseReceiverModel.deserialize(this._TradeHistoryByIdResponseReceiverValue)
        console.assert((deserialized.size > 0), 'trade.TradeHistoryByIdResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryByIdResponseReceiverValue)
        this.onReceive_TradeHistoryByIdResponse(this._TradeHistoryByIdResponseReceiverValue)
        return true
      }
      case ProfitClosedGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ProfitClosedGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedGetRequestReceiverModel.verify(), 'trade.ProfitClosedGetRequest validation failed!')
        let deserialized = this._ProfitClosedGetRequestReceiverModel.deserialize(this._ProfitClosedGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'trade.ProfitClosedGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ProfitClosedGetRequestReceiverValue)
        this.onReceive_ProfitClosedGetRequest(this._ProfitClosedGetRequestReceiverValue)
        return true
      }
      case ProfitClosedGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ProfitClosedGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedGetResponseReceiverModel.verify(), 'trade.ProfitClosedGetResponse validation failed!')
        let deserialized = this._ProfitClosedGetResponseReceiverModel.deserialize(this._ProfitClosedGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'trade.ProfitClosedGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ProfitClosedGetResponseReceiverValue)
        this.onReceive_ProfitClosedGetResponse(this._ProfitClosedGetResponseReceiverValue)
        return true
      }
      case MarginsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginsGetAllRequestReceiverModel.verify(), 'trade.MarginsGetAllRequest validation failed!')
        let deserialized = this._MarginsGetAllRequestReceiverModel.deserialize(this._MarginsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'trade.MarginsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginsGetAllRequestReceiverValue)
        this.onReceive_MarginsGetAllRequest(this._MarginsGetAllRequestReceiverValue)
        return true
      }
      case MarginsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginsGetAllResponseReceiverModel.verify(), 'trade.MarginsGetAllResponse validation failed!')
        let deserialized = this._MarginsGetAllResponseReceiverModel.deserialize(this._MarginsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'trade.MarginsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginsGetAllResponseReceiverValue)
        this.onReceive_MarginsGetAllResponse(this._MarginsGetAllResponseReceiverValue)
        return true
      }
      case OrdersGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetAllRequestReceiverModel.verify(), 'trade.OrdersGetAllRequest validation failed!')
        let deserialized = this._OrdersGetAllRequestReceiverModel.deserialize(this._OrdersGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'trade.OrdersGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetAllRequestReceiverValue)
        this.onReceive_OrdersGetAllRequest(this._OrdersGetAllRequestReceiverValue)
        return true
      }
      case OrdersGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetAllResponseReceiverModel.verify(), 'trade.OrdersGetAllResponse validation failed!')
        let deserialized = this._OrdersGetAllResponseReceiverModel.deserialize(this._OrdersGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'trade.OrdersGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetAllResponseReceiverValue)
        this.onReceive_OrdersGetAllResponse(this._OrdersGetAllResponseReceiverValue)
        return true
      }
      case AssetsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetAllRequestReceiverModel.verify(), 'trade.AssetsGetAllRequest validation failed!')
        let deserialized = this._AssetsGetAllRequestReceiverModel.deserialize(this._AssetsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'trade.AssetsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetsGetAllRequestReceiverValue)
        this.onReceive_AssetsGetAllRequest(this._AssetsGetAllRequestReceiverValue)
        return true
      }
      case AssetsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetAllResponseReceiverModel.verify(), 'trade.AssetsGetAllResponse validation failed!')
        let deserialized = this._AssetsGetAllResponseReceiverModel.deserialize(this._AssetsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'trade.AssetsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetsGetAllResponseReceiverValue)
        this.onReceive_AssetsGetAllResponse(this._AssetsGetAllResponseReceiverValue)
        return true
      }
      case PositionsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetAllRequestReceiverModel.verify(), 'trade.PositionsGetAllRequest validation failed!')
        let deserialized = this._PositionsGetAllRequestReceiverModel.deserialize(this._PositionsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'trade.PositionsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionsGetAllRequestReceiverValue)
        this.onReceive_PositionsGetAllRequest(this._PositionsGetAllRequestReceiverValue)
        return true
      }
      case PositionsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetAllResponseReceiverModel.verify(), 'trade.PositionsGetAllResponse validation failed!')
        let deserialized = this._PositionsGetAllResponseReceiverModel.deserialize(this._PositionsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'trade.PositionsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionsGetAllResponseReceiverValue)
        this.onReceive_PositionsGetAllResponse(this._PositionsGetAllResponseReceiverValue)
        return true
      }
      case PersonsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsGetAllRequestReceiverModel.verify(), 'trade.PersonsGetAllRequest validation failed!')
        let deserialized = this._PersonsGetAllRequestReceiverModel.deserialize(this._PersonsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'trade.PersonsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsGetAllRequestReceiverValue)
        this.onReceive_PersonsGetAllRequest(this._PersonsGetAllRequestReceiverValue)
        return true
      }
      case PersonsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsGetAllResponseReceiverModel.verify(), 'trade.PersonsGetAllResponse validation failed!')
        let deserialized = this._PersonsGetAllResponseReceiverModel.deserialize(this._PersonsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'trade.PersonsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsGetAllResponseReceiverValue)
        this.onReceive_PersonsGetAllResponse(this._PersonsGetAllResponseReceiverValue)
        return true
      }
      case DailySnapshotRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DailySnapshotRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DailySnapshotRequestReceiverModel.verify(), 'trade.DailySnapshotRequest validation failed!')
        let deserialized = this._DailySnapshotRequestReceiverModel.deserialize(this._DailySnapshotRequestReceiverValue)
        console.assert((deserialized.size > 0), 'trade.DailySnapshotRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DailySnapshotRequestReceiverValue)
        this.onReceive_DailySnapshotRequest(this._DailySnapshotRequestReceiverValue)
        return true
      }
      case DailySnapshotResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DailySnapshotResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DailySnapshotResponseReceiverModel.verify(), 'trade.DailySnapshotResponse validation failed!')
        let deserialized = this._DailySnapshotResponseReceiverModel.deserialize(this._DailySnapshotResponseReceiverValue)
        console.assert((deserialized.size > 0), 'trade.DailySnapshotResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DailySnapshotResponseReceiverValue)
        this.onReceive_DailySnapshotResponse(this._DailySnapshotResponseReceiverValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreClient != null) && this.coreClient.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.innerClient != null) && this.innerClient.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Client}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreClient.onReceiveLogHandler = handler
    this._innerClient.onReceiveLogHandler = handler
  }

  // Request methods

  /**
   * Request value
   * @this {!Client}
   * @param {!object} value Value to request
   * @param {!number} timeout Timeout in milliseconds (default is 0)
   * @returns {Promise} Response promise
   */
  request (value, timeout = 0) {
    let promise = new fbe.DeferredPromise()
    let current = Date.now()

    // Send the request message
    let serialized = this.send(value)
    if (serialized > 0) {
      // Calculate the unique timestamp
      this._timestamp = (current <= this._timestamp) ? this._timestamp + 1 : current

      // Register the request
      this._requests_by_id[value.id] = [this._timestamp, timeout * 1000000, promise]
      if (timeout > 0) {
        this._requests_by_timestamp[this._timestamp] = value.id
      }
    } else {
      promise.reject(new Error('Serialization failed!'))
    }

    return promise
  }

  /**
   * Response value
   * @this {!Client}
   * @param {!object} value Value to response
   * @returns {!boolean} Response handle flag
   */
  response (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.resolve(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.response(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._innerClient.response(value)) {
      return true
    }

    return false
  }

  /**
   * Reject value
   * @this {!Client}
   * @param {!object} value Value to reject
   * @returns {!boolean} Reject handle flag
   */
  reject (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.reject(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.reject(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._innerClient.reject(value)) {
      return true
    }

    return false
  }

  /**
   * Reset client requests
   * @this {!Client}
   */
  resetRequests () {
    this._coreClient.resetRequests()
    this._innerClient.resetRequests()

    for (let [, value] of this._requests_by_id) {
      value[2].reject(new Error('Reset client!'))
    }
    this._requests_by_id.clear()
    this._requests_by_timestamp.clear()
  }

  /**
   * Watchdog client requests for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp in milliseconds
   */
  watchdogRequests (utc) {
    this._coreClient.watchdogRequests(utc)
    this._innerClient.watchdogRequests(utc)

    for (let [, value] of this._requests_by_timestamp) {
      let item = this._requests_by_id[value]
      let id = value
      let timestamp = item[0]
      let timespan = item[1]
      if ((timestamp + timespan) <= utc) {
        let promise = item[2]
        promise.reject(new Error('Timeout!'))
        this._requests_by_id.delete(id)
        this._requests_by_timestamp.delete(timestamp)
      }
    }
  }
}

export { Client };
