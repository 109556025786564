
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {OrderDeleteResponse} from './OrderDeleteResponse';
import {TradeTransactionType} from '../core/TradeTransactionType';
import {FieldModelTradeTransactionType} from '../core/TradeTransactionType';
import {OrderStatus} from '../core/OrderStatus';
import {FieldModelOrderStatus} from '../core/OrderStatus';
import {TradeEvent} from '../entity/TradeEvent';
import {FieldModelTradeEvent} from '../entity/TradeEvent';
import {TradeTransactionReason} from '../core/TradeTransactionReason';
import {FieldModelTradeTransactionReason} from '../core/TradeTransactionReason';
import {AccountMarginUpdate} from './AccountMarginUpdate';
import {FieldModelAccountMarginUpdate} from './AccountMarginUpdate';

/**
 * OrderDeleteRequest struct
 */
class OrderDeleteRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {!number=} orderId
   * @param {!UUID=} accountGroupId
   * @param {!TradeTransactionType=} transactionType
   * @param {number=} closePrice
   * @param {number=} closeAmount
   * @param {number=} profit
   * @param {number=} profitRate
   * @param {number=} balanceChange
   * @param {number=} closeMarginRate
   * @param {number=} closeMarkup
   * @param {string=} managerComment
   * @param {OrderStatus=} orderStatus
   * @param {!Array=} tradeEvents
   * @param {!TradeTransactionReason=} transactionReason
   * @param {string=} externalClientId
   * @param {number=} lastClosePrice
   * @param {number=} lastCloseAmount
   * @param {AccountMarginUpdate=} accountMarginUpdate
   * @param {number=} transactionTradeVolumeUSD
   * @constructor
   */
  constructor (parent = new Request(), orderId = new UInt64(0, 0), accountGroupId = new UUID(), transactionType = new TradeTransactionType(), closePrice = null, closeAmount = null, profit = null, profitRate = null, balanceChange = null, closeMarginRate = null, closeMarkup = null, managerComment = null, orderStatus = null, tradeEvents = [], transactionReason = new TradeTransactionReason(), externalClientId = null, lastClosePrice = null, lastCloseAmount = null, accountMarginUpdate = null, transactionTradeVolumeUSD = null) {
    super()
    super.copy(parent)
    this.OrderId = orderId
    this.AccountGroupId = accountGroupId
    this.TransactionType = transactionType
    this.ClosePrice = closePrice
    this.CloseAmount = closeAmount
    this.Profit = profit
    this.ProfitRate = profitRate
    this.BalanceChange = balanceChange
    this.CloseMarginRate = closeMarginRate
    this.CloseMarkup = closeMarkup
    this.ManagerComment = managerComment
    this.OrderStatus = orderStatus
    this.TradeEvents = tradeEvents
    this.TransactionReason = transactionReason
    this.ExternalClientId = externalClientId
    this.LastClosePrice = lastClosePrice
    this.LastCloseAmount = lastCloseAmount
    this.AccountMarginUpdate = accountMarginUpdate
    this.TransactionTradeVolumeUSD = transactionTradeVolumeUSD
  }

  /**
   * Copy struct (shallow copy)
   * @this {!OrderDeleteRequest}
   * @param {!OrderDeleteRequest} other Other struct
   * @returns {!OrderDeleteRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.OrderId != null) {
      this.OrderId = UInt64.fromNumber(other.OrderId)
    } else {
      this.OrderId = null
    }
    if (other.AccountGroupId != null) {
      this.AccountGroupId = new UUID(other.AccountGroupId)
    } else {
      this.AccountGroupId = null
    }
    if (other.TransactionType != null) {
      this.TransactionType = TradeTransactionType.fromObject(other.TransactionType)
    } else {
      this.TransactionType = null
    }
    if (other.ClosePrice != null) {
      this.ClosePrice = other.ClosePrice
    } else {
      this.ClosePrice = null
    }
    if (other.CloseAmount != null) {
      this.CloseAmount = other.CloseAmount
    } else {
      this.CloseAmount = null
    }
    if (other.Profit != null) {
      this.Profit = other.Profit
    } else {
      this.Profit = null
    }
    if (other.ProfitRate != null) {
      this.ProfitRate = other.ProfitRate
    } else {
      this.ProfitRate = null
    }
    if (other.BalanceChange != null) {
      this.BalanceChange = other.BalanceChange
    } else {
      this.BalanceChange = null
    }
    if (other.CloseMarginRate != null) {
      this.CloseMarginRate = other.CloseMarginRate
    } else {
      this.CloseMarginRate = null
    }
    if (other.CloseMarkup != null) {
      this.CloseMarkup = UInt64.fromNumber(other.CloseMarkup)
    } else {
      this.CloseMarkup = null
    }
    if (other.ManagerComment != null) {
      this.ManagerComment = other.ManagerComment
    } else {
      this.ManagerComment = null
    }
    if (other.OrderStatus != null) {
      this.OrderStatus = OrderStatus.fromObject(other.OrderStatus)
    } else {
      this.OrderStatus = null
    }
    if (other.TradeEvents != null) {
      this.TradeEvents = []
      for (let item of other.TradeEvents) {
        if (item != null) {
          let tempItem
          tempItem = TradeEvent.fromObject(item)
          this.TradeEvents.push(tempItem)
        } else {
          this.TradeEvents.push(null)
        }
      }
    } else {
      this.TradeEvents = null
    }
    if (other.TransactionReason != null) {
      this.TransactionReason = TradeTransactionReason.fromObject(other.TransactionReason)
    } else {
      this.TransactionReason = null
    }
    if (other.ExternalClientId != null) {
      this.ExternalClientId = other.ExternalClientId
    } else {
      this.ExternalClientId = null
    }
    if (other.LastClosePrice != null) {
      this.LastClosePrice = other.LastClosePrice
    } else {
      this.LastClosePrice = null
    }
    if (other.LastCloseAmount != null) {
      this.LastCloseAmount = other.LastCloseAmount
    } else {
      this.LastCloseAmount = null
    }
    if (other.AccountMarginUpdate != null) {
      this.AccountMarginUpdate = AccountMarginUpdate.fromObject(other.AccountMarginUpdate)
    } else {
      this.AccountMarginUpdate = null
    }
    if (other.TransactionTradeVolumeUSD != null) {
      this.TransactionTradeVolumeUSD = other.TransactionTradeVolumeUSD
    } else {
      this.TransactionTradeVolumeUSD = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!OrderDeleteRequest}
   * @returns {!OrderDeleteRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new OrderDeleteRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new OrderDeleteRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!OrderDeleteRequest}
   * @param {!OrderDeleteRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof OrderDeleteRequest)) {
      throw new TypeError('Instance of OrderDeleteRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!OrderDeleteRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      OrderId: ((this.OrderId != null) ? this.OrderId.toNumber() : null),
      AccountGroupId: ((this.AccountGroupId != null) ? this.AccountGroupId.toString() : null),
      TransactionType: ((this.TransactionType != null) ? this.TransactionType : null),
      ClosePrice: ((this.ClosePrice != null) ? this.ClosePrice : null),
      CloseAmount: ((this.CloseAmount != null) ? this.CloseAmount : null),
      Profit: ((this.Profit != null) ? this.Profit : null),
      ProfitRate: ((this.ProfitRate != null) ? this.ProfitRate : null),
      BalanceChange: ((this.BalanceChange != null) ? this.BalanceChange : null),
      CloseMarginRate: ((this.CloseMarginRate != null) ? this.CloseMarginRate : null),
      CloseMarkup: ((this.CloseMarkup != null) ? this.CloseMarkup.toNumber() : null),
      ManagerComment: ((this.ManagerComment != null) ? this.ManagerComment : null),
      OrderStatus: ((this.OrderStatus != null) ? this.OrderStatus : null),
      TradeEvents: ((this.TradeEvents != null) ? Array.from(this.TradeEvents, item => ((item != null) ? item : null)) : null),
      TransactionReason: ((this.TransactionReason != null) ? this.TransactionReason : null),
      ExternalClientId: ((this.ExternalClientId != null) ? this.ExternalClientId : null),
      LastClosePrice: ((this.LastClosePrice != null) ? this.LastClosePrice : null),
      LastCloseAmount: ((this.LastCloseAmount != null) ? this.LastCloseAmount : null),
      AccountMarginUpdate: ((this.AccountMarginUpdate != null) ? this.AccountMarginUpdate : null),
      TransactionTradeVolumeUSD: ((this.TransactionTradeVolumeUSD != null) ? this.TransactionTradeVolumeUSD : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return OrderDeleteRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!OrderDeleteRequest} other Object value
   * @returns {!OrderDeleteRequest} Created struct
   */
  static fromObject (other) {
    return new OrderDeleteRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!OrderDeleteRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return OrderDeleteRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!OrderDeleteRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 4026
  }
}

export { OrderDeleteRequest };

/**
 * Fast Binary Encoding OrderDeleteRequest field model
 */
class FieldModelOrderDeleteRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.OrderId = new fbe.FieldModelUInt64(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.AccountGroupId = new fbe.FieldModelUUID(buffer, this.OrderId.fbeOffset + this.OrderId.fbeSize)
    this.TransactionType = new FieldModelTradeTransactionType(buffer, this.AccountGroupId.fbeOffset + this.AccountGroupId.fbeSize)
    this.ClosePrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.TransactionType.fbeOffset + this.TransactionType.fbeSize), buffer, this.TransactionType.fbeOffset + this.TransactionType.fbeSize)
    this.CloseAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.ClosePrice.fbeOffset + this.ClosePrice.fbeSize), buffer, this.ClosePrice.fbeOffset + this.ClosePrice.fbeSize)
    this.Profit = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.CloseAmount.fbeOffset + this.CloseAmount.fbeSize), buffer, this.CloseAmount.fbeOffset + this.CloseAmount.fbeSize)
    this.ProfitRate = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Profit.fbeOffset + this.Profit.fbeSize), buffer, this.Profit.fbeOffset + this.Profit.fbeSize)
    this.BalanceChange = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.ProfitRate.fbeOffset + this.ProfitRate.fbeSize), buffer, this.ProfitRate.fbeOffset + this.ProfitRate.fbeSize)
    this.CloseMarginRate = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.BalanceChange.fbeOffset + this.BalanceChange.fbeSize), buffer, this.BalanceChange.fbeOffset + this.BalanceChange.fbeSize)
    this.CloseMarkup = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.CloseMarginRate.fbeOffset + this.CloseMarginRate.fbeSize), buffer, this.CloseMarginRate.fbeOffset + this.CloseMarginRate.fbeSize)
    this.ManagerComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.CloseMarkup.fbeOffset + this.CloseMarkup.fbeSize), buffer, this.CloseMarkup.fbeOffset + this.CloseMarkup.fbeSize)
    this.OrderStatus = new fbe.FieldModelOptional(new FieldModelOrderStatus(buffer, this.ManagerComment.fbeOffset + this.ManagerComment.fbeSize), buffer, this.ManagerComment.fbeOffset + this.ManagerComment.fbeSize)
    this.TradeEvents = new fbe.FieldModelVector(new FieldModelTradeEvent(buffer, this.OrderStatus.fbeOffset + this.OrderStatus.fbeSize), buffer, this.OrderStatus.fbeOffset + this.OrderStatus.fbeSize)
    this.TransactionReason = new FieldModelTradeTransactionReason(buffer, this.TradeEvents.fbeOffset + this.TradeEvents.fbeSize)
    this.ExternalClientId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.TransactionReason.fbeOffset + this.TransactionReason.fbeSize), buffer, this.TransactionReason.fbeOffset + this.TransactionReason.fbeSize)
    this.LastClosePrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.ExternalClientId.fbeOffset + this.ExternalClientId.fbeSize), buffer, this.ExternalClientId.fbeOffset + this.ExternalClientId.fbeSize)
    this.LastCloseAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.LastClosePrice.fbeOffset + this.LastClosePrice.fbeSize), buffer, this.LastClosePrice.fbeOffset + this.LastClosePrice.fbeSize)
    this.AccountMarginUpdate = new fbe.FieldModelOptional(new FieldModelAccountMarginUpdate(buffer, this.LastCloseAmount.fbeOffset + this.LastCloseAmount.fbeSize), buffer, this.LastCloseAmount.fbeOffset + this.LastCloseAmount.fbeSize)
    this.TransactionTradeVolumeUSD = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.AccountMarginUpdate.fbeOffset + this.AccountMarginUpdate.fbeSize), buffer, this.AccountMarginUpdate.fbeOffset + this.AccountMarginUpdate.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelOrderDeleteRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelOrderDeleteRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelOrderDeleteRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.OrderId.fbeSize + this.AccountGroupId.fbeSize + this.TransactionType.fbeSize + this.ClosePrice.fbeSize + this.CloseAmount.fbeSize + this.Profit.fbeSize + this.ProfitRate.fbeSize + this.BalanceChange.fbeSize + this.CloseMarginRate.fbeSize + this.CloseMarkup.fbeSize + this.ManagerComment.fbeSize + this.OrderStatus.fbeSize + this.TradeEvents.fbeSize + this.TransactionReason.fbeSize + this.ExternalClientId.fbeSize + this.LastClosePrice.fbeSize + this.LastCloseAmount.fbeSize + this.AccountMarginUpdate.fbeSize + this.TransactionTradeVolumeUSD.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOrderDeleteRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.OrderId.fbeExtra + this.AccountGroupId.fbeExtra + this.TransactionType.fbeExtra + this.ClosePrice.fbeExtra + this.CloseAmount.fbeExtra + this.Profit.fbeExtra + this.ProfitRate.fbeExtra + this.BalanceChange.fbeExtra + this.CloseMarginRate.fbeExtra + this.CloseMarkup.fbeExtra + this.ManagerComment.fbeExtra + this.OrderStatus.fbeExtra + this.TradeEvents.fbeExtra + this.TransactionReason.fbeExtra + this.ExternalClientId.fbeExtra + this.LastClosePrice.fbeExtra + this.LastCloseAmount.fbeExtra + this.AccountMarginUpdate.fbeExtra + this.TransactionTradeVolumeUSD.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelOrderDeleteRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelOrderDeleteRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelOrderDeleteRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 4026
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelOrderDeleteRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelOrderDeleteRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.OrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderId.fbeSize

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.TransactionType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TransactionType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionType.fbeSize

    if ((fbeCurrentSize + this.ClosePrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClosePrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClosePrice.fbeSize

    if ((fbeCurrentSize + this.CloseAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CloseAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CloseAmount.fbeSize

    if ((fbeCurrentSize + this.Profit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Profit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Profit.fbeSize

    if ((fbeCurrentSize + this.ProfitRate.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProfitRate.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitRate.fbeSize

    if ((fbeCurrentSize + this.BalanceChange.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BalanceChange.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceChange.fbeSize

    if ((fbeCurrentSize + this.CloseMarginRate.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CloseMarginRate.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CloseMarginRate.fbeSize

    if ((fbeCurrentSize + this.CloseMarkup.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CloseMarkup.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CloseMarkup.fbeSize

    if ((fbeCurrentSize + this.ManagerComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ManagerComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerComment.fbeSize

    if ((fbeCurrentSize + this.OrderStatus.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderStatus.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderStatus.fbeSize

    if ((fbeCurrentSize + this.TradeEvents.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradeEvents.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeEvents.fbeSize

    if ((fbeCurrentSize + this.TransactionReason.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TransactionReason.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionReason.fbeSize

    if ((fbeCurrentSize + this.ExternalClientId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExternalClientId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalClientId.fbeSize

    if ((fbeCurrentSize + this.LastClosePrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LastClosePrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastClosePrice.fbeSize

    if ((fbeCurrentSize + this.LastCloseAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LastCloseAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastCloseAmount.fbeSize

    if ((fbeCurrentSize + this.AccountMarginUpdate.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountMarginUpdate.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginUpdate.fbeSize

    if ((fbeCurrentSize + this.TransactionTradeVolumeUSD.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TransactionTradeVolumeUSD.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionTradeVolumeUSD.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelOrderDeleteRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelOrderDeleteRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelOrderDeleteRequest}
   * @param {!OrderDeleteRequest} fbeValue Default value, defaults is new OrderDeleteRequest()
   * @returns {!OrderDeleteRequest} OrderDeleteRequest value
   */
  get (fbeValue = new OrderDeleteRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelOrderDeleteRequest}
   * @param {!OrderDeleteRequest} fbeValue OrderDeleteRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.OrderId.fbeSize) <= fbeStructSize) {
      fbeValue.OrderId = this.OrderId.get()
    } else {
      fbeValue.OrderId = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderId.fbeSize

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) <= fbeStructSize) {
      fbeValue.AccountGroupId = this.AccountGroupId.get()
    } else {
      fbeValue.AccountGroupId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.TransactionType.fbeSize) <= fbeStructSize) {
      fbeValue.TransactionType = this.TransactionType.get()
    } else {
      fbeValue.TransactionType = new TradeTransactionType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionType.fbeSize

    if ((fbeCurrentSize + this.ClosePrice.fbeSize) <= fbeStructSize) {
      fbeValue.ClosePrice = this.ClosePrice.get()
    } else {
      fbeValue.ClosePrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClosePrice.fbeSize

    if ((fbeCurrentSize + this.CloseAmount.fbeSize) <= fbeStructSize) {
      fbeValue.CloseAmount = this.CloseAmount.get()
    } else {
      fbeValue.CloseAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CloseAmount.fbeSize

    if ((fbeCurrentSize + this.Profit.fbeSize) <= fbeStructSize) {
      fbeValue.Profit = this.Profit.get()
    } else {
      fbeValue.Profit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Profit.fbeSize

    if ((fbeCurrentSize + this.ProfitRate.fbeSize) <= fbeStructSize) {
      fbeValue.ProfitRate = this.ProfitRate.get()
    } else {
      fbeValue.ProfitRate = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitRate.fbeSize

    if ((fbeCurrentSize + this.BalanceChange.fbeSize) <= fbeStructSize) {
      fbeValue.BalanceChange = this.BalanceChange.get()
    } else {
      fbeValue.BalanceChange = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceChange.fbeSize

    if ((fbeCurrentSize + this.CloseMarginRate.fbeSize) <= fbeStructSize) {
      fbeValue.CloseMarginRate = this.CloseMarginRate.get()
    } else {
      fbeValue.CloseMarginRate = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CloseMarginRate.fbeSize

    if ((fbeCurrentSize + this.CloseMarkup.fbeSize) <= fbeStructSize) {
      fbeValue.CloseMarkup = this.CloseMarkup.get()
    } else {
      fbeValue.CloseMarkup = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CloseMarkup.fbeSize

    if ((fbeCurrentSize + this.ManagerComment.fbeSize) <= fbeStructSize) {
      fbeValue.ManagerComment = this.ManagerComment.get()
    } else {
      fbeValue.ManagerComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerComment.fbeSize

    if ((fbeCurrentSize + this.OrderStatus.fbeSize) <= fbeStructSize) {
      fbeValue.OrderStatus = this.OrderStatus.get()
    } else {
      fbeValue.OrderStatus = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderStatus.fbeSize

    if ((fbeCurrentSize + this.TradeEvents.fbeSize) <= fbeStructSize) {
      this.TradeEvents.get(fbeValue.TradeEvents)
    } else {
      fbeValue.TradeEvents.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeEvents.fbeSize

    if ((fbeCurrentSize + this.TransactionReason.fbeSize) <= fbeStructSize) {
      fbeValue.TransactionReason = this.TransactionReason.get()
    } else {
      fbeValue.TransactionReason = new TradeTransactionReason()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionReason.fbeSize

    if ((fbeCurrentSize + this.ExternalClientId.fbeSize) <= fbeStructSize) {
      fbeValue.ExternalClientId = this.ExternalClientId.get()
    } else {
      fbeValue.ExternalClientId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalClientId.fbeSize

    if ((fbeCurrentSize + this.LastClosePrice.fbeSize) <= fbeStructSize) {
      fbeValue.LastClosePrice = this.LastClosePrice.get()
    } else {
      fbeValue.LastClosePrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastClosePrice.fbeSize

    if ((fbeCurrentSize + this.LastCloseAmount.fbeSize) <= fbeStructSize) {
      fbeValue.LastCloseAmount = this.LastCloseAmount.get()
    } else {
      fbeValue.LastCloseAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastCloseAmount.fbeSize

    if ((fbeCurrentSize + this.AccountMarginUpdate.fbeSize) <= fbeStructSize) {
      fbeValue.AccountMarginUpdate = this.AccountMarginUpdate.get()
    } else {
      fbeValue.AccountMarginUpdate = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginUpdate.fbeSize

    if ((fbeCurrentSize + this.TransactionTradeVolumeUSD.fbeSize) <= fbeStructSize) {
      fbeValue.TransactionTradeVolumeUSD = this.TransactionTradeVolumeUSD.get()
    } else {
      fbeValue.TransactionTradeVolumeUSD = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionTradeVolumeUSD.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelOrderDeleteRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelOrderDeleteRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelOrderDeleteRequest}
   * @param {!OrderDeleteRequest} fbeValue OrderDeleteRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelOrderDeleteRequest}
   * @param {OrderDeleteRequest} fbeValue OrderDeleteRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.OrderId.set(fbeValue.OrderId)
    this.AccountGroupId.set(fbeValue.AccountGroupId)
    this.TransactionType.set(fbeValue.TransactionType)
    this.ClosePrice.set(fbeValue.ClosePrice)
    this.CloseAmount.set(fbeValue.CloseAmount)
    this.Profit.set(fbeValue.Profit)
    this.ProfitRate.set(fbeValue.ProfitRate)
    this.BalanceChange.set(fbeValue.BalanceChange)
    this.CloseMarginRate.set(fbeValue.CloseMarginRate)
    this.CloseMarkup.set(fbeValue.CloseMarkup)
    this.ManagerComment.set(fbeValue.ManagerComment)
    this.OrderStatus.set(fbeValue.OrderStatus)
    this.TradeEvents.set(fbeValue.TradeEvents)
    this.TransactionReason.set(fbeValue.TransactionReason)
    this.ExternalClientId.set(fbeValue.ExternalClientId)
    this.LastClosePrice.set(fbeValue.LastClosePrice)
    this.LastCloseAmount.set(fbeValue.LastCloseAmount)
    this.AccountMarginUpdate.set(fbeValue.AccountMarginUpdate)
    this.TransactionTradeVolumeUSD.set(fbeValue.TransactionTradeVolumeUSD)
  }
}

export { FieldModelOrderDeleteRequest };

/**
 * Fast Binary Encoding OrderDeleteRequest model
 */
class OrderDeleteRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelOrderDeleteRequest(this.buffer, 4)
  }

  /**
   * Get the OrderDeleteRequest model
   * @this {!OrderDeleteRequestModel}
   * @returns {!FieldModelOrderDeleteRequest} model OrderDeleteRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!OrderDeleteRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!OrderDeleteRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return OrderDeleteRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!OrderDeleteRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelOrderDeleteRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!OrderDeleteRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!OrderDeleteRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!OrderDeleteRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!OrderDeleteRequestModel}
   * @param {!OrderDeleteRequest} value OrderDeleteRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!OrderDeleteRequestModel}
   * @param {!OrderDeleteRequest} value OrderDeleteRequest value, defaults is new OrderDeleteRequest()
   * @return {!object} Deserialized OrderDeleteRequest value and its size
   */
  deserialize (value = new OrderDeleteRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new OrderDeleteRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new OrderDeleteRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!OrderDeleteRequestModel}
   * @param {!number} prev Previous OrderDeleteRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { OrderDeleteRequestModel };
OrderDeleteRequest.__has_response = true;
OrderDeleteRequest.__response_class = OrderDeleteResponse;
