/* eslint-disable class-methods-use-this */
import { makeAutoObservable } from 'mobx';
import { ErrorsTracker } from '@api/errorsTracker';
import { SocketClient } from '@api/SocketClient';
import { UUID } from '@api/fbe/uuid';
import { keyMap, t } from '@localization';
import { AccessServerSettings } from '@api/fbe/core/AccessServerSettings';
import { BackupServerSettings } from '@api/fbe/core/BackupServerSettings';
import { ConfigServerSettings } from '@api/fbe/core/ConfigServerSettings';
import { ExecutionServerSettings } from '@api/fbe/core/ExecutionServerSettings';
import { ExportServerSettings } from '@api/fbe/core/ExportServerSettings';
import { FeedServerSettings } from '@api/fbe/core/FeedServerSettings';
import { HistoryServerSettings } from '@api/fbe/core/HistoryServerSettings';
import { DrawingStorageServerSettings } from '@api/fbe/core/DrawingStorageServerSettings';
import { LoggingSettings } from '@api/fbe/core/LoggingSettings';
import { LogLevelType } from '@api/fbe/core/LogLevelType';
import { ModuleType } from '@api/fbe/core/ModuleType';
import { StorageServerSettings } from '@api/fbe/core/StorageServerSettings';
import { TradeServerSettings } from '@api/fbe/core/TradeServerSettings';
import { Module, convertNewsServicesSettings, convertUUIDToString } from '@models/Modules';
import { ModuleSystemInfo, StatusLog } from '@pages/ModulesWatch/types';
import { adaptApi } from '@utils/models';
import { ModuleModifyRequest } from '@api/fbe/manager/ModuleModifyRequest';
import { ModulesGetAllRequest } from '@api/fbe/manager/ModulesGetAllRequest';
import { ModulesSystemInfoGetAllRequest } from '@api/fbe/manager/ModulesSystemInfoGetAllRequest';
import { ModulesSystemInfoNotify } from '@api/fbe/manager/ModulesSystemInfoNotify';
import { ModuleCreateRequest } from '@api/fbe/manager/ModuleCreateRequest';
import { ModuleDeleteRequest } from '@api/fbe/manager/ModuleDeleteRequest';
import { NANOSEC_IN_MILLISEC } from '@utils/datetime';

type RequestParams = {
    LoggingSettings: LoggingSettings | null;
    AccessServerSettings: AccessServerSettings | null;
    ConfigServerSettings: ConfigServerSettings | null;
    FeedServerSettings: FeedServerSettings | null;
    HistoryServerSettings: HistoryServerSettings | null;
    StorageServerSettings: StorageServerSettings | null;
    TradeServerSettings: TradeServerSettings | null;
    ExecutionServerSettings: ExecutionServerSettings | null;
    BackupServerSettings: BackupServerSettings | null;
    ExportServerSettings: ExportServerSettings | null;
    DrawingStorageServerSettings: DrawingStorageServerSettings | null;
};

const nanosecToMillis = (value: number | null): number | null => {
    if (Number.isFinite(value) && value !== null) return value / NANOSEC_IN_MILLISEC;
    return null;
};
const millisToNanosec = (value: number | null): number | null => {
    if (Number.isFinite(value) && value !== null) return value * NANOSEC_IN_MILLISEC;
    return null;
};
export class ModulesStore {
    errorTracker = new ErrorsTracker({ title: 'Modules' });

    constructor() {
        makeAutoObservable(this);
    }

    data: Module[] = [];

    isLoading: boolean = false;

    systemInfo: ModuleSystemInfo[] | null = null;

    statusLog: StatusLog[] | null = null;

    setData(value: Module[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    setSystemInfo(value: ModuleSystemInfo[] | null) {
        this.systemInfo = value;
    }

    setStatusLog(value: StatusLog[] | null) {
        this.statusLog = value;
    }

    async updateItemSortOrder(id: string, newIndex: number) {
        const targetItem = this.data.find((item) => item.id === id);
        if (targetItem) {
            const request = new ModuleModifyRequest();
            request.ModuleId = UUID.fromString(targetItem.id);
            request.NewSortOrder = newIndex;
            await SocketClient.instance.request(request, this.errorTracker);
            await this.get();
        } else {
            throw new Error(t(keyMap.messages.modules.reorderError));
        }
    }

    private getRequestParams = (module: Module): RequestParams => {
        const requestParams = {
            LoggingSettings: module.LoggingSettings
                ? // TODO: need to delete first 2 params when FBE will update
                  new LoggingSettings('', '', new LogLevelType(module.LoggingSettings.LogLevel))
                : null,
            AccessServerSettings:
                module.AccessServerSettings && module.ModuleType === ModuleType.AccessServer.valueOf()
                    ? AccessServerSettings.fromObject(module.AccessServerSettings)
                    : null,
            ConfigServerSettings:
                module.ConfigServerSettings && module.ModuleType === ModuleType.ConfigServer.valueOf()
                    ? ConfigServerSettings.fromObject(module.ConfigServerSettings)
                    : null,
            FeedServerSettings:
                module.FeedServerSettings && module.ModuleType === ModuleType.FeedServer.valueOf()
                    ? FeedServerSettings.fromObject(module.FeedServerSettings)
                    : null,
            HistoryServerSettings:
                module.HistoryServerSettings && module.ModuleType === ModuleType.HistoryServer.valueOf()
                    ? HistoryServerSettings.fromObject({
                          ...module.HistoryServerSettings,
                          ChartBarsSlowQueryLogThreshold: millisToNanosec(
                              module.HistoryServerSettings.ChartBarsSlowQueryLogThreshold,
                          ),
                          TicksSlowQueryLogThreshold: millisToNanosec(
                              module.HistoryServerSettings.TicksSlowQueryLogThreshold,
                          ),
                          ClientReportsSlowQueryLogThreshold: millisToNanosec(
                              module.HistoryServerSettings.ClientReportsSlowQueryLogThreshold,
                          ),
                          UserAuditSlowQueryLogThreshold: millisToNanosec(
                              module.HistoryServerSettings.UserAuditSlowQueryLogThreshold,
                          ),
                      })
                    : null,
            StorageServerSettings:
                module.StorageServerSettings && module.ModuleType === ModuleType.StorageServer.valueOf()
                    ? StorageServerSettings.fromObject({
                          ...module.StorageServerSettings,
                          SlowQueryLogThreshold: millisToNanosec(module.StorageServerSettings.SlowQueryLogThreshold),
                      })
                    : null,
            TradeServerSettings:
                module.TradeServerSettings && module.ModuleType === ModuleType.TradeServer.valueOf()
                    ? TradeServerSettings.fromObject({
                          ...module.TradeServerSettings,
                          NotificationServiceSettings: module.TradeServerSettings.NotificationServiceSettings.IsEnabled
                              ? module.TradeServerSettings.NotificationServiceSettings
                              : null,
                      })
                    : null,
            ExecutionServerSettings:
                module.ExecutionServerSettings && module.ModuleType === ModuleType.ExecutionServer.valueOf()
                    ? ExecutionServerSettings.fromObject(module.ExecutionServerSettings)
                    : null,
            BackupServerSettings:
                module.BackupServerSettings && module.ModuleType === ModuleType.BackupServer.valueOf()
                    ? BackupServerSettings.fromObject({
                          ...module.BackupServerSettings,
                          ChartBarsSlowQueryLogThreshold: millisToNanosec(
                              module.BackupServerSettings.ChartBarsSlowQueryLogThreshold,
                          ),
                          StorageSlowQueryLogThreshold: millisToNanosec(
                              module.BackupServerSettings.StorageSlowQueryLogThreshold,
                          ),
                          DrawingSlowQueryLogThreshold: millisToNanosec(
                              module.BackupServerSettings.DrawingSlowQueryLogThreshold,
                          ),
                          ClientReportsSlowQueryLogThreshold: millisToNanosec(
                              module.BackupServerSettings.ClientReportsSlowQueryLogThreshold,
                          ),
                      })
                    : null,
            ExportServerSettings:
                module.ExportServerSettings && module.ModuleType === ModuleType.ExportServer.valueOf()
                    ? ExportServerSettings.fromObject({
                          ...module.ExportServerSettings,
                          SyncPricesInterval: millisToNanosec(module.ExportServerSettings.SyncPricesInterval),
                          SyncTradesInterval: millisToNanosec(module.ExportServerSettings.SyncTradesInterval),
                          SyncMarginInterval: millisToNanosec(module.ExportServerSettings.SyncMarginInterval),
                          SyncDailyInterval: millisToNanosec(module.ExportServerSettings.SyncDailyInterval),
                          SyncUsersInterval: millisToNanosec(module.ExportServerSettings.SyncUsersInterval),
                      })
                    : null,
            DrawingStorageServerSettings:
                module.DrawingStorageServerSettings && module.ModuleType === ModuleType.DrawingStorageServer.valueOf()
                    ? DrawingStorageServerSettings.fromObject({
                          ...module.DrawingStorageServerSettings,
                          SqliteParams:
                              module.DrawingStorageServerSettings.SqliteParams &&
                              !!module.DrawingStorageServerSettings.SqliteParams.DatabaseFile
                                  ? {
                                        ...module.DrawingStorageServerSettings.SqliteParams,
                                        DatabasePassword: module.DrawingStorageServerSettings.SqliteParams
                                            .DatabasePassword
                                            ? module.DrawingStorageServerSettings.SqliteParams.DatabasePassword
                                            : null,
                                    }
                                  : null,
                          SlowQueryLogThreshold: millisToNanosec(
                              module.DrawingStorageServerSettings.SlowQueryLogThreshold,
                          ),
                      })
                    : null,
        };

        if (
            requestParams.AccessServerSettings?.ExternalAuthParams &&
            (requestParams.AccessServerSettings?.ExternalAuthParams?.MAEndpointHost === null ||
                requestParams.AccessServerSettings?.ExternalAuthParams?.MAEndpointHost === undefined ||
                requestParams.AccessServerSettings?.ExternalAuthParams?.MAEndpointHost === '') &&
            (requestParams.AccessServerSettings?.ExternalAuthParams?.MAEndpointPort === null ||
                requestParams.AccessServerSettings?.ExternalAuthParams?.MAEndpointPort === undefined)
        ) {
            requestParams.AccessServerSettings.ExternalAuthParams = null;
        }

        return requestParams;
    };

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new ModulesGetAllRequest();
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(
            data.Modules.map(
                (record): Module => ({
                    id: record.id.toString(),
                    SortOrder: record.SortOrder,
                    HostId: record.HostId.toString(),
                    Name: record.Name,
                    Comment: record.Comment,
                    Description: record.Description,
                    ModuleType: record.ModuleType.valueOf(),
                    IsEnabled: record.IsEnabled,
                    LoggingSettings: {
                        LogLevel: record.LoggingSettings.LogLevel.valueOf(),
                    },
                    HistoryServerSettings:
                        ModuleType.HistoryServer.eq(record.ModuleType) && record.HistoryServerSettings
                            ? {
                                  HistoryPath: record.HistoryServerSettings.HistoryPath,
                                  HistoryPort: record.HistoryServerSettings.HistoryPort,
                                  UseSsl: record.HistoryServerSettings.UseSsl,
                                  ClientReportsPath: record.HistoryServerSettings.ClientReportsPath,
                                  Deprecated1: '',
                                  Deprecated2: '',
                                  OutgoingBufferSizeLimit: record.HistoryServerSettings.OutgoingBufferSizeLimit
                                      ? +record.HistoryServerSettings.OutgoingBufferSizeLimit.toNumber()
                                      : null,
                                  ChartBarsSlowQueryLogThreshold: nanosecToMillis(
                                      +record.HistoryServerSettings.ChartBarsSlowQueryLogThreshold.toNumber(),
                                  ),
                                  TicksSlowQueryLogThreshold: nanosecToMillis(
                                      +record.HistoryServerSettings.TicksSlowQueryLogThreshold.toNumber(),
                                  ),
                                  RequestsThreadPoolSize: record.HistoryServerSettings.RequestsThreadPoolSize,
                                  ChartBarsUpdatePeriod: record.HistoryServerSettings.ChartBarsUpdatePeriod,
                                  ClientReportsSlowQueryLogThreshold: nanosecToMillis(
                                      +record.HistoryServerSettings.ClientReportsSlowQueryLogThreshold.toNumber(),
                                  ),
                                  UserAuditSlowQueryLogThreshold: nanosecToMillis(
                                      +record.HistoryServerSettings.UserAuditSlowQueryLogThreshold.toNumber(),
                                  ),
                              }
                            : null,
                    ConfigServerSettings:
                        ModuleType.ConfigServer.eq(record.ModuleType) && record.ConfigServerSettings
                            ? {
                                  ConfigPort: record.ConfigServerSettings.ConfigPort,
                                  ConfigPath: record.ConfigServerSettings.ConfigPath,
                                  ConfigGit: record.ConfigServerSettings.ConfigGit,
                                  UseSsl: record.ConfigServerSettings.UseSsl,
                              }
                            : null,
                    FeedServerSettings:
                        ModuleType.FeedServer.eq(record.ModuleType) && record.FeedServerSettings
                            ? {
                                  FeedPort: record.FeedServerSettings.FeedPort,
                                  LatestTickDatabaseFile: record.FeedServerSettings.LatestTickDatabaseFile,
                                  UseSsl: record.FeedServerSettings.UseSsl,
                              }
                            : null,
                    StorageServerSettings:
                        ModuleType.StorageServer.eq(record.ModuleType) && record.StorageServerSettings
                            ? {
                                  StoragePort: record.StorageServerSettings.StoragePort,
                                  StorageDatabase: record.StorageServerSettings.StorageDatabase.valueOf(),
                                  StorageDatabaseFile: record.StorageServerSettings.StorageDatabaseFile,
                                  UseSsl: record.StorageServerSettings.UseSsl,
                                  //   DisableDailySnapshots: record.StorageServerSettings.DisableDailySnapshots,
                                  SlowQueryLogThreshold: nanosecToMillis(
                                      +record.StorageServerSettings.SlowQueryLogThreshold,
                                  ),
                              }
                            : null,
                    TradeServerSettings:
                        ModuleType.TradeServer.eq(record.ModuleType) && record.TradeServerSettings
                            ? {
                                  TradePort: record.TradeServerSettings.TradePort,
                                  UseSsl: record.TradeServerSettings.UseSsl,
                                  OrderTimeout: +record.TradeServerSettings.OrderTimeout.toNumber(),
                                  NotificationServiceSettings: record.TradeServerSettings.NotificationServiceSettings
                                      ? {
                                            IsEnabled: !!record.TradeServerSettings.NotificationServiceSettings,
                                            Host: record.TradeServerSettings.NotificationServiceSettings.Host,
                                            Port: record.TradeServerSettings.NotificationServiceSettings.Port,
                                            UseSsl: record.TradeServerSettings.NotificationServiceSettings.UseSsl,
                                            Environment:
                                                record.TradeServerSettings.NotificationServiceSettings.Environment,
                                            NotificationTTLInMinutes:
                                                +record.TradeServerSettings.NotificationServiceSettings
                                                    .NotificationTTLInMinutes,
                                            TokenTTLInMinutes:
                                                +record.TradeServerSettings.NotificationServiceSettings
                                                    .TokenTTLInMinutes,
                                            ResponseTimeoutInSeconds:
                                                +record.TradeServerSettings.NotificationServiceSettings
                                                    .ResponseTimeoutInSeconds,
                                            MaxBatchSize:
                                                +record.TradeServerSettings.NotificationServiceSettings.MaxBatchSize,
                                        }
                                      : {
                                            IsEnabled: false,
                                            Host: '',
                                            Port: null,
                                            UseSsl: false,
                                            Environment: '',
                                            NotificationTTLInMinutes: null,
                                            TokenTTLInMinutes: null,
                                            ResponseTimeoutInSeconds: null,
                                            MaxBatchSize: null,
                                        },
                              }
                            : null,
                    ExecutionServerSettings:
                        ModuleType.ExecutionServer.eq(record.ModuleType) && record.ExecutionServerSettings
                            ? {
                                  ExecutionPort: record.ExecutionServerSettings.ExecutionPort,
                                  UseSsl: record.ExecutionServerSettings.UseSsl,
                              }
                            : null,
                    BackupServerSettings:
                        ModuleType.BackupServer.eq(record.ModuleType) && record.BackupServerSettings
                            ? {
                                  //   MainConfigServerHost: record.BackupServerSettings.MainConfigServerHost,
                                  //   MainConfigServerPort: record.BackupServerSettings.MainConfigServerPort,
                                  //   BackupTradingPlatformDirectory:
                                  //       record.BackupServerSettings.BackupTradingPlatformDirectory,
                                  IsConfigSyncEnabled: record.BackupServerSettings.IsConfigSyncEnabled,
                                  IsStorageSyncEnabled: record.BackupServerSettings.IsStorageSyncEnabled,
                                  IsLastPricesSyncEnabled: record.BackupServerSettings.IsLastPricesSyncEnabled,
                                  IsChartBarHistorySyncEnabled:
                                      record.BackupServerSettings.IsChartBarHistorySyncEnabled,
                                  DrawingSlowQueryLogThreshold: nanosecToMillis(
                                      +record.BackupServerSettings.DrawingSlowQueryLogThreshold.toNumber(),
                                  ),
                                  IsDrawingSyncEnabled: record.BackupServerSettings.IsDrawingSyncEnabled,
                                  DrawingSyncBatchSize: record.BackupServerSettings.DrawingSyncBatchSize,
                                  DrawingSyncPeriodInSeconds: record.BackupServerSettings.DrawingSyncPeriodInSeconds,
                                  ChartBarSyncBatchSize: record.BackupServerSettings.ChartBarSyncBatchSize,
                                  ChartBarSyncPeriodInSeconds: record.BackupServerSettings.ChartBarSyncPeriodInSeconds,
                                  BackupPort: record.BackupServerSettings.BackupPort,
                                  UseSsl: record.BackupServerSettings.UseSsl,
                                  StorageSlowQueryLogThreshold: nanosecToMillis(
                                      +record.BackupServerSettings.StorageSlowQueryLogThreshold.toNumber(),
                                  ),
                                  ChartBarsSlowQueryLogThreshold: nanosecToMillis(
                                      +record.BackupServerSettings.ChartBarsSlowQueryLogThreshold.toNumber(),
                                  ),
                                  IsClientReportsSyncEnabled: record.BackupServerSettings.IsClientReportsSyncEnabled,
                                  ClientReportsSyncBatchSize: record.BackupServerSettings.ClientReportsSyncBatchSize,
                                  ClientReportsSyncPeriodInSeconds:
                                      record.BackupServerSettings.ClientReportsSyncPeriodInSeconds,
                                  ClientReportsSlowQueryLogThreshold: nanosecToMillis(
                                      +record.BackupServerSettings.ClientReportsSlowQueryLogThreshold.toNumber(),
                                  ),
                              }
                            : null,
                    AccessServerSettings:
                        ModuleType.AccessServer.eq(record.ModuleType) && record.AccessServerSettings
                            ? {
                                  SslClientPort: record.AccessServerSettings.SslClientPort,
                                  SslManagerPort: record.AccessServerSettings.SslManagerPort,
                                  WssClientPort: record.AccessServerSettings.WssClientPort,
                                  WssManagerPort: record.AccessServerSettings.WssManagerPort,
                                  HttpsClientPort: record.AccessServerSettings.HttpsClientPort,
                                  HttpsManagerPort: record.AccessServerSettings.HttpsManagerPort,
                                  HttpsPublicPort: record.AccessServerSettings.HttpsPublicPort,
                                  WwwPath: record.AccessServerSettings.WwwPath,
                                  ExternalAuthParams: record.AccessServerSettings?.ExternalAuthParams
                                      ? {
                                            MAEndpointHost:
                                                record.AccessServerSettings?.ExternalAuthParams?.MAEndpointHost ?? '',
                                            MAEndpointPort:
                                                record.AccessServerSettings?.ExternalAuthParams?.MAEndpointPort ?? null,
                                        }
                                      : {
                                            MAEndpointHost: null,
                                            MAEndpointPort: null,
                                        },
                                  ClientNotifySendingInterval: record.AccessServerSettings.ClientNotifySendingInterval,

                                  SslClientOutgoingBufferSizeLimit: record.AccessServerSettings
                                      .SslClientOutgoingBufferSizeLimit
                                      ? +record.AccessServerSettings.SslClientOutgoingBufferSizeLimit.toNumber()
                                      : null,
                                  SslManagerOutgoingBufferSizeLimit: record.AccessServerSettings
                                      .SslManagerOutgoingBufferSizeLimit
                                      ? +record.AccessServerSettings.SslManagerOutgoingBufferSizeLimit.toNumber()
                                      : null,
                                  WssClientOutgoingBufferSizeLimit: record.AccessServerSettings
                                      .WssClientOutgoingBufferSizeLimit
                                      ? +record.AccessServerSettings.WssClientOutgoingBufferSizeLimit.toNumber()
                                      : null,
                                  WssManagerOutgoingBufferSizeLimit: record.AccessServerSettings
                                      .WssManagerOutgoingBufferSizeLimit
                                      ? +record.AccessServerSettings.WssManagerOutgoingBufferSizeLimit.toNumber()
                                      : null,
                                  HttpsClientOutgoingBufferSizeLimit: record.AccessServerSettings
                                      .HttpsClientOutgoingBufferSizeLimit
                                      ? +record.AccessServerSettings.HttpsClientOutgoingBufferSizeLimit.toNumber()
                                      : null,
                                  HttpsManagerOutgoingBufferSizeLimit: record.AccessServerSettings
                                      .HttpsManagerOutgoingBufferSizeLimit
                                      ? +record.AccessServerSettings.HttpsManagerOutgoingBufferSizeLimit.toNumber()
                                      : null,
                                  HttpsPublicOutgoingBufferSizeLimit: record.AccessServerSettings
                                      .HttpsPublicOutgoingBufferSizeLimit
                                      ? +record.AccessServerSettings.HttpsPublicOutgoingBufferSizeLimit.toNumber()
                                      : null,
                                  Deprecated1: '',
                                  Deprecated2: '',
                                  SslClientEndpointEnabled: record.AccessServerSettings.SslClientEndpointEnabled,
                                  SslManagerEndpointEnabled: record.AccessServerSettings.SslManagerEndpointEnabled,
                                  WssClientEndpointEnabled: record.AccessServerSettings.WssClientEndpointEnabled,
                                  WssManagerEndpointEnabled: record.AccessServerSettings.WssManagerEndpointEnabled,
                                  HttpsClientEndpointEnabled: record.AccessServerSettings.HttpsClientEndpointEnabled,
                                  HttpsManagerEndpointEnabled: record.AccessServerSettings.HttpsManagerEndpointEnabled,
                                  HttpsPublicEndpointEnabled: record.AccessServerSettings.HttpsPublicEndpointEnabled,
                                  NewsServicesSettings: convertNewsServicesSettings(
                                      record.AccessServerSettings.NewsServicesSettings,
                                  ),
                                  SecuritySettingsId: convertUUIDToString(
                                      record.AccessServerSettings.SecuritySettingsId,
                                  ),
                                  MinRequiredClientAppVersion:
                                      record.AccessServerSettings.MinRequiredClientAppVersion ?? null,
                              }
                            : null,
                    ExportServerSettings:
                        ModuleType.ExportServer.eq(record.ModuleType) && record.ExportServerSettings
                            ? {
                                  DatabaseParams: {
                                      DatabaseType: record.ExportServerSettings.DatabaseParams.DatabaseType.valueOf(),
                                      DatabaseAddress: record.ExportServerSettings.DatabaseParams.DatabaseAddress,
                                      DatabasePort: record.ExportServerSettings.DatabaseParams.DatabasePort,
                                      DatabaseLogin: record.ExportServerSettings.DatabaseParams.DatabaseLogin,
                                      DatabasePassword: record.ExportServerSettings.DatabaseParams.DatabasePassword,
                                      DatabaseName: record.ExportServerSettings.DatabaseParams.DatabaseName,
                                      ConnectionTimeout: record.ExportServerSettings.DatabaseParams.ConnectionTimeout,
                                      CommandTimeout: record.ExportServerSettings.DatabaseParams.CommandTimeout,
                                  },
                                  SyncPrices: record.ExportServerSettings.SyncPrices,
                                  SyncPricesInterval: nanosecToMillis(
                                      +record.ExportServerSettings.SyncPricesInterval.toNumber(),
                                  ),
                                  SyncTrades: record.ExportServerSettings.SyncTrades,
                                  SyncTradesInterval: nanosecToMillis(
                                      +record.ExportServerSettings.SyncTradesInterval.toNumber(),
                                  ),
                                  SyncMarginInterval: nanosecToMillis(
                                      +record.ExportServerSettings.SyncMarginInterval.toNumber(),
                                  ),
                                  SyncDaily: record.ExportServerSettings.SyncDaily,
                                  SyncDailyInterval: nanosecToMillis(
                                      +record.ExportServerSettings.SyncDailyInterval.toNumber(),
                                  ),
                                  SyncUsers: record.ExportServerSettings.SyncUsers,
                                  SyncUsersInterval: nanosecToMillis(
                                      record.ExportServerSettings.SyncUsersInterval
                                          ? +record.ExportServerSettings.SyncUsersInterval.toNumber()
                                          : null,
                                  ),
                                  PlatformId: record.ExportServerSettings.PlatformId
                                      ? record.ExportServerSettings.PlatformId.toString()
                                      : null,
                                  ExportPort: record.ExportServerSettings.ExportPort,
                                  UseSsl: record.ExportServerSettings.UseSsl,
                              }
                            : null,
                    DrawingStorageServerSettings:
                        ModuleType.DrawingStorageServer.eq(record.ModuleType) && record.DrawingStorageServerSettings
                            ? {
                                  ApiPort: record.DrawingStorageServerSettings.ApiPort,
                                  SlowQueryLogThreshold: nanosecToMillis(
                                      +record.DrawingStorageServerSettings.SlowQueryLogThreshold.toNumber(),
                                  ),
                                  SslCertificatePassword:
                                      record.DrawingStorageServerSettings.SslCertificatePassword ?? null,
                                  SslCertificatePath: record.DrawingStorageServerSettings.SslCertificatePath,
                                  UseSsl: record.DrawingStorageServerSettings.UseSsl,
                                  SqliteParams: record.DrawingStorageServerSettings.SqliteParams
                                      ? {
                                            DatabaseFile: record.DrawingStorageServerSettings.SqliteParams.DatabaseFile,
                                            DatabasePassword:
                                                record.DrawingStorageServerSettings.SqliteParams.DatabasePassword ??
                                                null,
                                        }
                                      : {
                                            DatabaseFile: '',
                                            DatabasePassword: null,
                                        },
                              }
                            : null,
                }),
            ),
        );
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async getSystemInfo() {
        const request = new ModulesSystemInfoGetAllRequest();
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setSystemInfo(
            data.ModuleSystemInfo.map((info) => ({
                ...adaptApi(info),
                StartTime: info.StartTime.getTime(),
                UpdateTime: info.UpdateTime,
            })),
        );
        const log: StatusLog[] = data.ModuleSystemInfo.map((info) => {
            const parsedInfo = adaptApi(info);
            return {
                moduleId: parsedInfo.ModuleId,
                CpuLoad: parsedInfo.CpuLoad,
                ModulesConnectionInfo: parsedInfo.ModulesConnectionInfo,
                MemoryUsage: parsedInfo.MemoryUsage,
                NetworkReceiving: parsedInfo.NetworkReceiving,
                NetworkSending: parsedInfo.NetworkSending,
                date: new Date().getTime(),
                UpdateTime: parsedInfo.UpdateTime,
            };
        });
        this.setStatusLog([...(this.statusLog || []), ...log]);
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async subscribeSystemInfo() {
        SocketClient.instance.responseListener.addListener(ModulesSystemInfoNotify, this.systemInfoReceiveCallback);
        const request = new ModulesSystemInfoNotify();
        SocketClient.instance.sendRequest(request);
    }

    @ErrorsTracker.wrapApi()
    async unsubscribeSystemInfo() {
        SocketClient.instance.responseListener.removeListener(ModulesSystemInfoNotify, this.systemInfoReceiveCallback);
        this.setStatusLog(null);
    }

    @ErrorsTracker.wrapApi()
    async create(record: Module) {
        const request = new ModuleCreateRequest();
        const requestParams = this.getRequestParams(record);
        if (record.HostId) request.HostId = UUID.fromString(record.HostId);
        request.Name = record.Name;
        if (record.Comment) request.Comment = record.Comment;
        if (record.Description) request.Description = record.Description;
        if (record.ModuleType !== null) request.ModuleType = new ModuleType(record.ModuleType);
        request.IsEnabled = record.IsEnabled;
        request.LoggingSettings = requestParams.LoggingSettings;
        request.AccessServerSettings = requestParams.AccessServerSettings;
        request.ConfigServerSettings = requestParams.ConfigServerSettings;
        request.FeedServerSettings = requestParams.FeedServerSettings;
        request.HistoryServerSettings = requestParams.HistoryServerSettings;
        request.StorageServerSettings = requestParams.StorageServerSettings;
        request.TradeServerSettings = requestParams.TradeServerSettings;
        request.ExecutionServerSettings = requestParams.ExecutionServerSettings;
        request.BackupServerSettings = requestParams.BackupServerSettings;
        request.ExportServerSettings = requestParams.ExportServerSettings;
        request.DrawingStorageServerSettings = requestParams.DrawingStorageServerSettings;
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new ModuleDeleteRequest();
        request.ModuleId = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(record: Module) {
        const request = new ModuleModifyRequest();
        const requestParams = this.getRequestParams(record);
        request.ModuleId = UUID.fromString(record.id);
        if (record.ModuleType !== null) request.NewModuleType = new ModuleType(record.ModuleType);
        request.NewName = record.Name;
        request.NewSortOrder = record.SortOrder;
        request.NewIsEnabled = record.IsEnabled;
        if (record.HostId) request.NewHostId = UUID.fromString(record.HostId);
        request.NewComment = record.Comment;
        request.NewDescription = record.Description;
        request.NewLoggingSettings = requestParams.LoggingSettings;
        request.NewAccessServerSettings = requestParams.AccessServerSettings;
        request.NewConfigServerSettings = requestParams.ConfigServerSettings;
        request.NewFeedServerSettings = requestParams.FeedServerSettings;
        request.NewHistoryServerSettings = requestParams.HistoryServerSettings;
        request.NewStorageServerSettings = requestParams.StorageServerSettings;
        request.NewTradeServerSettings = requestParams.TradeServerSettings;
        request.NewExecutionServerSettings = requestParams.ExecutionServerSettings;
        request.NewBackupServerSettings = requestParams.BackupServerSettings;
        request.NewExportServerSettings = requestParams.ExportServerSettings;
        request.NewDrawingStorageServerSettings = requestParams.DrawingStorageServerSettings;
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    private systemInfoReceiveCallback = (data) => {
        this.setSystemInfo(
            data.ModuleSystemInfo.map((info) => ({
                ...adaptApi(info),
                StartTime: info.StartTime.getTime(),
                UpdateTime: info.UpdateTime,
            })),
        );
        const log: StatusLog[] = data.ModuleSystemInfo.map((info: ModuleSystemInfo) => {
            const parsedInfo = adaptApi(info);
            return {
                moduleId: parsedInfo.ModuleId,
                CpuLoad: parsedInfo.CpuLoad,
                ModulesConnectionInfo: parsedInfo.ModulesConnectionInfo,
                MemoryUsage: parsedInfo.MemoryUsage,
                NetworkReceiving: parsedInfo.NetworkReceiving,
                NetworkSending: parsedInfo.NetworkSending,
                date: new Date().getTime(),
                UpdateTime: parsedInfo.UpdateTime,
            };
        });
        this.setStatusLog(
            this.statusLog
                ? this.statusLog.length > 1000 * data.ModuleSystemInfo.length
                    ? [...this.statusLog.slice(data.ModuleSystemInfo.length), ...log]
                    : [...this.statusLog, ...log]
                : [...log],
        );
    };

    reset() {
        this.data = [];
        this.isLoading = false;
        this.systemInfo = null;
        this.statusLog = null;
    }
}

const modulesStore = new ModulesStore();
export { modulesStore };
