import { PushNotificationTemplate } from '@api/fbe/core/PushNotificationTemplate';
import { NotificationTemplate } from '@api/fbe/core/NotificationTemplate';
import { UUID } from '@api/fbe/uuid';

export type NotificationTemplateModel = {
    Title: string;
    Body: string;
};

export type TemplateByLanguageModel = {
    LanguageId: string;
    Template: NotificationTemplateModel;
};

export type PushNotificationTemplateModel = {
    id: string;
    SortOrder: number;
    EventType: number | null; // PushNotificationEventType
    TemplatesByLanguage: Array<TemplateByLanguageModel>;
    IsEnabled: boolean;
    ThrottleTimeInSeconds: number | null;
};

export const convertNotificationTemplateRecordToModel = (record: NotificationTemplate): NotificationTemplateModel => ({
    Title: record.Title,
    Body: record.Body,
});

export const convertPushNotificationTemplateRecordToModel = (
    record: PushNotificationTemplate,
): PushNotificationTemplateModel => ({
    id: record.id.toString(),
    SortOrder: record.SortOrder,
    EventType: record.EventType.valueOf(),
    TemplatesByLanguage: [...record.TemplatesByLanguage].map(([key, value]) => ({
        LanguageId: key.toString(),
        Template: convertNotificationTemplateRecordToModel(value),
    })),
    IsEnabled: !record.IsDisabled,
    ThrottleTimeInSeconds: record.ThrottleTimeInSeconds,
});

export const convertTemplatesByLanguageToApiFormat = (
    templates: Array<TemplateByLanguageModel>,
): Map<UUID, NotificationTemplate> =>
    new Map(
        templates.map(({ LanguageId, Template }) => [
            UUID.fromString(LanguageId),
            new NotificationTemplate(Template.Title, Template.Body),
        ]),
    );
